import { Grid, useMediaQuery, useTheme } from '@mui/material';

import { useFacilityContext } from '@/context';
import { DisableTemplatePaddingOn } from '@/types';
import {
	ContentTypeRichText as TContentTypeRichText,
	ContentTypeRichTextContent,
	ContentTypeRichTextSecondaryContent,
} from '@/types/generated';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import { resolveTemplatePadding } from '@/utils/resolveTemplatePadding';

export interface IRichTextBlockSeoProps {
	/**
	 * Content data for ContentTypeRichText template.
	 *
	 * @type {TContentTypeRichText}
	 * @memberof IRichTextBlockSeoProps
	 */
	content: TContentTypeRichText;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof IRichTextBlockSeoProps
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
}

export default function RichTextBlockSeo({ content, disablePaddingOn = [] }: IRichTextBlockSeoProps) {
	const { officeInfo } = useFacilityContext();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('md'));

	if (!content?.content) {
		return null;
	}

	const dynamicContent = (content: ContentTypeRichTextContent | ContentTypeRichTextSecondaryContent) => {
		if (!content?.json || !officeInfo) {
			return content;
		}

		const jsonContentModified = JSON.stringify(content?.json)
			.replace(/\[facilityName\]/gi, officeInfo?.name)
			.replace(/\[Office Name\]/gi, officeInfo?.name)
			.replace(/\[neighboringAreas\]/gi, officeInfo?.location?.neighbouringAreas as string)
			.replace(/\[Landmarks\]/gi, officeInfo?.location?.landmarks as string)
			.replace(/\[City\]/gi, officeInfo?.address?.city as string)
			.replace(/\[ST\]/gi, officeInfo?.address?.stateCode as string)
			.replace(/\[State\]/gi, officeInfo?.address?.stateCode as string)
			.replace(/\n/gi, ' ');

		return { ...content, json: JSON.parse(jsonContentModified) as JSON };
	};

	const modfiedContent = dynamicContent(content?.content);
	const modfiedSecondaryContent = content?.secondaryContent && dynamicContent(content?.secondaryContent);

	if (!modfiedContent?.json) {
		return null;
	}

	return (
		<Grid
			data-test-id="section_rich_text_block_seo"
			sx={{
				...resolveTemplatePadding(disablePaddingOn, isSmall),
			}}
		>
			<ContentTypeRichText
				{...content}
				disablePaddingOn={['Top', 'Bottom']}
				content={modfiedContent as ContentTypeRichTextContent}
				secondaryContent={modfiedSecondaryContent as ContentTypeRichTextSecondaryContent}
				displaySecondaryContentInDom={!!modfiedSecondaryContent}
				dataTestId="section_rich_text_block_seo_richtext"
			/>
		</Grid>
	);
}
