import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';
import { Patient } from '@/rest/__generated/revspringApi';

export default async function createPatient(patient: Patient): Promise<Patient> {
	try {
		const patientData = await httpClient.request<Patient>({
			url: `Patient/GetOrCreate`,
			requestName: 'createPatient',
			method: 'POST',
			data: patient,
		});
		return patientData;
	} catch (err) {
		logWarning(`Could not set a patient`);
		if (err instanceof Error) {
			logError(`REVSPRING_CREATE_PATIENT_FAILURE: ${err.message}`);
		}
		return {};
	}
}
