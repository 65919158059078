import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import { AgendaStepperStepsCollection, ContentTypeRichText } from '@/types/generated';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { HeadingTag } from '@/types';

import TAGSvgIcon, { IconName } from '../TAGSvgIcon';
import RichText, { RichTextContent } from '../RichText';
import TemplateContainer from '../TemplateContainer';

import { getTitleStyles, headingStyles, templateContainerStyles } from './AgendaStepper.styles';
import TAGStepper from './TAGStepper/TAGStepper';

export interface IAgendaStepperProps {
	backgroundColor?: TAGBackground;
	description?: ContentTypeRichText;
	icon?: IconName;
	hideStepperDot: boolean;
	stepsCollection: AgendaStepperStepsCollection;
	title: string;
	titleHtag?: HeadingTag;
}

const AgendaStepper = ({
	backgroundColor = 'neutral' as TAGBackground,
	description,
	icon,
	hideStepperDot,
	stepsCollection,
	title,
	titleHtag = 'h1',
}: IAgendaStepperProps) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<TemplateContainer
			disableGutters={true}
			maxWidth={false}
			containerSx={templateContainerStyles}
			backgroundColor={backgroundColor}
			dataTestId="agenda_stepper_container"
		>
			{icon && <TAGSvgIcon icon={icon} size={64} dataTestId={'agenda_stepper_icon'} />}
			<Box sx={headingStyles(isSmallScreen, icon)}>
				<Typography
					variant={getHeadingVariant(titleHtag)}
					sx={getTitleStyles(theme)}
					component={titleHtag === 'normal' ? 'p' : titleHtag}
					data-test-id={'agenda_stepper_title'}
				>
					{title}
				</Typography>
				{description && (
					<Box data-test-id={'agenda_stepper_description'}>
						<RichText
							links={description.content?.links}
							content={description.content?.json as RichTextContent}
						/>
					</Box>
				)}
			</Box>
			<TAGStepper hideStepperDot={hideStepperDot} stepsCollection={stepsCollection} />
		</TemplateContainer>
	);
};

export default AgendaStepper;
