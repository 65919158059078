import { Box, Card, IconButton, Slide, Typography, useTheme } from '@mui/material';

import ContentTypeRichText from '@/components/ContentTypeRichText';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import { Maybe } from '@/types';
import { MedicalService, ContentTypeRichTextContent } from '@/types/generated';

import {
	getContainerStyles,
	closeButtonStyles,
	getContentContainerStyles,
	headlineStyles,
	contentStyles,
} from './ServiceLineHeroDesktopDrawer.styles';

interface IServiceLineHeroDesktopDrawer {
	activeMedicalService?: Maybe<MedicalService>;
	isDrawerOpen: boolean;
	closeDrawer: () => void;
}

export default function ServiceLineHeroDesktopDrawer({
	isDrawerOpen,
	activeMedicalService,
	closeDrawer,
}: IServiceLineHeroDesktopDrawer) {
	const theme = useTheme();
	return (
		<Box data-test-id="section_service_line_hero_desktop_drawer">
			<Slide direction="left" in={isDrawerOpen}>
				<Card variant="outlined" sx={getContainerStyles(theme)}>
					{activeMedicalService && (
						<>
							<Box sx={getContentContainerStyles(theme)}>
								<TAGSvgIcon
									icon={activeMedicalService.iconName as IconName}
									size={32}
									fill="currentColor"
								/>
								<Typography
									color={'text.light'}
									variant="header2"
									data-test-id="text_medical_service_title"
									sx={headlineStyles}
								>
									{activeMedicalService?.title}
								</Typography>
								<IconButton onClick={closeDrawer} sx={closeButtonStyles}>
									<TAGSvgIcon icon="Times" size={28} />
								</IconButton>
							</Box>
							<Box sx={contentStyles}>
								<ContentTypeRichText
									key={activeMedicalService.sys.id}
									disableGutters
									disablePaddingOn={['Top', 'Bottom']}
									contentMaxWidth="100%"
									content={activeMedicalService.description as ContentTypeRichTextContent}
									dataTestId="section_service_line_hero_drawer"
									backgroundColor={'transparent'}
									docProps={{
										color: 'text.light',
										variant: 'bodyMediumBook',
									}}
								/>
							</Box>
						</>
					)}
				</Card>
			</Slide>
		</Box>
	);
}
