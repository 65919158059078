/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return, react-hooks/exhaustive-deps, @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, ChangeEvent } from 'react';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useRouter } from 'next/router';
import {
	Box,
	Container,
	useTheme,
	Typography,
	OutlinedInput,
	MenuItem,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import { getSessionId } from '@aspendental/shared-utils/utils';

import { dataLayerConfig, resolveEnv } from '@/utils';
import { SmileAssessmentForm as TSmileAssessmentForm } from '@/types/generated';
import { useAppContext } from '@/context';

import TAGButton from '../TAGButton';

function SmileAssessmentForm({ formContent }: TSmileAssessmentForm) {
	const router = useRouter();
	const theme = useTheme();
	const appConfig = useAppContext();

	const formObj = formContent?.formCollection?.items.reduce((prev, current: any) => {
		const tmp = current?.aditionalReferencesCollection.items.reduce((p: any, c: any) => {
			return {
				...p,
				[c.apiKey]: {
					required: c.required,
					isValid: false,
					isTouched: false,
					isEmail: c.apiKey == 'email',
					alertMessage: c.required && c.alertMessage,
					value: null,
				},
			};
		}, {});
		return { ...prev, ...tmp };
	}, {});

	const [formData, setFormData] = useState<any>(formObj);
	const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
	const [submitButtonClicked, setSubmitButtonClicked] = useState<boolean>(false);
	const submitButtonRef = useRef<HTMLInputElement>(null);

	const apiKForSubmitUrl = formContent?.formCollection?.items
		.flatMap((ob: any) => ob.aditionalReferencesCollection.items)
		.find((elem: any) => elem.onSubmitFormUrl)?.apiKey;

	const urlsOnSubmit = formContent?.formCollection?.items.flatMap((ob: any) =>
		ob.aditionalReferencesCollection.items.filter((elem: any) => elem.onSubmitFormUrl)
	);

	const validateEmail = (email: string) => {
		// eslint-disable-next-line security/detect-unsafe-regex
		const emailRegex = /^[\w-]+(?:\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	};

	const validateZip = (zip: string) => {
		if (zip) {
			const zipRegex = /^[0-9]*$/;
			return zipRegex.test(zip);
		}
		return false;
	};

	const isFormValid = (): boolean => {
		let formValid = true;
		for (const property in formData) {
			if (formData[property].required && !formData[property].isValid) {
				formValid = false;
			}
		}
		return formValid;
	};

	const environment = resolveEnv();
	const env = environment === 'nonprod' ? 'stage' : 'prod';
	const timestamp = new Date().toISOString();
	const facilityCode = globalThis?.localStorage?.getItem('facility');
	const smileAssessmentFormURL = `https://userevents.dataplatform.gke.us-central1.gcp.${env}.aspendental.com/user/events`;

	const onSubmitForm = async (formData: any, e: any) => {
		e.preventDefault();
		if (isFormValid()) {
			try {
				setSubmitButtonClicked(true);
				const formDataPayload = {
					session_id: getSessionId(),
					facility_code: facilityCode,
					age_demographic: formData?.age?.value || '',
					consideration_reason: formData?.whyConsider?.value || '',
					previous_ortho_appliance: formData?.brace?.value || '',
					type_of_insurance: formData?.insuranceType?.value || '',
					teeth_crowding_severity: formData?.teethCrowding?.value || '',
					teeth_spacing_severity: formData?.teethSpacing?.value || '',
					first_name: formData.firstName.value,
					last_name: formData.lastName.value,
					email_address: formData.email.value,
					zip_code: formData.zip.value,
				};

				const res = await fetch(smileAssessmentFormURL, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'x-session-id': getSessionId(),
						'x-client-application': appConfig.appName,
					},
					body: JSON.stringify({
						timestamp,
						event_type: 'submit_smile_assessment',
						event_data: formDataPayload,
					}),
				});
				await res.json();

				// only post to salesforce webto in prod
				if (env === 'prod') {
					await fetch('https://webto.salesforce.com/servlet/servlet.WebToCase', {
						method: 'POST',
						mode: 'no-cors',
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							'x-session-id': getSessionId(),
							'x-client-application': appConfig.appName,
						},
						body: `orgid=00DDn00000CWtSM&Subject=Form+Submit&API_Email__c=${formDataPayload.email_address}&API_First_Name__c=${formDataPayload.first_name}&Service_Line__c=ortho&API_Last_Name__c=${formDataPayload.last_name}&API_Form_Name__c=Smile%20Assessment%20Submit`,
					});
				}

				dataLayerConfig({
					event: 'smile_assessment_complete',
					smile_step: 'Complete',
				});
				void router.push(formData.onSubmitFormUrl);
			} catch (error) {
				console.error(error);
			}
		}

		setFormSubmitted(true);
	};

	// SELECT
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	function handleInputClick(_event: React.FormEvent<HTMLInputElement>, element: any, elementIndex: number) {
		const title = element.title;
		dataLayerConfig({
			event: 'smile_assessment',
			smile_step: `Question ${elementIndex}`,
			smile_question: title,
		});
	}

	// handle select input
	const handleChange = (event: SelectChangeEvent<any>, element: any, elementIndex: number) => {
		const {
			target: { value },
		} = event;
		const title = element?.title;
		const field = element?.aditionalReferencesCollection.items[0].apiKey;
		setFormData({ ...formData, [field]: { ...formData[field], value, isValid: true, isTouched: true } });
		dataLayerConfig({
			event: 'smile_assessment',
			smile_step: `Question ${elementIndex}`,
			smile_question: title,
		});
	};

	// handle text input
	const handleTextInput = (event: React.FormEvent<HTMLInputElement>, field: string) => {
		const {
			currentTarget: { value },
		} = event;

		switch (field) {
			case 'email':
				if (validateEmail(value)) {
					setFormData({
						...formData,
						[field]: { ...formData[field], value, isValid: true, isTouched: true },
					});
				} else {
					setFormData({
						...formData,
						[field]: { ...formData[field], value, isValid: false, isTouched: true },
					});
				}
				break;
			case 'zip':
				if (validateZip(value)) {
					setFormData({
						...formData,
						[field]: {
							...formData[field],
							value: value.substring(0, 5),
							isValid: value.length >= 5,
							isTouched: true,
							alertMessage: '',
						},
					});
				} else {
					setFormData({
						...formData,
						[field]: {
							...formData[field],
							value: value.length == 0 ? '' : value.slice(0, -1),
							isValid: false,
							isTouched: true,
							alertMessage: 'Please insert a valid zip code',
						},
					});
				}
				break;
			default:
				if (value) {
					setFormData({
						...formData,
						[field]: { ...formData[field], value, isValid: true, isTouched: true },
					});
				} else {
					setFormData({
						...formData,
						[field]: { ...formData[field], value, isValid: false, isTouched: true },
					});
				}
				break;
		}
	};

	// handle radio input
	const handleRadio = (event: ChangeEvent<any>, element: any, elementIndex: number) => {
		let value;
		switch (event.target.value) {
			case 'yes':
			case 'true':
				value = true;
				break;
			case 'no':
			case 'false':
				value = false;
				break;
			default:
				value = event.target.value;
				break;
		}

		const title = element?.title;
		const field = element?.aditionalReferencesCollection.items[0].apiKey;

		setFormData({ ...formData, [field]: { ...formData[field], value, isValid: true, isTouched: true } });

		dataLayerConfig({
			event: 'smile_assessment',
			smile_step: `Question ${elementIndex}`,
			smile_question: title,
		});
	};

	// RADIO IMG ICON
	const RadioButton = ({ checked }: any) => (
		<svg width="100%" height="100%">
			<rect rx="5" ry="5" width="100%" height="100%" strokeWidth="1px" stroke="#33234533" fill="none" />
			{checked && (
				<>
					<rect
						rx="5"
						ry="5"
						width="100%"
						height="100%"
						strokeWidth="1px"
						stroke={theme.palette.secondary.dark}
						fill="none"
					/>
				</>
			)}
		</svg>
	);

	useEffect(() => {
		if (formData[apiKForSubmitUrl].value) {
			const v = urlsOnSubmit?.find((el: any) => el.label == formData[apiKForSubmitUrl].value).onSubmitFormUrl;
			setFormData({ ...formData, onSubmitFormUrl: v });
		} else {
			setFormData({ ...formData, onSubmitFormUrl: urlsOnSubmit?.length ? urlsOnSubmit[0]?.onSubmitFormUrl : '' });
		}
	}, [formData[apiKForSubmitUrl].value]);

	useEffect(() => {
		const onFormLoadEvent = {
			event: 'smile_assessment_start',
			smile_step: 'Start',
		};
		dataLayerConfig(onFormLoadEvent);
	}, []);

	const labelValue = (label: string): string | boolean => {
		const l = label.toLowerCase();
		if (l == 'yes') {
			return true;
		} else if (l == 'no') {
			return false;
		} else {
			return label;
		}
	};

	return (
		<Container maxWidth="sm" data-test-id="section_smile_assessment_form">
			<form onSubmit={(e) => void onSubmitForm(formData, e)}>
				<input type="submit" value="" style={{ display: 'none' }} ref={submitButtonRef} />
				{formContent?.formCollection?.items.map((elem: any, index) => {
					if (elem.type == 'radio') {
						return (
							<Box key={elem.title} sx={{ mb: '40px' }}>
								<Typography variant="header4">{elem.title}</Typography>
								<RadioGroup
									row={Boolean(elem.aditionalReferencesCollection.items[0].image)}
									aria-label={elem.title}
									name={elem.aditionalReferencesCollection.items[0].apiKey}
									onChange={(e) => handleRadio(e, elem, ++index)}
								>
									{elem.aditionalReferencesCollection.items.map((el: any) => {
										if (el.image) {
											return (
												<Box
													key={el.label}
													sx={{
														position: 'relative',
														width: { xs: '100%', md: '164px' },
														height: '142px',
														mr: { xs: 0, md: '12px' },
														mb: { xs: '8px', md: 0 },
														'&:last-of-type': {
															mr: { xs: 0, md: 0 },
															mb: { xs: 0, md: 0 },
														},
													}}
												>
													<Radio
														value={el.label}
														icon={<RadioButton />}
														checkedIcon={<RadioButton checked />}
														sx={{
															width: '100%',
															height: '100%',
															borderRadius: 0,
															p: 0,
														}}
													/>
													<Box
														sx={{
															position: 'absolute',
															width: '100%',
															height: '100%',
															top: 0,
															left: 0,
															p: '20px 10px',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'space-between',
															alignItems: 'center',
															boxSizing: 'border-box',
														}}
													>
														<img src={el.image.url} alt="variant" />
														<Typography
															variant="bodyMediumBook"
															sx={{ color: '#3323458c' }}
														>
															{el.label}
														</Typography>
													</Box>
												</Box>
											);
										}
										return (
											<Box key={el.label}>
												<Box sx={{ position: 'relative' }}>
													<OutlinedInput
														fullWidth
														value=""
														placeholder=""
														sx={{
															pointerEvents: 'none',
														}}
													/>
													<Box
														sx={{
															position: 'absolute',
															top: '50%',
															transform: 'translateY(-50%)',
															left: '20px',
														}}
													>
														<FormControlLabel
															value={labelValue(el.label)}
															control={
																<Radio
																	sx={{
																		color: theme.palette.secondary.dark,
																		'&.Mui-checked': {
																			color: theme.palette.secondary.dark,
																		},
																	}}
																	checked={
																		formData[el.apiKey].value ==
																		labelValue(el.label)
																	}
																/>
															}
															label={el.label}
														/>
													</Box>
												</Box>
											</Box>
										);
									})}
								</RadioGroup>
								{!formData[elem.aditionalReferencesCollection.items[0].apiKey].isValid &&
									formData[elem.aditionalReferencesCollection.items[0].apiKey].required &&
									formSubmitted && (
										<Typography variant="bodyMediumBook" color="error.dark">
											{formContent?.defaultErrorMessage}{' '}
											{formData[elem.aditionalReferencesCollection.items[0].apiKey]?.alertMessage}
										</Typography>
									)}
							</Box>
						);
					} else if (elem.type == 'dropdown') {
						return (
							<Box key={elem.title} sx={{ mb: '40px' }}>
								<Typography variant="header4">{elem.title}</Typography>
								<Select
									displayEmpty
									value={formData[elem.aditionalReferencesCollection.items[0].apiKey].value || ''}
									onChange={(e) => handleChange(e, elem, ++index)}
									input={<OutlinedInput />}
									renderValue={(selected) => {
										if (!selected) {
											return (
												<span style={{ color: '#9da6b2' }}>
													{elem.aditionalReferencesCollection.items[0].clearTextItem}
												</span>
											);
										}
										return selected;
									}}
									MenuProps={MenuProps}
									IconComponent={ExpandMoreRoundedIcon}
									inputProps={{
										'aria-label': 'Without label',
										name: elem.aditionalReferencesCollection.items[0].apiKey,
									}}
									sx={{ width: '100%' }}
									error={Boolean(
										!formData[elem.aditionalReferencesCollection.items[0].apiKey].isValid &&
											formData[elem.aditionalReferencesCollection.items[0].apiKey].required &&
											formSubmitted
									)}
								>
									<MenuItem disabled value="">
										<span>{elem.aditionalReferencesCollection.items[0].clearTextItem}</span>
									</MenuItem>
									{elem.aditionalReferencesCollection.items[0].itemList.map((item: any) => (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									))}
								</Select>
								{!formData[elem.aditionalReferencesCollection.items[0].apiKey].isValid &&
									formData[elem.aditionalReferencesCollection.items[0].apiKey].required &&
									formSubmitted && (
										<Typography variant="bodyMediumBook" color="error.dark">
											{formContent?.defaultErrorMessage}{' '}
											{formData[elem.aditionalReferencesCollection.items[0].apiKey].alertMessage}
										</Typography>
									)}
							</Box>
						);
					} else {
						return (
							<Box key={elem.title} sx={{ mb: '40px' }}>
								<Typography variant="header4">{elem.title}</Typography>
								{elem.aditionalReferencesCollection.items.map((el: any) => {
									return (
										<Box sx={{ mb: '10px' }} key={el.title}>
											<OutlinedInput
												fullWidth
												placeholder={el.title}
												name={el.apiKey}
												value={formData[el.apiKey].value || ''}
												onChange={(e: any) => handleTextInput(e, el.apiKey)}
												onClick={(e: any) => handleInputClick(e, elem, ++index)}
												error={Boolean(
													!formData[el.apiKey].isValid &&
														formData[el.apiKey].required &&
														formSubmitted
												)}
											/>

											{!formData[el.apiKey].isValid &&
												formData[el.apiKey].required &&
												formSubmitted && (
													<Typography variant="bodyMediumBook" color="error.dark">
														{formContent?.defaultErrorMessage}{' '}
														{formData[el.apiKey].alertMessage}
													</Typography>
												)}
										</Box>
									);
								})}
							</Box>
						);
					}
				})}

				<TAGButton
					size="M"
					onClick={() => submitButtonRef?.current?.click()}
					disabled={submitButtonClicked}
					variant="primaryDefault"
				>
					{formContent?.buttonText}
				</TAGButton>
			</form>
		</Container>
	);
}

export default SmileAssessmentForm;
