// Temporary - Replace with Object.groupBy when supported in our user base's browsers
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/groupBy

export const groupBy = <T>(
	groupingFn: (item: T) => string,
	list: T[],
	initVal: Record<string, T[]> = {}
): Record<string, T[]> =>
	list.reduce((acc: Record<string, T[]>, item: T) => {
		const key = groupingFn(item);
		const lookupArr = acc[key] || [];
		return Object.assign(acc, { [key]: lookupArr.concat(item) });
	}, initVal);

// commonly used sorting directional functions
// eg ['q', 'w', 'e', 'r', 't', 'y'].sort(desc)
// => ['y', 'w', 't', 'r', 'q', 'e']
export const asc = (a: number | string, b: number | string): number => (a > b ? 1 : a < b ? -1 : 0);
export const desc = (a: number | string, b: number | string): number => (a < b ? 1 : a > b ? -1 : 0);

export const extendArrayToLength = <T>(arr: T[], n: number): T[] => {
	if (arr.length === 0) return [];
	if (arr.length > n) return arr;

	const result: T[] = [];

	while (result.length < n) {
		result.push(...arr.slice(0, n - result.length));
	}

	return result;
};
