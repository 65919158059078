import { SxProps, typographyClasses, fabClasses } from '@mui/material';

import { DisableTemplatePaddingOn, Maybe } from '@/types';
import { resolveTemplatePadding } from '@/utils/resolveTemplatePadding';

export const wrapperStyles = (
	disablePaddingOn: DisableTemplatePaddingOn,
	isSmall: boolean,
	displayAsTitles: boolean
) => ({
	paddingX: {
		xs: '5%',
		sm: '2%',
		lg: '5%',
	},
	display: 'flex',
	flexDirection: 'column',
	gap: displayAsTitles ? '0.5rem' : '2.5rem',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '0 auto',
	boxSizing: 'border-box',
	...resolveTemplatePadding(disablePaddingOn, isSmall),
});

export const getEyebrowStyles = (isDark: boolean, eyebrowStyle?: Maybe<string>): SxProps => ({
	color: eyebrowStyle === 'Promo' ? 'success.dark' : isDark ? 'text.light' : 'text.secondary',
});

export const quickLinkStyles: SxProps = {
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	height: '3.5rem',
	width: {
		xs: '90%',
		lg: '50%',
	},
	minWidth: '14.6875rem',
	maxWidth: '30rem',
	boxShadow: '0rem 0rem 0.75rem rgba(0, 0, 0, 0.1)',
	padding: '0.5rem 0.5rem 0.5rem 1.5rem',
	backgroundColor: 'neutrals.white',
	'&:hover, &:focus': {
		backgroundColor: 'neutrals.neutral',
	},
	[`&:active span.${typographyClasses.root}, &:active > svg`]: {
		color: 'text.primary',
	},
	[`:is(:hover, :focus) button.${fabClasses.root}`]: {
		backgroundColor: 'secondary.dark',
	},
};

export const quickLinkLabelStyles = {
	fontWeight: 700,
	fontSize: '1rem',
	lineHeight: '1.25',
	color: 'text.interactive',
	flexGrow: 1,
	textAlign: 'left',
};

export const quickLinkButtonStyles = {
	'&:disabled': {
		backgroundColor: 'text.interactive',
		'& > svg': {
			color: 'text.light',
		},
	},
	pointerEvents: 'none',
	boxShadow: 'none',
	zIndex: 1,
};

export const getQuickLinksWrapperStyles = (
	displayAsTitles: Maybe<boolean>,
	isSmallScreen?: boolean,
	isMediumScreen?: boolean
) => ({
	display: 'flex',
	flexWrap: {
		xs: 'wrap',
		lg: 'nowrap',
	},
	alignContent: 'center',
	gap: '0.75rem',
	justifyContent: 'center',
	width: '100%',
	flexDirection: {
		xs: 'column',
		md: 'row',
	},
	...(displayAsTitles && {
		gap: isMediumScreen || isSmallScreen ? '2.5rem' : '1rem',
		padding: isSmallScreen ? '1.25rem 0' : '1.5rem 1rem',
	}),
});

export const quickLinkIconBufferStyles = {
	marginLeft: {
		xs: '1.1875rem',
		md: '0.8125rem',
		xl: '1.1875rem',
	},
};

export const getQuickLinkAsTitlesStyles = (isSmallScreen?: boolean, isMediumScreen?: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignItems: 'center',
	flex: '1 0 0',
	...(isSmallScreen &&
		!isMediumScreen && {
			width: '100%',
			alignItems: 'flex-start',
		}),
});

export const getQuickLinkTitleStyles = (isSmallScreen?: boolean) => ({
	display: 'flex',
	alignItems: 'center',
	gap: '0.5rem',
	...(isSmallScreen && {
		width: '100%',
	}),
});
