import dynamic from 'next/dynamic';

export const AdditionalServices = dynamic(() =>
	import(/* webpackChunkName: "AdditionalServices" */ '../AdditionalServices').then((mod) => mod.default)
);
export const Allergy = dynamic(() => import(/* webpackChunkName: "Allergy" */ '../Allergy').then((mod) => mod.default));
export const ArrowDownFunc = dynamic(() =>
	import(/* webpackChunkName: "ArrowDownFunc" */ '@mui/icons-material/ArrowDownwardOutlined').then(
		(mod) => mod.default
	)
);
export const ArrowLeftFunc = dynamic(() =>
	import(/* webpackChunkName: "ArrowLeftFunc" */ '@mui/icons-material/ArrowBackOutlined').then((mod) => mod.default)
);
export const ArrowRightFunc = dynamic(() =>
	import(/* webpackChunkName: "ArrowRightFunc" */ '@mui/icons-material/ArrowForwardOutlined').then(
		(mod) => mod.default
	)
);
export const ArrowScrollToTop = dynamic(() =>
	import(/* webpackChunkName: "ArrowScrollToTop" */ '../ArrowScrollToTop').then((mod) => mod.default)
);
export const ArrowUpFunc = dynamic(() =>
	import(/* webpackChunkName: "ArrowUpFunc" */ '@mui/icons-material/ArrowUpwardOutlined').then((mod) => mod.default)
);
export const Asthma = dynamic(() => import(/* webpackChunkName: "Asthma" */ '../Asthma').then((mod) => mod.default));
export const Bars = dynamic(() =>
	import(/* webpackChunkName: "Bars" */ '@mui/icons-material/MenuOutlined').then((mod) => mod.default)
);
export const Bell = dynamic(() =>
	import(/* webpackChunkName: "Bell" */ '@mui/icons-material/NotificationsNoneOutlined').then((mod) => mod.default)
);
export const Calendar = dynamic(() =>
	import(/* webpackChunkName: "Calendar" */ '@mui/icons-material/CalendarTodayOutlined').then((mod) => mod.default)
);
export const CalendarAlt = dynamic(() =>
	import(/* webpackChunkName: "CalendarAlt" */ '@mui/icons-material/DateRangeOutlined').then((mod) => mod.default)
);
export const CalendarDay = dynamic(() =>
	import(/* webpackChunkName: "CalendarDay" */ '@mui/icons-material/TodayOutlined').then((mod) => mod.default)
);
export const CalendarWeek = dynamic(() =>
	import(/* webpackChunkName: "CalendarWeek" */ '@mui/icons-material/EventNoteOutlined').then((mod) => mod.default)
);
export const Call = dynamic(() => import(/* webpackChunkName: "Call" */ '../Call').then((mod) => mod.default));
export const CallIcon = dynamic(() =>
	import(/* webpackChunkName: "CallIcon" */ '@mui/icons-material/Call').then((mod) => mod.default)
);
export const Chat = dynamic(() => import(/* webpackChunkName: "Chat" */ '../Chat').then((mod) => mod.default));
export const ChatBubbleOutline = dynamic(() =>
	import(/* webpackChunkName: "ChatBubbleOutline" */ '@mui/icons-material/ChatBubbleOutline').then(
		(mod) => mod.default
	)
);
export const CheckFunc = dynamic(() =>
	import(/* webpackChunkName: "CheckFunc" */ '@mui/icons-material/CheckOutlined').then((mod) => mod.default)
);
export const CheckSquareFunc = dynamic(() =>
	import(/* webpackChunkName: "CheckSquareFunc" */ '@mui/icons-material/CheckBoxOutlined').then((mod) => mod.default)
);
export const ChevronLeftFunc = dynamic(() =>
	import(/* webpackChunkName: "ChevronLeftFunc" */ '@mui/icons-material/KeyboardArrowLeftOutlined').then(
		(mod) => mod.default
	)
);
export const ChevronRightFunc = dynamic(() =>
	import(/* webpackChunkName: "ChevronRightFunc" */ '@mui/icons-material/KeyboardArrowRightOutlined').then(
		(mod) => mod.default
	)
);
export const CircleError = dynamic(() =>
	import(/* webpackChunkName: "CircleError" */ '@mui/icons-material/HighlightOffOutlined').then((mod) => mod.default)
);
export const CircleInfo = dynamic(() =>
	import(/* webpackChunkName: "CircleInfo" */ '@mui/icons-material/InfoOutlined').then((mod) => mod.default)
);
export const CircleQuestion = dynamic(() =>
	import(/* webpackChunkName: "CircleQuestion" */ '@mui/icons-material/HelpOutline').then((mod) => mod.default)
);
export const CircleWarning = dynamic(() =>
	import(/* webpackChunkName: "CircleWarning" */ '@mui/icons-material/ErrorOutlineOutlined').then(
		(mod) => mod.default
	)
);
export const ClipboardListCheck = dynamic(() =>
	import(/* webpackChunkName: "ClipboardListCheck" */ '@mui/icons-material/ContentPasteOutlined').then(
		(mod) => mod.default
	)
);
export const Clock = dynamic(() =>
	import(/* webpackChunkName: "Clock" */ '@mui/icons-material/AccessTime').then((mod) => mod.default)
);
export const Covid19 = dynamic(() => import(/* webpackChunkName: "Covid19" */ '../Covid19').then((mod) => mod.default));
export const CricleSuccess = dynamic(() =>
	import(/* webpackChunkName: "CricleSuccess" */ '@mui/icons-material/CheckCircleOutlineOutlined').then(
		(mod) => mod.default
	)
);
export const Desktop = dynamic(() =>
	import(/* webpackChunkName: "Desktop" */ '@mui/icons-material/DesktopMacOutlined').then((mod) => mod.default)
);
export const Discord = dynamic(() => import(/* webpackChunkName: "Discord" */ '../Discord').then((mod) => mod.default));
export const DownloadFunc = dynamic(() =>
	import(/* webpackChunkName: "DownloadFunc" */ '@mui/icons-material/DownloadOutlined').then((mod) => mod.default)
);
export const Eczema = dynamic(() => import(/* webpackChunkName: "Eczema" */ '../Eczema').then((mod) => mod.default));
export const Edit = dynamic(() =>
	import(/* webpackChunkName: "Edit" */ '@mui/icons-material/ModeEditOutlineOutlined').then((mod) => mod.default)
);
export const EllipsisH = dynamic(() =>
	import(/* webpackChunkName: "EllipsisH" */ '@mui/icons-material/MoreHoriz').then((mod) => mod.default)
);
export const EllipsisHAlt = dynamic(() =>
	import(/* webpackChunkName: "EllipsisHAlt" */ '../EllipsisHAlt').then((mod) => mod.default)
);
export const EllipsisV = dynamic(() =>
	import(/* webpackChunkName: "EllipsisV" */ '@mui/icons-material/MoreVert').then((mod) => mod.default)
);
export const EllipsisVAlt = dynamic(() =>
	import(/* webpackChunkName: "EllipsisVAlt" */ '../EllipsisVAlt').then((mod) => mod.default)
);
export const Email = dynamic(() => import(/* webpackChunkName: "Email" */ '../Email').then((mod) => mod.default));
export const EnvironmentalAllergy = dynamic(() =>
	import(/* webpackChunkName: "EnvironmentalAllergy" */ '../EnvironmentalAllergy').then((mod) => mod.default)
);
export const ExternalLink = dynamic(() =>
	import(/* webpackChunkName: "ExternalLink" */ '@mui/icons-material/LaunchOutlined').then((mod) => mod.default)
);
export const Eye = dynamic(() =>
	import(/* webpackChunkName: "Eye" */ '@mui/icons-material/RemoveRedEyeOutlined').then((mod) => mod.default)
);
export const EyeSlash = dynamic(() =>
	import(/* webpackChunkName: "EyeSlash" */ '@mui/icons-material/VisibilityOffOutlined').then((mod) => mod.default)
);
export const Facebook = dynamic(() =>
	import(/* webpackChunkName: "Facebook" */ '../Facebook').then((mod) => mod.default)
);
export const Fax = dynamic(() =>
	import(/* webpackChunkName: "Fax" */ '@mui/icons-material/Fax').then((mod) => mod.default)
);
export const FilterFunc = dynamic(() =>
	import(/* webpackChunkName: "FilterFunc" */ '@mui/icons-material/FilterAltOutlined').then((mod) => mod.default)
);
export const FolderOpen = dynamic(() =>
	import(/* webpackChunkName: "FolderOpen" */ '@mui/icons-material/FolderOutlined').then((mod) => mod.default)
);
export const FoodAllergy = dynamic(() =>
	import(/* webpackChunkName: "FoodAllergy" */ '../FoodAllergy').then((mod) => mod.default)
);
export const Illnesses = dynamic(() =>
	import(/* webpackChunkName: "Illnesses" */ '../Illnesses').then((mod) => mod.default)
);
export const Injury = dynamic(() => import(/* webpackChunkName: "Injury" */ '../Injury').then((mod) => mod.default));
export const LinkedIn = dynamic(() =>
	import(/* webpackChunkName: "LinkedIn" */ '../LinkedIn').then((mod) => mod.default)
);
export const LocationArrow = dynamic(() =>
	import(/* webpackChunkName: "LocationArrow" */ '@mui/icons-material/NavigationOutlined').then((mod) => mod.default)
);
export const LocationRounded = dynamic(() =>
	import(/* webpackChunkName: "LocationRounded" */ '@mui/icons-material/GpsFixed').then((mod) => mod.default)
);
export const Map = dynamic(() =>
	import(/* webpackChunkName: "Map" */ '@mui/icons-material/MapOutlined').then((mod) => mod.default)
);
export const MapMarker = dynamic(() =>
	import(/* webpackChunkName: "MapMarker" */ '@mui/icons-material/FmdGoodOutlined').then((mod) => mod.default)
);
export const MedicationAllergy = dynamic(() =>
	import(/* webpackChunkName: "MedicationAllergy" */ '../MedicationAllergy').then((mod) => mod.default)
);
export const MicrophoneAlt = dynamic(() =>
	import(/* webpackChunkName: "MicrophoneAlt" */ '@mui/icons-material/MicNoneOutlined').then((mod) => mod.default)
);
export const MinusFunc = dynamic(() =>
	import(/* webpackChunkName: "MinusFunc" */ '@mui/icons-material/HorizontalRuleOutlined').then((mod) => mod.default)
);
export const Mobile = dynamic(() =>
	import(/* webpackChunkName: "Mobile" */ '@mui/icons-material/PhoneIphoneOutlined').then((mod) => mod.default)
);
export const OccMed = dynamic(() => import(/* webpackChunkName: "OccMed" */ '../OccMed').then((mod) => mod.default));
export const OnSitePrograms = dynamic(() =>
	import(/* webpackChunkName: "OnSitePrograms" */ '../OnSitePrograms').then((mod) => mod.default)
);
export const PauseCircleOutlinedIcon = dynamic(() =>
	import(/* webpackChunkName: "PauseCircleOutlinedIcon" */ '@mui/icons-material/PauseCircleOutline').then(
		(mod) => mod.default
	)
);
export const Pediatrician = dynamic(() =>
	import(/* webpackChunkName: "Pediatrician" */ '../Pediatrician').then((mod) => mod.default)
);
export const Physicals = dynamic(() =>
	import(/* webpackChunkName: "Physicals" */ '../Physicals').then((mod) => mod.default)
);
export const PlayCircleOutlinedIcon = dynamic(() =>
	import(/* webpackChunkName: "PlayCircleOutlinedIcon" */ '@mui/icons-material/PlayCircleOutline').then(
		(mod) => mod.default
	)
);
export const PlusFunc = dynamic(() =>
	import(/* webpackChunkName: "PlusFunc" */ '@mui/icons-material/AddOutlined').then((mod) => mod.default)
);
export const Reddit = dynamic(() => import(/* webpackChunkName: "Reddit" */ '../Reddit').then((mod) => mod.default));
export const SearchFunc = dynamic(() =>
	import(/* webpackChunkName: "SearchFunc" */ '@mui/icons-material/Search').then((mod) => mod.default)
);
export const SettingsFunc = dynamic(() =>
	import(/* webpackChunkName: "SettingsFunc" */ '@mui/icons-material/SettingsOutlined').then((mod) => mod.default)
);
export const Sort = dynamic(() =>
	import(/* webpackChunkName: "Sort" */ '@mui/icons-material/UnfoldMoreOutlined').then((mod) => mod.default)
);
export const SortDown = dynamic(() =>
	import(/* webpackChunkName: "SortDown" */ '@mui/icons-material/KeyboardArrowUpOutlined').then((mod) => mod.default)
);
export const SortUp = dynamic(() =>
	import(/* webpackChunkName: "SortUp" */ '@mui/icons-material/KeyboardArrowDownOutlined').then((mod) => mod.default)
);
export const SquareFunc = dynamic(() =>
	import(/* webpackChunkName: "SquareFunc" */ '@mui/icons-material/CheckBoxOutlineBlankOutlined').then(
		(mod) => mod.default
	)
);
export const Star = dynamic(() =>
	import(/* webpackChunkName: "Star" */ '@mui/icons-material/StarBorderRounded').then((mod) => mod.default)
);
export const StarFull = dynamic(() =>
	import(/* webpackChunkName: "StarFull" */ '@mui/icons-material/StarRounded').then((mod) => mod.default)
);
export const Symptoms = dynamic(() =>
	import(/* webpackChunkName: "Symptoms" */ '../Symptoms').then((mod) => mod.default)
);
export const Teeth = dynamic(() => import(/* webpackChunkName: "Teeth" */ '../Teeth').then((mod) => mod.default));
export const TeethOpen = dynamic(() =>
	import(/* webpackChunkName: "TeethOpen" */ '../TeethOpen').then((mod) => mod.default)
);
export const Threads = dynamic(() => import(/* webpackChunkName: "Threads" */ '../Threads').then((mod) => mod.default));
export const Times = dynamic(() =>
	import(/* webpackChunkName: "Times" */ '@mui/icons-material/ClearOutlined').then((mod) => mod.default)
);
export const Tooth = dynamic(() => import(/* webpackChunkName: "Tooth" */ '../Tooth').then((mod) => mod.default));
export const TrashFunc = dynamic(() =>
	import(/* webpackChunkName: "TrashFunc" */ '@mui/icons-material/DeleteOutlineOutlined').then((mod) => mod.default)
);
export const UploadFunc = dynamic(() =>
	import(/* webpackChunkName: "UploadFunc" */ '@mui/icons-material/UploadOutlined').then((mod) => mod.default)
);
export const UrgentCare = dynamic(() =>
	import(/* webpackChunkName: "UrgentCare" */ '../UrgentCare').then((mod) => mod.default)
);
export const User = dynamic(() =>
	import(/* webpackChunkName: "User" */ '@mui/icons-material/PersonOutlineOutlined').then((mod) => mod.default)
);
export const Users = dynamic(() =>
	import(/* webpackChunkName: "Users" */ '@mui/icons-material/GroupsOutlined').then((mod) => mod.default)
);
export const WellNowOffice = dynamic(() =>
	import(/* webpackChunkName: "WellNowOffice" */ '../WellNowOffice').then((mod) => mod.default)
);
export const WellNowVirtualCare = dynamic(() =>
	import(/* webpackChunkName: "WellNowVirtualCare" */ '../WellNowVirtualCare').then((mod) => mod.default)
);
export const WellNowVirtualCareAlt = dynamic(() =>
	import(/* webpackChunkName: "WellNowVirtualCareAlt" */ '../WellNowVirtualCareAlt').then((mod) => mod.default)
);
export const XCompany = dynamic(() =>
	import(/* webpackChunkName: "XCompany" */ '../XCompany').then((mod) => mod.default)
);
export const XRay = dynamic(() => import(/* webpackChunkName: "XRay" */ '../XRay').then((mod) => mod.default));
export const Youtube = dynamic(() => import(/* webpackChunkName: "Youtube" */ '../Youtube').then((mod) => mod.default));
export const Work = dynamic(() =>
	import(/* webpackChunkName: "Work" */ '@mui/icons-material/Work').then((mod) => mod.default)
);
export const Verified = dynamic(() =>
	import(/* webpackChunkName: "Verified" */ '@mui/icons-material/Verified').then((mod) => mod.default)
);
export const LocationOn = dynamic(() =>
	import(/* webpackChunkName: "LocationOn" */ '@mui/icons-material/LocationOn').then((mod) => mod.default)
);
export const Language = dynamic(() =>
	import(/* webpackChunkName: "Language" */ '@mui/icons-material/Language').then((mod) => mod.default)
);
export const VolunteerActivism = dynamic(() =>
	import(/* webpackChunkName: "VolunteerActivism" */ '@mui/icons-material/VolunteerActivism').then(
		(mod) => mod.default
	)
);
export const CloseFunc = dynamic(() =>
	import(/* webpackChunkName: "CloseFunc" */ '@mui/icons-material/Close').then((mod) => mod.default)
);
export const LocationOnOutlined = dynamic(() =>
	import(/* webpackChunkName: "LocationOnOutlined" */ '@mui/icons-material/LocationOnOutlined').then(
		(mod) => mod.default
	)
);
export const MenuBookIcon = dynamic(() =>
	import(/* webpackChunkName: "MenuBookIcon" */ '@mui/icons-material/MenuBook').then((mod) => mod.default)
);
export const East = dynamic(() =>
	import(/* webpackChunkName: "East" */ '@mui/icons-material/East').then((mod) => mod.default)
);
