import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { CityLinks as ICityLinks } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { useAppContext, useOfficeLocationContext } from '@/context';
import { TCity } from '@/types';
import {
	clickContext,
	joinValidStrs,
	officeURLByBrand,
	stateStrByBrand,
	strToSlug,
	useTemplateContainerFullWidthStyles,
} from '@/utils';
import TemplateContainer from '@/components/TemplateContainer';

import { linkItemWrapper } from '../Centers.styles';

export default function CityLinks({ sectionTitle, useDisplayName }: ICityLinks) {
	const theme = useTheme();
	const { config } = useAppContext();
	const isMediumDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'));

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	const { cities } = useOfficeLocationContext() as { cities: TCity[] };
	const facilityBasePath = config.features.otherApps.facilityDetailsBasePath;

	return (
		<TemplateContainer
			disableGutters={false}
			maxWidth={false}
			dataTestId="section_city_links_wrapper"
			containerSx={useTemplateContainerFullWidthStyles(['Top', 'Bottom'], isMediumDevice)}
		>
			<Container data-test-id="city_links_section">
				{sectionTitle && (
					<Typography
						variant="header4"
						color="text.secondary"
						my={1.5}
						data-test-id={'city_links_section_title'}
					>
						{sectionTitle}
					</Typography>
				)}
				{cities.length ? (
					<Grid
						container
						spacing={2}
						columns={{ xs: 1, sm: 4 }}
						justifyContent="flex-start"
						data-test-id="city_links_box_wrapper"
					>
						{cities
							.sort((aCity: TCity, bCity: TCity) => {
								const cityAStr = aCity.city || aCity.location?.city || '';
								const cityBStr = bCity.city || bCity.location?.city || '';

								const linkAText =
									config.themeId === 'wellnow' && aCity.displayName ? aCity.displayName : cityAStr;
								const linkBText =
									config.themeId === 'wellnow' && bCity.displayName ? bCity.displayName : cityBStr;

								return linkAText.localeCompare(linkBText);
							})
							.map((city: TCity, index: number) => {
								const stateStr = stateStrByBrand(config.themeId, city);
								const cityStr = city.city || city.location?.city || undefined;

								const center = city.centers?.[0];
								const addressStr = center?.location?.street1 || city.location?.street1 || undefined;
								const zipStr = center?.location?.zip || city.location?.zip || undefined;

								function computeLinkText(): string {
									return useDisplayName && city.displayName
										? city.displayName
										: joinValidStrs(', ', [cityStr, stateStr]);
								}

								const href = `/${facilityBasePath}/${officeURLByBrand(config.themeId, {
									stateStr,
									city: city?.centers && city?.centers?.length > 1 ? undefined : cityStr,
									address: addressStr,
									zip: zipStr,
								})}/`;

								const dataId = `city_links_link_item_${strToSlug(
									joinValidStrs('_', [stateStr, cityStr, city?.location?.street1])
								)}`;

								const linkText = computeLinkText();

								return (
									<Grid item xs={1} sm={1} key={`city_links_link_${index}`}>
										<Box data-test-id={dataId} sx={linkItemWrapper}>
											<TAGLink
												href={href}
												analyticsContext={clickContext({
													brand: config.name,
													text: linkText,
												})}
											>
												<Typography
													variant="bodyMediumBook"
													color="text.interactive"
													sx={{ textDecoration: 'underline' }}
												>
													{linkText}
												</Typography>
											</TAGLink>
										</Box>
									</Grid>
								);
							})}
					</Grid>
				) : null}
			</Container>
		</TemplateContainer>
	);
}
