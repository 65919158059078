import { HeadingTag, Maybe } from '@/types';

export default function resolveContentfulHeadingTag(tag: Maybe<HeadingTag>) {
	switch (tag) {
		case 'h1':
		case 'h2':
		case 'h3':
		case 'h4':
			return tag;
		default:
			return 'p';
	}
}
