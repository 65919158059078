import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

export interface BookedAppointment {
	status: string;
	appointmentDTO: {
		vendorAppointmentId: string;
		appointmentDateTime: string;
	};
}

export default async function setSlotBook(data: {
	revspringAccountId: string;
	revspringLocationValue: string;
	apptType: string; // appointment type
	startDate: string;
	duration: number;
	patientId: string;
}): Promise<BookedAppointment> {
	try {
		const { revspringAccountId: accountId, revspringLocationValue: location, startDate: startTime, ...rest } = data;
		const {
			status,
			appointmentDTO: { vendorAppointmentId = '', appointmentDateTime = '' },
		} = await httpClient.request<BookedAppointment>({
			url: `Slots/book`,
			requestName: 'setSlotBook',
			method: 'POST',
			data: {
				createdByApp: 'wellnow-webapp',
				accountId,
				location,
				startTime,
				...rest,
			},
		});

		return {
			status: status === 'BOOKED' ? 'success' : 'error',
			appointmentDTO: { vendorAppointmentId, appointmentDateTime },
		};
	} catch (err) {
		logWarning(`Could not book a slot`);
		if (err instanceof Error) {
			logError(`REVSPRING_SLOT_BOOK_FAILURE: ${err.message}`);
		}
		return {
			status: 'error',
			appointmentDTO: { vendorAppointmentId: '0', appointmentDateTime: '2010-01-01T00:00:00Z' },
		};
	}
}
