import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
	Asset,
	ContentTypeRichTextContent,
	ServiceButtonsCollection,
	ServiceRichTextDescription,
	Service as TService,
	TermsAndConditions as TTermsAndConditions,
} from '@/types/generated';
import { HeadingTag, Maybe } from '@/types';
import resolveContentfulHeadingTag from '@/utils/resolveContentfulHeadingTag';

import ContentTypeRichText from '../ContentTypeRichText';
import TermsAndConditions from '../TermsAndConditions';
import ContentfulButton from '../ContentfulButton';

import {
	getContentWrapperStyles,
	getMediaWrapperStyles,
	getWrapperStyles,
	buttonsWrapperStyles,
} from './Service.styles';
import ServiceMedia from './ServiceMedia';
import { ServiceMediaType } from './Service.helpers';

export interface IService extends TService {
	/**
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 * @deprecated use buttonsCollection instead
	 */
	buttonText?: Maybe<string>;
	/**
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 * @deprecated use buttonsCollection instead
	 */
	buttonType?: Maybe<string>;
	/**
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 * @deprecated use buttonsCollection instead
	 */
	buttonUrl?: Maybe<string>;
	/**
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 * @deprecated
	 */
	contained?: Maybe<boolean>;
	/**
	 * The collection of buttons to display. Maximum of 2 buttons.
	 *
	 * @type {Maybe<ServiceButtonsCollection>}
	 * @memberof IService
	 */
	buttonsCollection?: Maybe<ServiceButtonsCollection>;
	/**
	 * The background color of the service template.
	 *
	 * @type {Maybe<TAGBackground>}
	 * @memberof IService
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * Specifies whether the content should be center aligned.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IService
	 */
	centerAlign?: Maybe<boolean>;
	/**
	 * The plain text description of the service template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 */
	description?: Maybe<string>;
	/**
	 * Specifies the direction of the content. Can be row or row-reverse.
	 *
	 * @type {Maybe<'row' | 'row-reverse'>}
	 * @memberof IService
	 */
	direction?: Maybe<string>;
	/**
	 * The plain text eyebrow of the service template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 */
	eyebrow?: Maybe<string>;
	/**
	 * The image to display. The reference to an image asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	image?: Maybe<Asset>;
	/**
	 * Specifies the layout of the image. Can be circle, square, or rectangle.
	 *
	 * @type {Maybe<circle | square | rectangle>}
	 * @memberof IService
	 */
	imageLayout?: Maybe<string>;
	/**
	 * Specifies the type of the media used in the template. Can be Image, Video or YouTube Video.
	 *
	 * @type {Maybe<ServiceMediaType>}
	 * @memberof IService
	 */
	mediaType?: Maybe<string>;
	/**
	 * The image to display on smaller screens. The reference to an image asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	mobileImage?: Maybe<Asset>;
	/**
	 * The rich text description of the service template.
	 *
	 * @type {Maybe<ServiceRichTextDescription>}
	 * @memberof IService
	 */
	richTextDescription?: Maybe<ServiceRichTextDescription>;
	/**
	 * The terms and conditions to display.
	 *
	 * @type {Maybe<TTermsAndConditions>}
	 * @memberof IService
	 */
	tc?: Maybe<TTermsAndConditions>;
	/**
	 * The plain text title of the service template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 */
	title?: Maybe<string>;
	/**
	 * Large text shown as the headline on large screens
	 *
	 * @type {Maybe<HeadingTag>}
	 * @memberof IService
	 * @default 'h2'
	 */
	titleHtag?: Maybe<HeadingTag>;
	/**
	 * The video to display. The reference to a video asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	video?: Maybe<Asset>;
	/**
	 * The image to display for the video or on small screens. The reference to an image asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	videoImage?: Maybe<Asset>;
	/**
	 * The ID of YouTube video to display.
	 *
	 * @type {Maybe<string>}
	 * @memberof IService
	 */
	youTubeVideoId?: Maybe<string>;
	/**
	 * The image to display for the YouTube video. The reference to an image asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IService
	 */
	youTubeVideoImage?: Maybe<Asset>;
}

export default function Service({
	sys,
	backgroundColor,
	buttonsCollection,
	centerAlign,
	description,
	direction,
	eyebrow,
	image,
	imageLayout,
	mediaType,
	mobileImage,
	richTextDescription,
	tc,
	title,
	titleHtag = 'h2',
	video,
	videoImage,
	youTubeVideoId,
	youTubeVideoImage,
}: IService) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');

	const shouldDisplayMediaFirst = direction === 'row';

	return (
		<Box
			data-test-id={`section_service_${sys.id}`}
			sx={{
				...getWrapperStyles({ isSmallScreen, shouldDisplayMediaFirst }),
				backgroundColor: `backgrounds.${backgroundColor || 'white'}`,
			}}
		>
			<Box data-test-id="group_media_half" sx={getMediaWrapperStyles({ isSmallScreen, mediaType, imageLayout })}>
				<ServiceMedia
					image={image}
					imageLayout={imageLayout}
					isSmallScreen={isSmallScreen}
					mediaType={mediaType as ServiceMediaType}
					mobileImage={mobileImage}
					shouldDisplayMediaFirst={shouldDisplayMediaFirst}
					video={video}
					videoImage={videoImage}
					youTubeVideoId={youTubeVideoId}
					youTubeVideoImage={youTubeVideoImage}
					sixteenByNineVideoRatio={true}
				/>
			</Box>

			<Box
				data-test-id="group_content_half"
				sx={getContentWrapperStyles({ isSmallScreen, mediaType, imageLayout, centerAlign: !!centerAlign })}
			>
				{eyebrow && (
					<Typography
						color={isDarkBackground ? 'text.light' : 'text.secondary'}
						variant="bodyMediumBook"
						data-test-id="text_service_eyebrow"
					>
						{eyebrow}
					</Typography>
				)}

				{title && (
					<Typography
						component={resolveContentfulHeadingTag(titleHtag)}
						variant="header2"
						color={isDarkBackground ? 'text.light' : 'text.primary'}
						data-test-id="text_service_title"
					>
						{title}
					</Typography>
				)}

				{description && (
					<Typography
						color={isDarkBackground ? 'text.light' : 'text.secondary'}
						variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
						textAlign={centerAlign || isSmallScreen ? 'center' : 'left'}
						data-test-id="text_service_description"
					>
						{description}
					</Typography>
				)}

				{richTextDescription && (
					<ContentTypeRichText
						disablePaddingOn={['Top', 'Bottom']}
						backgroundColor="transparent"
						centerAlign={isSmallScreen ?? !!centerAlign}
						content={richTextDescription as ContentTypeRichTextContent}
						contentMaxWidth="100%"
						dataTestId="section_rich_text_service_description"
						disableGutters
						docProps={{
							variant: 'bodyLargeBook',
							sx: { color: isDarkBackground ? 'text.light' : 'text.secondary' },
						}}
					/>
				)}

				{buttonsCollection?.items && buttonsCollection.items.length > 0 && (
					<Box paddingTop="0.5rem" sx={buttonsWrapperStyles} data-test-id="group_service_buttons">
						{buttonsCollection.items.map((button) =>
							button ? (
								<ContentfulButton
									key={button.sys.id}
									dataTestId="button_service_cta"
									defaultVariant="secondaryDefault"
									{...button}
								/>
							) : null
						)}
					</Box>
				)}

				{tc && (
					<Box paddingTop="0.5rem" data-test-id="group_service_tc">
						<TermsAndConditions {...tc} textColor={isDarkBackground ? 'text.light' : 'text.secondary'} />
					</Box>
				)}
			</Box>
		</Box>
	);
}
