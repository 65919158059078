import { TContentMaxWidthSize } from '@/types';

export const getSwiperContainerStyles = (params?: {
	showDisabledSwiperArrows?: boolean;
	contentMaxWidth?: TContentMaxWidthSize;
	isSmallScreen?: boolean;
	withMobileArrowsNavigation?: boolean;
}) => ({
	...(params?.contentMaxWidth === 'Large' &&
		!params.isSmallScreen && {
			'.swiper': {
				paddingLeft: '4rem',
				paddingRight: '12rem',
			},
		}),
	'& .swiper-button-prev, & .swiper-button-next': {
		color: 'text.interactive',
	},
	...(params?.isSmallScreen &&
		params?.withMobileArrowsNavigation && {
			'& .swiper-pagination-bullets.swiper-pagination-horizontal': {
				width: 'auto',
			},
			'& .swiper-button-prev': {
				top: '96.5%',
				left: '78%',
			},
			'& .swiper-button-next': {
				top: '96.5%',
				right: '0',
			},
		}),
	'& .swiper-button-disabled': {
		display: params?.showDisabledSwiperArrows ? 'flex' : 'none',
	},
	'& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
		backgroundColor: 'text.interactive',
	},
});

export const getSwiperStyles = ({ isSmallScreen }: { isSmallScreen: boolean }) => ({
	...(isSmallScreen && { paddingBottom: '2rem' }),
});
