export const getDividerStyles = { paddingTop: '1rem' };

export const getInsuranceListStyles = (showMore: boolean, expanded: boolean) => {
	return [
		{ columnCount: { sm: 2, md: 3 } },
		showMore && !expanded
			? {
					webkitMaskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',
					maskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',
			  }
			: null,
	];
};

export const getInsuranceListItemStyles = { paddingLeft: 0 };
