import { Box } from '@mui/material';

import { Maybe } from '@/types/generated';
import { dataLayerConfig, IDataLayerEventConfig } from '@/utils/dataLayerConfig';
import { allowedYextOrigins } from '@/constants';

export interface IFrame {
	/**
	 * Required field for the iframe title
	 *
	 * @type {string}
	 * @memberof IFrame
	 */
	title: string;
	/**
	 * Required field to identify the webpage to be embedded in the iframe
	 *
	 * @type {string}
	 * @memberof IFrame
	 */
	url: string;
	/**
	 * Optional field to define a specific height for the iframe
	 *
	 * @type {Maybe<number>}
	 * @memberof IFrame
	 */
	height?: Maybe<number>;
	/**
	 * Optional data-test-id attribute for testing purposes.
	 *
	 * @type {string}
	 * @memberof IMultiCard
	 */
	dataTestId?: string;
}

export default function IFrame({ dataTestId = 'iframe_container', url = '', height, title = '' }: IFrame) {
	const iFrameHeight = height ? `${height}px` : '100vh';

	if (globalThis?.window) {
		globalThis?.window.addEventListener(
			'message',
			(e) => {
				if (!allowedYextOrigins.includes(e.origin)) return;

				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				const msg = JSON.parse(e.data) as IDataLayerEventConfig;
				dataLayerConfig({
					...msg,
				});
			},
			false
		);
	}

	return (
		<Box
			data-test-id={dataTestId}
			width="100%"
			height={iFrameHeight}
			sx={{
				position: 'relative',
				overflow: 'hidden',
			}}
		>
			<iframe
				src={url}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					width: '100%',
					height: '100%',
					border: 'none',
				}}
				title={title}
			/>
		</Box>
	);
}
