import { Maybe } from '@/types';

export function resolveContentAlignment(contentAlign?: Maybe<string>, isTypography?: boolean) {
	switch (contentAlign) {
		case 'Left':
			return isTypography ? 'left' : 'flex-start';
		case 'Center':
			return 'center';
		case 'Right':
			return isTypography ? 'right' : 'flex-end';
		default:
			return 'center';
	}
}
