import { resolveTypographyVariantStyles } from '@aspendental/themes';
import { Theme } from '@mui/material';

export const stepperStyles = (theme: Theme, isSmallScreen: boolean, hideStepperDot: boolean) => {
	const hideDotAndSmallScreen = isSmallScreen && hideStepperDot;

	return {
		'.MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
			borderColor: theme.palette.neutrals?.primary,
			height: '1.5rem',
			marginLeft: isSmallScreen ? '-0.4rem' : null,
		},
		'.MuiStepLabel-root.MuiStepLabel-vertical': {
			paddingTop: '0',
			textAlign: hideStepperDot ? 'center' : 'start',
		},
		'.MuiStepLabel-iconContainer.Mui-completed': {
			display: hideDotAndSmallScreen ? 'none' : 'block',
			paddingTop: isSmallScreen ? '.6rem' : null,
		},
		'.MuiStepConnector-root.MuiStepConnector-vertical.Mui-completed': {
			display: hideDotAndSmallScreen ? 'flex' : null,
			justifyContent: hideDotAndSmallScreen ? 'center' : null,
			marginLeft: hideDotAndSmallScreen ? '0' : null,
		},
		'.MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel.Mui-completed': {
			padding: ' 0 1rem',
		},
		'.MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
			marginTop: '0.8rem',
		},
	};
};

export const stepLabelStyles = (theme: Theme, isSmallScreen: boolean, hideStepperDot: boolean) => {
	const hideDotAndSmallScreen = isSmallScreen && hideStepperDot;
	return {
		'.MuiSvgIcon-root': {
			display: hideDotAndSmallScreen ? 'none' : 'block',
			fontSize: '0.8rem',
			color: theme.palette.success.main,
			textAlign: 'left',
		},
		'.MuiStepIcon-text': { display: 'none' },
	};
};

export const stepperContainerStyle = (isSmallScreen: boolean) => {
	return { marginTop: isSmallScreen ? '2.25rem' : '2.75rem' };
};

export const getLinkTitleStyles = (theme: Theme, isSmallScreen: boolean) => ({
	...resolveTypographyVariantStyles(theme, 'p'),
	cursor: 'pointer',
	color: theme.palette.secondary.main,
	fontWeight: '600',
	fontSize: isSmallScreen ? '1.1rem' : '1rem',
});
