import { useState } from 'react';

import { useMediaQuery, Grid, Box, Divider, useTheme, Typography } from '@mui/material';
import { format } from 'date-fns';

import { IOneReviewProps } from './BirdEyeReviews.helper';
import Rating from './Rating';
import {
	oneReviewBoxStyles,
	oneReviewDividerStyles,
	oneReviewRatingBoxMargin,
	oneReviewReadMoreStyles,
	oneReviewSourceMargins,
} from './BirdEyeReviews.styles';

export default function OneReview({ review, sx, showFullReview, dataTestId }: IOneReviewProps) {
	const [full, setFull] = useState(false);
	const theme = useTheme();
	const mobileWidth = useMediaQuery(theme.breakpoints.down('md'));

	const getShortText = (text: string) => {
		if (text && text.length >= 150) {
			const truncate = text.slice?.(0, 150).trim() + '.... ';
			return truncate;
		}
		return text;
	};
	const fullText = review?.comments;
	const shortText = getShortText(fullText || '');
	return (
		<>
			<Grid sx={sx} data-test-id={dataTestId}>
				<Box sx={oneReviewBoxStyles}>
					<Box sx={oneReviewRatingBoxMargin}>
						<Rating rating={review.rating} />
					</Box>
					<Typography variant={mobileWidth ? 'bodyMediumBook' : 'bodyLargeBook'} color="text.secondary">
						{showFullReview || full ? (
							fullText
						) : (
							<>
								{shortText}
								{!full && review.comments && review.comments.length > 150 && (
									<Typography
										variant={mobileWidth ? 'bodyMediumBook' : 'bodyLargeBook'}
										color="text.link"
										sx={oneReviewReadMoreStyles}
										onClick={() => setFull(true)}
									>
										Read more
									</Typography>
								)}
							</>
						)}
					</Typography>
					{
						// facebook or yelp or etc
						review.source && (
							<Typography variant="bodyMediumBook" color="text.secondary" sx={oneReviewSourceMargins}>
								Posted on {review.source}
							</Typography>
						)
					}
					{
						// review posted date
						review.reviewTimestamp && (
							<Typography variant="bodyMediumBook" color="text.secondary">
								{format(new Date(review.reviewTimestamp), 'MM/dd/yyyy')}
							</Typography>
						)
					}
				</Box>
			</Grid>
			{!showFullReview && <Divider sx={oneReviewDividerStyles} />}
		</>
	);
}
