import { Theme, alpha } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

import { TemplateWidth } from '@/types';

const getBackground = (imgUrl: string, theme: Theme) => {
	const gradient = theme.palette.backgrounds?.ODPHeroGradient ? `${theme.palette.backgrounds?.ODPHeroGradient},` : '';

	return `${gradient} ${imgUrl}, transparent 50% / cover no-repeat`;
};

export const getContainerStyles = (theme: Theme, imgUrl: string, templateWidth: TemplateWidth) => ({
	maxWidth: '1920px',
	width: templateWidth === 'Inset' ? '90%' : '100%',
	margin: '0 auto',
	minHeight: 'calc(100vh - 200px)',
	alignItems: 'center',
	background: getBackground(imgUrl, theme),
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	[theme.breakpoints.up('lg')]: { padding: '60px 5%' },
	[theme.breakpoints.down('lg')]: { padding: '60px 2%' },
	[theme.breakpoints.down('md')]: {
		padding: '40px 20px',
		minHeight: theme.tagFeatures?.ON_PAGE_SCHEDULING ? 'initial' : 'calc(100vh - 200px)',
		background: `linear-gradient(0deg, 
			${alpha(theme.palette.primary.dark, 0.85)} 0%, 
			${alpha(theme.palette.primary.dark, 0.85)} 100%), 
			${imgUrl} transparent 50% / cover no-repeat;`,
	},
});

export const getPromoTitleStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	textAlign: isSmallScreen ? 'center' : 'flex-start',
});

export const getGridStyles = (theme: Theme) => ({
	minWidth: '45%',
	maxWidth: '100%',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	[theme.breakpoints.up('lg')]: { maxWidth: '600px' },
	[theme.breakpoints.between('md', 'lg')]: { maxWidth: '50%' },
	[theme.breakpoints.down('md')]: {
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export const getTitleTextStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	textAlign: isSmallScreen ? 'center' : 'flex-start',
});

export const getVisitTypeSelectorContainerStyles = (theme: Theme) => ({
	padding: '2rem 2rem 2.25rem 2rem',
	backgroundColor: theme.palette.backgrounds?.white,
	borderRadius: '1.5rem',
	minHeight: '37.125rem',
});

export const getHeadingStyles = (theme: Theme, darkTextColor: boolean) => ({
	...resolveTypographyVariantStyles(theme, 'heroMedium'),
	color: darkTextColor ? theme.palette.text.secondary : theme.palette.text.light,
	textAlign: 'left',
	[theme.breakpoints.down('md')]: {
		textAlign: 'center',
	},
});
