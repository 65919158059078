import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

import { ContentfulBackground, DisableTemplatePaddingOn, Maybe, TemplateWidth, TemplateHeight } from '@/types';
import {
	ContentTypeRichTextContent,
	SingleMessage as TSingleMessage,
	TermsAndConditions as TTermsAndConditions,
	FocalPointImage as TFocalPointImage,
	SingleMessageButtonsCollection,
	SmallScreenContent,
	ImageAsset as TImageAsset,
} from '@/types/generated';
import { textToJumpLink, useTemplateContainerFullWidthStyles } from '@/utils';
import ImageAsset from '@/components/ImageAsset';
import { metaData } from '@/constants';

import { FocalPointData } from '../FocalPointImage';
import TAGSvgIcon, { IconName } from '../TAGSvgIcon';
import { resolveContentfulBgColor, translateTagColorNameForContentful } from '../../utils/resolveContentfulBgColor';
import FocalPointImage from '../FocalPointImage';
import TermsAndConditions from '../TermsAndConditions';
import ContentTypeRichText from '../ContentTypeRichText';
import TemplateContainer from '../TemplateContainer/TemplateContainer';
import ContentfulButton from '../ContentfulButton';
import { IImage } from '../ImageAsset/ImageAsset';

import {
	templateWrapperStyles,
	getContentWrapperStyles,
	backgroundImageStyles,
	getBodyContainerStyles,
	getContentAlignStyles,
	getSingleMessageButtonsWrapper,
	getSingleMessageRichTextContainerStyles,
	bannerImageStyles,
} from './SingleMessage.styles';

export interface ISingleMessage extends TSingleMessage {
	/**
	 * Required field for the SingleMessage id
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	name?: Maybe<string>;
	/**
	 * Collection of SIngleMessage buttons
	 *
	 * @type {Maybe<SingleMessageButtonsCollection>}
	 * @memberof ISingleMessage
	 */
	buttonsCollection?: Maybe<SingleMessageButtonsCollection>;
	/**
	 * Background color for the single message section coming from Contentful.
	 *
	 * @type {ContentfulBackground}
	 * @memberof ISingleMessage
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * Specifies single message button size.
	 *
	 * @type {ContentfulButtonSize}
	 * @memberof ISingleMessage
	 */
	buttonSize?: Maybe<string>;
	/**
	 * Background image information for the hero section.
	 *
	 * @type {Maybe<TFocalPointImage>}
	 * @memberof ISingleMessage
	 */
	focalPointImage?: Maybe<TFocalPointImage>;
	/**
	 * Button text coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	buttonText?: Maybe<string>;
	/**
	 * Button variant coming from Contentful. Can be 'Primary Default' or 'Secondary Default'.
	 *
	 * @type {Primary Default | Secondary Default}
	 * @memberof ISingleMessage
	 * @default 'Primary Default'
	 */
	buttonType?: Maybe<string>;
	/**
	 * Button Url coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	buttonUrl?: Maybe<string>;
	/**
	 * Text content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	eyebrow?: Maybe<string>;
	/**
	 * Icon image coming from Contentful.
	 *
	 * @type {IconName}
	 * @memberof ISingleMessage
	 */
	icon?: Maybe<string>;
	/**
	 * Optional terms and conditions content coming from Contentful.
	 *
	 * @type {TTermsAndConditions}
	 * @memberof ISingleMessage
	 */
	tc?: Maybe<TTermsAndConditions>;
	/**
	 * Width of the SingleMessage template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof ISingleMessage
	 * @default 'Full'
	 */
	templateWidth?: Maybe<string>;
	/**
	 * Height of the SingleMessage template. Can be Regular or Full.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof ISingleMessage
	 * @default 'Regular'
	 */
	templateHeight?: Maybe<string>;
	/**
	 * Optional subtext content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 * @deprecated use content rich text instead
	 */
	subText?: Maybe<string>;
	/**
	 * Optional description content coming from Contentful.
	 *
	 * @type {Maybe<Scalars['String']>}
	 * @memberof ISingleMessage
	 * @deprecated use content rich text instead
	 */
	description?: Maybe<string>;
	/**
	 * Title content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 * @deprecated use content rich text instead
	 */
	title?: Maybe<string>;
	/**
	 * Optional prop for determining SingleMessage content placement.
	 *
	 * @type {'row' | 'row-reverse'}
	 * @memberof ISingleMessage
	 * @default undefined
	 */
	direction?: Maybe<string>;
	/**
	 * Allows setting different content on small screens.
	 *
	 * @type {SmallScreenContent}
	 * @memberof ISingleMessage
	 * @default null
	 */
	contentSmallScreen?: Maybe<SmallScreenContent>;
	/**
	 * An option to disable left/right padding of content.
	 *
	 * @type {boolean}
	 * @memberof ISingleMessage
	 */
	disableGutters?: boolean;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof ISingleMessage
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * An option to either 'left' or 'center' align the Rich Text content
	 * @type {'left' | 'center'}
	 * @memberof ISingleMessage
	 * @default 'center'
	 */
	contentAlign?: Maybe<string>;
	/**
	 * Brandfolder Image with focal point.
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageAsset?: Maybe<TImageAsset>;
	/**
	 * Specifies whether the content should grow vertically
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	contentFillVerticalSpace?: Maybe<boolean>;
	/**
	 * Specifies whether image is a banner above content
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isBannerImage?: Maybe<boolean>;
	/**
	 * Enables an inline layout for icon and button
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isInlineLayout?: Maybe<boolean>;
	/**
	 * Enables rounded corners for container
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	useRoundedCorners?: Maybe<boolean>;
}

function SingleMessage({
	name,
	contentSmallScreen,
	buttonsCollection,
	backgroundColor,
	disableGutters = false,
	disablePaddingOn,
	focalPointImage,
	templateHeight,
	templateWidth,
	contentAlign = 'center',
	buttonSize,
	buttonText,
	buttonType,
	buttonUrl,
	content,
	eyebrow,
	icon,
	sys,
	tc,
	imageAsset,
	contentFillVerticalSpace,
	isBannerImage,
	isInlineLayout = false,
	useRoundedCorners = false,
}: Readonly<ISingleMessage>) {
	const { bodySmallScreen, eyebrowSmallScreen, focalPointImageSmallScreen } = Object(
		contentSmallScreen
	) as SmallScreenContent;
	const singleMessageButtons = buttonsCollection?.items || [];
	const sysId = sys.id;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isDarkBackground =
		backgroundColor?.toLowerCase().includes('dark') || backgroundColor?.toLowerCase() === 'saffron';
	const contentfulSafeBackgroundColor = translateTagColorNameForContentful(backgroundColor || '') || '';

	const bgColor = resolveContentfulBgColor(contentfulSafeBackgroundColor as ContentfulBackground, theme);
	const bgImage =
		isSmallScreen && focalPointImageSmallScreen ? focalPointImageSmallScreen?.image : focalPointImage?.image;
	const focalPoint = (
		isSmallScreen && focalPointImageSmallScreen
			? focalPointImageSmallScreen?.focalPoint
			: focalPointImage?.focalPoint
	) as FocalPointData;
	const eyebrowText = isSmallScreen && eyebrowSmallScreen ? eyebrowSmallScreen : eyebrow;
	const bodyContent = isSmallScreen && bodySmallScreen ? bodySmallScreen : content;
	const removePaddingFromContainer = isInlineLayout ? ['Top', 'Bottom'] : disablePaddingOn;
	const paddingStyles = useTemplateContainerFullWidthStyles(removePaddingFromContainer, isSmallScreen, true);
	const isContentCenterAligned = !contentAlign || contentAlign === 'center' ? true : false;

	const idLabel = textToJumpLink(name);
	const singleMessageImageStyles = isBannerImage ? bannerImageStyles : backgroundImageStyles;

	const SingleMessageContent = () => {
		return (
			<>
				{eyebrow && (
					<Box
						data-test-id="text_single_message_eyebrow_container"
						sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}
					>
						<Typography
							data-test-id="text_single_message_eyebrow"
							variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
							color={isDarkBackground || focalPointImage ? 'text.light' : 'text.primary'}
							margin="0.75rem 0"
							letterSpacing="0.0625rem"
						>
							{eyebrowText}
						</Typography>
					</Box>
				)}
				<Box
					data-test-id="single_message_rich_text_content_container"
					sx={{
						...getSingleMessageRichTextContainerStyles(isContentCenterAligned),
						...(contentFillVerticalSpace && { flex: 1 }),
					}}
				>
					{content ? (
						<ContentTypeRichText
							contentMaxWidth="100%"
							disablePaddingOn={['Top', 'Bottom']}
							backgroundColor="transparent"
							dataTestId="section_single_message_rich_content"
							containerSx={getBodyContainerStyles(isSmallScreen)}
							content={bodyContent as ContentTypeRichTextContent}
							docProps={isDarkBackground || focalPointImage ? { color: 'text.light' } : {}}
							centerAlign={isContentCenterAligned}
							disableGutters={!isContentCenterAligned}
						/>
					) : null}
				</Box>
				{/* will be deprecated */}
				{!singleMessageButtons.length && buttonText && buttonUrl && buttonType && (
					<Box sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}>
						<ContentfulButton
							_id={sysId}
							sys={sys}
							buttonLink={buttonUrl}
							label={buttonText}
							variant={buttonType}
							size={buttonSize}
							dataTestId="single_message_button"
							contentfulMetadata={metaData}
						/>
					</Box>
				)}

				{singleMessageButtons.length ? (
					<Box
						data-test-id="group_single_message_buttons_wrapper"
						sx={getSingleMessageButtonsWrapper(isContentCenterAligned, isInlineLayout)}
					>
						{singleMessageButtons.map((button) =>
							button ? (
								<ContentfulButton key={button.sys.id} {...button} dataTestId="single_message_button" />
							) : null
						)}
					</Box>
				) : null}

				{tc && (
					<Box
						data-test-id="group_single_message_terms_and_conditions"
						sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}
					>
						<TermsAndConditions
							{...tc}
							textColor={isDarkBackground || focalPointImage ? 'text.light' : 'text.secondary'}
						/>
					</Box>
				)}
			</>
		);
	};

	return (
		<TemplateContainer
			id={`single-message-${idLabel}`}
			maxWidth={false}
			contentfulBackgroundColor={bgColor}
			templateWidth={(templateWidth as TemplateWidth) || 'Full'}
			templateHeight={(templateHeight as TemplateHeight) || 'Standard'}
			dataTestId={`section_single_message_${sys.id}`}
			containerSx={{ ...templateWrapperStyles(templateWidth === 'Full', !!useRoundedCorners), ...paddingStyles }}
		>
			{imageAsset && (
				<ImageAsset
					desktopImage={imageAsset?.desktopImage as IImage}
					mobileImage={imageAsset?.mobileImage as IImage}
					imageSx={singleMessageImageStyles}
					isBackgroundImage={!isBannerImage}
					dataTestId={
						isBannerImage ? 'img_asset_single_message_banner' : 'img_asset_single_message_background'
					}
				/>
			)}
			{focalPointImage && !imageAsset && (
				<FocalPointImage
					image={bgImage}
					backgroundImage
					focalPoint={focalPoint}
					imageSx={backgroundImageStyles}
					dataTestId="focal_point_image"
				/>
			)}
			<Box
				data-test-id="section_single_message_content"
				sx={getContentWrapperStyles(isSmallScreen, isContentCenterAligned, isInlineLayout, disableGutters)}
			>
				{icon && (
					<Box
						data-test-id="icon_single_message_container"
						sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}
					>
						<TAGSvgIcon data-test-id="icon_single_message" icon={icon as IconName} size={64} />
					</Box>
				)}

				{(isInlineLayout && isSmallScreen && (
					<Box sx={getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen)}>
						<SingleMessageContent />
					</Box>
				)) || <SingleMessageContent />}
			</Box>
		</TemplateContainer>
	);
}

export default SingleMessage;
