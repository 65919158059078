/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { StateLinks as IStateLinks } from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { useAppContext, useOfficeLocationContext } from '@/context';
import { TState } from '@/types';
import { useTemplateContainerFullWidthStyles, clickContext, officePathByBrand } from '@/utils';
import TemplateContainer from '@/components/TemplateContainer';

import { linkItemWrapper } from '../Centers.styles';

export default function StateLinks({ sectionTitle }: IStateLinks) {
	const theme = useTheme();
	const { config } = useAppContext();
	const isMediumDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'));

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	const { states } = useOfficeLocationContext() as { states: TState[] };
	const facilityBasePath = config.features.otherApps.facilityDetailsBasePath;

	return (
		<TemplateContainer
			disableGutters={false}
			maxWidth={false}
			dataTestId="section_state_links_wrapper"
			containerSx={useTemplateContainerFullWidthStyles(['Top', 'Bottom'], isMediumDevice)}
		>
			<Container data-test-id="state_links_section">
				{sectionTitle && (
					<Typography
						variant="header4"
						color="text.secondary"
						my={1.5}
						data-test-id="state_links_section_title"
					>
						{sectionTitle}
					</Typography>
				)}
				{states.length ? (
					<Grid
						container
						spacing={2}
						columns={{ xs: 1, sm: 4 }}
						justifyContent="flex-start"
						data-test-id="state_links_box_wrapper"
					>
						{states.map((state: TState) => (
							<Grid
								data-test-id={`state_links_link_item_${state.abbreviation}`}
								key={`${state.abbreviation}`}
								item
								xs={1}
								sm={1}
								sx={linkItemWrapper}
							>
								<TAGLink
									href={`/${facilityBasePath}/${officePathByBrand(config.themeId, {
										stateStrOptions: {
											stateAbbreviation: state?.abbreviation,
											state: state.state,
											city: '',
										},
									})}/`}
									analyticsContext={clickContext({ brand: config.name, text: state.state })}
								>
									<Typography
										variant="bodyMediumBook"
										color="text.interactive"
										sx={{ textDecoration: 'underline' }}
									>
										{state.state}
									</Typography>
								</TAGLink>
							</Grid>
						))}
					</Grid>
				) : null}
			</Container>
		</TemplateContainer>
	);
}
