import enUS from 'date-fns/locale/en-US';
import { format, utcToZonedTime } from 'date-fns-tz';

export const supportedTZs: Record<string, string> = {
	EST: 'America/New_York',
	CST: 'America/Chicago',
	MST: 'America/Denver',
	PST: 'America/Los_Angeles',
};

export const formatUsingTZ = (dateStr: string | undefined | null, timezone: string, formatStr: string) =>
	dateStr ? format(utcToZonedTime(new Date(dateStr), timezone), formatStr, { timeZone: timezone, locale: enUS }) : '';
