import { ContentfulSpacerSize } from '@/types';

export function resolveContentfulSpacerSize(size?: ContentfulSpacerSize, isSmallDevice?: boolean) {
	switch (size) {
		case 'Small':
			return isSmallDevice ? '20px' : '40px';
		case 'Standard':
			return isSmallDevice ? '40px' : '60px';
		case 'Large':
			return isSmallDevice ? '60px' : '80px';
		case 'XLarge':
			return isSmallDevice ? '80px' : '100px';
		default:
			return isSmallDevice ? '40px' : '60px';
	}
}
