import { create } from 'zustand';

export interface LocationDetailsState {
	displayName: string;
	timezone: string;
	address: string;
	city: string;
	state: string;
	zip: string;
}

export interface LocationDetailsActions {
	setDisplayName: (displayName: string) => void;
	setTimezone: (timezone: string) => void;
	setAddress: (address: string) => void;
	setCity: (city: string) => void;
	setState: (state: string) => void;
	setZip: (zip: string) => void;
}

// Combining state and actions for the store
type LocationDetailsStore = LocationDetailsState & LocationDetailsActions;

const useLocationDetailsStore = create<LocationDetailsStore>((set) => ({
	displayName: '',
	setDisplayName: (displayName: string) => set({ displayName }),
	timezone: '',
	setTimezone: (timezone: string) => set({ timezone }),
	address: '',
	setAddress: (address: string) => set({ address }),
	city: '',
	setCity: (city: string) => set({ city }),
	state: '',
	setState: (state: string) => set({ state }),
	zip: '',
	setZip: (zip: string) => set({ zip }),
}));

export default useLocationDetailsStore;
