import { useEffect, useState } from 'react';

import { Grid, useMediaQuery, useTheme, Typography, Stack, Box } from '@mui/material';
import { useRouter } from 'next/router';

import { BffFacility } from '@/graphql/__generated/sdk';
import { SchedulingSystemType } from '@/components/GoogleMap/LocationCard/LocationCard';
import { resolvePhoneNumberFormat, resolveContentVariable } from '@/utils';
import TAGSvgIcon from '@/components/TAGSvgIcon';
import {
	ContentTypeRichText as TContentTypeRichText,
	OdpHeroButtonsCollection,
	Maybe,
	FocalPointImage,
	Facility,
} from '@/types/generated';
import { HeadingTag, TemplateWidth } from '@/types';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { useFacilityContext } from '@/context/FacilityContextProvider.ctx';
import VisitTypeSelector from '@/components/OnPageScheduling/VisitTypeSelector';
import { useAppContext, useOfficeLocationContext } from '@/context';
import { getScheduleButtonUrl } from '@/components/GoogleMap/LocationCard/LocationCard.helper';
import { NearbyFacility } from '@/types/generated';
import { FacilityBrand } from '@/types/generated';
import BirdEyeReviews from '@/components/BirdEyeReviews/BirdEyeReviews';
import { BffWorkingHours } from '@/graphql/__generated/sdk';
import { DAYS_OF_WEEK } from '@/constants';

import ContentTypeRichText from '../../ContentTypeRichText/ContentTypeRichText';
import ContentfulButton from '../../ContentfulButton';
import TAGButton from '../../TAGButton';

import OfficeHours from './OfficeHours';
import {
	getContainerStyles,
	getGridStyles,
	getPromoTitleStyles,
	getVisitTypeSelectorContainerStyles,
	getHeadingStyles,
} from './ODPHero.styles';
import { checkIsFacilityOpenBff } from './ODPHero.helpers';
import LocationStatusBadge from './LocationStautsBadge';

export type LocationOperationStatusType =
	| 'Open'
	| 'Closed'
	| 'Open-Planned Relo'
	| 'Temp-Closed'
	| 'Future Opening'
	| 'Open-Closed Weekends'
	| '';

export interface IODPHero {
	/**
	 * An option to set the width of the ODP hero section. Can be set to Full or Inset.
	 *
	 * @type {TemplateWidth}
	 * @memberof IODPHero
	 */
	templateWidth?: TemplateWidth;
	/**
	 * The background image displayed in the ODP hero section.
	 *
	 * @type {Maybe<FocalPointImage>}
	 * @memberof IODPHero
	 */
	image: Maybe<FocalPointImage>;
	/**
	 * Boolean controlled by content team to determine if the reviews should be displayed in the ODP hero section.
	 *
	 * @type {boolean}
	 * @memberof IODPHero
	 */
	showReviews?: boolean;
	/**
	 * The Promo Title displayed in the ODP hero section.
	 *
	 * @type {string}
	 * @memberof IODPHero
	 */
	promoTitle?: string;
	/**
	 * Boolean specifying the color of the text content. Hero text is going to be dark if the value is true.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IHero
	 */
	darkTextColor?: Maybe<boolean>;
	/**
	 * The title displayed in the ODP hero section.
	 *
	 * @type {string}
	 * @memberof IODPHero
	 */
	title?: string;
	/**
	 * The title heading tag. Can be h1, h2, h3, or h4
	 *
	 * @type {HeadingTag}
	 * @memberof IODPHero
	 */
	titleHtag?: HeadingTag;
	/**
	 * bodyCopy alias of description displayed below the title in the ODP hero section .
	 *
	 * @type {HeadingTag}
	 * @memberof IODPHero
	 */
	bodyCopy: Maybe<TContentTypeRichText>;
	/**
	 * A boolean set in contentful to determine if the address should be displayed in the ODP hero section.
	 *
	 * @type {HeadingTag}
	 * @memberof IODPHero
	 */
	showAddress?: boolean;
	/**
	 * A boolean set in contentful to determine if the phone should be displayed in the ODP hero section.
	 *
	 * @type {HeadingTag}
	 * @memberof IODPHero
	 */
	showPhoneNumber?: boolean;
	/**
	 * A boolean set in contentful to determine if the hours should be displayed in the ODP hero section.
	 *
	 * @type {HeadingTag}
	 * @memberof IODPHero
	 */
	showHours?: boolean;
	showFax?: boolean;
	showEmail?: boolean;
	/**
	 * ODP Hero cta buttons
	 *
	 * @type {Maybe<OdpHeroButtonsCollection>}
	 * @memberof IODPHero
	 */
	buttonsCollection?: Maybe<OdpHeroButtonsCollection>;
	/**
	 * ODP Hero cta buttons
	 *
	 * @type {string}
	 * @memberof IODPHero
	 */
	googleMapsBusinessQuery?: string;
	/**
	 * ODP Hero Appointment Data Source
	 * InHouse, RevSpring, None
	 *
	 * @type {string}
	 * @memberof IODPHero
	 */
	appointmentDataSource?: string;
	/**
	 * A boolean set in contentful to determine if the Location Status Badge should be displayed in the ODP hero.
	 *
	 * @type {boolean}
	 * @memberof IODPHero
	 */
	showLocationStatusBadge?: boolean;
	/**
	 * The label displayed in the ODP hero office hours.
	 *
	 * @type {string}
	 * @memberof IODPHero
	 */
	officeHoursLabel?: string;
	/**
	 * The label displayed in the ODP hero call center hours.
	 *
	 * @type {string}
	 * @memberof IODPHero
	 */
	callCenterLabel?: string;
}

export default function OdpHero({
	title,
	titleHtag,
	promoTitle,
	image,
	bodyCopy: description,
	showAddress = true,
	showPhoneNumber = true,
	showReviews = true,
	showHours = true,
	showFax = false,
	showEmail = false,
	buttonsCollection,
	templateWidth = 'Full',
	googleMapsBusinessQuery,
	appointmentDataSource = 'None',
	showLocationStatusBadge = false,
	darkTextColor = false,
	officeHoursLabel,
	callCenterLabel,
}: IODPHero) {
	const [state, setState] = useState({
		showHours: false,
		isBadgeReady: false,
		workingHoursForWeek: [] as BffWorkingHours[],
		locationStatus: 'Closed' as LocationOperationStatusType,
	});

	const router = useRouter();

	const theme = useTheme();
	const { officeInfo, facilityBrand, locationState } = useFacilityContext();
	const { officeInfo: legalCenterName } = useOfficeLocationContext();
	const { config } = useAppContext();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const titleTag = getHeadingVariant(titleHtag as HeadingTag);

	const street = officeInfo?.address?.address1 as string;
	const city = officeInfo?.address?.city as string;
	const officeState = officeInfo?.address?.stateCode as string;
	const zip = officeInfo?.address?.zipCode as string;
	const address = `${street} ${city}, ${officeState} ${zip}`;
	const phoneNumber =
		officeInfo?.technicalInfrastructure?.phoneNumber &&
		resolvePhoneNumberFormat(officeInfo.technicalInfrastructure.phoneNumber);
	const faxNumber = officeInfo?.technicalInfrastructure?.faxNumber;
	const email = officeInfo?.technicalInfrastructure?.email;

	const textColor = darkTextColor ? 'text.secondary' : 'text.light';

	// TODO: Temporarily handling a Schedule Button for WellNow since WellNow has more than 1 scheduling source/service. Will fix as soon as possible.

	const schedulingPath = config.features.otherApps.schedulingPath;

	const useFacilityBeffeDataSource = config.featureFlags.useFacilityBeffeDataSource;

	const pilotLocations = new Set(config?.featureFlags?.onPageSchedulingLocations || []);

	const isPilotLocation = officeInfo && pilotLocations.has(legalCenterName?.siteName || '');

	const locationScheduling = {
		schedulingSystem: isPilotLocation ? (locationState.schedulingSystem as SchedulingSystemType) : '#',
	};

	const wellNowFacilityObj = {
		address: {
			address1: address,
			address2: '',
			city: city,
			zipCode: zip,
			stateCode: officeState,
		},
		technicalInfrastructure: {
			phoneNumber: officeInfo?.technicalInfrastructure?.phoneNumber,
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		name: officeInfo?.name,
		scheduling: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			schedulingUrl: officeInfo?.scheduling?.schedulingUrl,
		},
		code: officeInfo?.code,
		brand: facilityBrand,
		location: {
			latitude: officeInfo?.location?.latitude as number,
			longitude: officeInfo?.location?.longitude as number,
		},
	} as NearbyFacility['facility'];

	const isForWellNow = facilityBrand === FacilityBrand.WellNow || config.name === 'WellNow Urgent Care';

	const handleHoursClick = () => {
		setState({
			...state,
			showHours: true,
		});
	};

	const handleClose = () => {
		setState({
			...state,
			showHours: false,
		});
	};

	const getGoogleLink = () => {
		return resolveContentVariable(
			`${googleMapsBusinessQuery || config.googleMapBusinessQuery}`,
			officeInfo as Maybe<Facility>
		);
	};

	const shouldShowVisitTypes =
		theme.tagFeatures?.ON_PAGE_SCHEDULING &&
		appointmentDataSource === 'RevSpring' &&
		officeInfo?.scheduling?.schedulingUrl;

	const navigateToSchedulePage = () => {
		void router.push('/schedule-appointment/');
	};

	const imgUrl = image?.image?.url ? `url(${image.image?.url})` : '';

	const isFacilityOpenBff =
		state.locationStatus === ('Temp-Closed' as LocationOperationStatusType) ||
		state.locationStatus === ('Future Opening' as LocationOperationStatusType)
			? 'Not in operation'
			: checkIsFacilityOpenBff(state.workingHoursForWeek);

	useEffect(() => {
		setState({
			...state,
			isBadgeReady: !(isFacilityOpenBff === null || isFacilityOpenBff === undefined),
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFacilityOpenBff]);

	useEffect(() => {
		if (officeInfo) {
			const { workingHours } = officeInfo;

			const workingHoursForWeek =
				workingHours && workingHours.filter((day) => DAYS_OF_WEEK.includes(day?.date as string));
			const officeInformation = officeInfo as BffFacility;

			setState({
				...state,
				...(workingHoursForWeek && workingHoursForWeek.length
					? { workingHoursForWeek: workingHoursForWeek as BffWorkingHours[] }
					: {}),
				...(officeInformation && Object.keys(officeInformation).includes('status')
					? { locationStatus: (officeInformation.status as LocationOperationStatusType) || '' }
					: {}),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [officeInfo]);

	return (
		<>
			{state.showHours && officeInfo?.workingHours && (
				<OfficeHours
					handleClose={handleClose}
					workingHours={officeInfo?.workingHours}
					useFacilityBeffeDataSource={useFacilityBeffeDataSource}
					officeHoursLabel={officeHoursLabel}
					callCenterLabel={callCenterLabel}
				/>
			)}

			<Grid
				container
				columnGap={theme.tagFeatures?.ON_PAGE_SCHEDULING ? '160px' : ''}
				flexWrap={theme.tagFeatures?.ON_PAGE_SCHEDULING ? 'nowrap' : 'wrap'}
				data-test-id="odp_hero_container"
				sx={getContainerStyles(theme, imgUrl, templateWidth)}
			>
				<Grid
					item
					container
					direction="column"
					data-test-id="odp_hero_grid"
					sx={getGridStyles(theme)}
					xs={12}
					md={6}
					gap={2}
				>
					{/* Location Status Badge */}
					{!showLocationStatusBadge ? null : state.isBadgeReady ? (
						<Grid item data-test-id="odp_hero_location_status_badge">
							{
								<LocationStatusBadge
									fallBackComponent={<Box sx={{ height: '1.25rem' }} />}
									isFacilityOpen={isFacilityOpenBff}
									locationStatus={state.locationStatus}
								/>
							}
						</Grid>
					) : (
						<Grid item height={'1.25rem'} />
					)}

					{/* Reviews Modal here */}
					{showReviews && (
						<Box sx={{ display: 'flex' }} data-test-id="odp_hero_item_review">
							<BirdEyeReviews onlyStarsAndLink />
						</Box>
					)}

					{/* Promo title */}
					{promoTitle && (
						<Grid item data-test-id="odp_hero_promo_title">
							<Typography
								data-test-id="odp_hero_promo_title_text"
								variant={isSmallScreen ? 'heroLarge' : 'heroXL'}
								color="text.light"
								sx={getPromoTitleStyles(isSmallScreen)}
							>
								{promoTitle}
							</Typography>
						</Grid>
					)}

					{/* Title */}
					<Grid item data-test-id="odp_hero_item_title">
						<Typography
							data-test-id="odp_hero_item_title_text"
							variant={titleTag}
							sx={getHeadingStyles(theme, darkTextColor as boolean)}
							component={titleHtag === 'normal' ? 'p' : titleHtag === undefined ? 'h1' : titleHtag}
						>
							{resolveContentVariable(title as string, officeInfo as Maybe<Facility>)}
						</Typography>
					</Grid>

					{/* Description */}
					{description?.content && (
						<Grid item data-test-id="odp_hero_item_description" pb={1.5}>
							<ContentTypeRichText
								{...description}
								disableGutters
								disablePaddingOn={['Top', 'Bottom']}
								contentMaxWidth="100%"
								backgroundColor="transparent"
								docProps={{
									color: `${textColor}`,
									variant: 'bodyLargeBook',
									textAlign: isSmallScreen ? 'center' : 'left',
								}}
							/>
						</Grid>
					)}

					{/* Office Info */}
					<Grid item data-test-id="odp_hero_item_office_block">
						<Grid
							container
							spacing={2.5}
							justifyContent={isSmallScreen ? 'center' : 'flex-start'}
							alignItems={isSmallScreen ? 'center' : 'flex-start'}
						>
							{/* Address link */}
							{showAddress && street !== undefined && (
								<Grid item data-test-id="odp_hero_item_address">
									<TAGButton
										size="S"
										startIcon={<TAGSvgIcon icon="MapMarker" size={24} />}
										variant="tertiaryHC"
										href={getGoogleLink()}
										target="_blank"
									>
										{address}
									</TAGButton>
								</Grid>
							)}

							{/* Phone number */}
							{showPhoneNumber && phoneNumber !== undefined && (
								<Grid item data-test-id="odp_hero_item_phone">
									<TAGButton
										size="S"
										startIcon={<TAGSvgIcon icon="CallIcon" size={24} />}
										variant="tertiaryHC"
										href={`tel:${phoneNumber as string}`}
									>
										{phoneNumber}
									</TAGButton>
								</Grid>
							)}

							{/* TODO: Currently Fax and Email data is being used from Enterprise API which results in a content shift within the ODPHero.
									Will need a follow-up to address this issue.  */}

							{/* Fax */}
							{showFax && faxNumber && (
								<Grid item data-test-id="odp_hero_item_fax_number">
									<Stack alignItems="center" direction="row" gap={1} p=".3rem">
										<TAGSvgIcon icon="Fax" color="white" size={24} />
										<Typography color="text.light" title="Fax Number">
											{faxNumber}
										</Typography>
									</Stack>
								</Grid>
							)}

							{/* Email */}
							{showEmail && email && (
								<Grid item data-test-id="odp_hero_item_email">
									<TAGButton
										size="S"
										startIcon={<TAGSvgIcon icon="Email" size={24} />}
										variant="tertiaryHC"
										href={`mailto:${email}?subject=Contact from Office Page`}
										target="_blank"
									>
										Email
									</TAGButton>
								</Grid>
							)}

							{/* Hours */}
							{showHours &&
								Array.isArray(officeInfo?.workingHours) &&
								(officeInfo?.workingHours || []).length > 0 && (
									<Grid item data-test-id="odp_hero_item_hours">
										<TAGButton
											dataTestId="odp_hero_item_hours_button"
											size="S"
											startIcon={<TAGSvgIcon icon="Clock" size={24} />}
											variant="tertiaryHC"
											onClick={handleHoursClick}
										>
											Hours
										</TAGButton>
									</Grid>
								)}
						</Grid>
					</Grid>

					{/* Schedule CTA */}
					{/* TODO: Temporarily handling a Schedule Button for WellNow since WellNow has more than 1 scheduling source/service. Will fix as soon as possible. */}
					{buttonsCollection?.items?.map((button) => {
						return button ? (
							<Grid key={button?.sys?.id}>
								<ContentfulButton
									{...button}
									buttonLink={
										isForWellNow
											? `${getScheduleButtonUrl(
													schedulingPath,
													facilityBrand,
													wellNowFacilityObj,
													locationScheduling
											  )}`
											: button?.buttonLink
									}
									dataTestId="odp_hero_item_button"
								/>
							</Grid>
						) : null;
					})}
				</Grid>

				{/* On Page Scheduling Visit Types Selector */}
				{!isSmallScreen && shouldShowVisitTypes && (
					<Grid item container direction={'column'} md={6} data-test-id="odp_hero_visit_type_selector">
						<Grid item container direction={'column'} sx={getVisitTypeSelectorContainerStyles(theme)}>
							<VisitTypeSelector postSelectionAction={navigateToSchedulePage} />
						</Grid>
					</Grid>
				)}
			</Grid>

			{/* On Page Scheduling Visit Types Selector Mobile Bottom */}
			{isSmallScreen && shouldShowVisitTypes && (
				<Grid container>
					<Grid
						item
						container
						direction={'column'}
						data-test-id="odp_hero_visit_type_selector_mobile"
						sx={getVisitTypeSelectorContainerStyles(theme)}
					>
						<VisitTypeSelector postSelectionAction={navigateToSchedulePage} />
					</Grid>
				</Grid>
			)}
		</>
	);
}
