import React from 'react';

import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { STATE_CODES, IStateCodes, ALL_WELLNOW_OPERATING_REGIONS } from '@/constants';
import { Maybe } from '@/types';
import { FacilityBrand, InsuranceIntro, UsStateCode } from '@/types/generated';
import { getInsuranceCompanies } from '@/services/useInsurances';
import RichText, { RichTextContent } from '@/components/RichText';
import { logWarning } from '@/utils/miscUtils';
import { dataLayerConfig } from '@/utils/dataLayerConfig';
import { useFacilityContext } from '@/context';
import { useAppContext } from '@/context/AppContextProvider.ctx';

import {
	getDocProps,
	getWrapperStyles,
	getSelectSectionStyles,
	getInputLabelStyles,
	getSelectStyles,
	getSelectBoxStyles,
	getSelectTextStyles,
	containerStyles,
} from './Insurance.styles';
import InsuranceList, { IInsuranceListProps } from './InsuranceList/InsuranceList';

export interface IInsuranceProps {
	/**
	 * Specifies the title of the template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IInsuranceProps
	 */
	title?: Maybe<string>;
	/**
	 * Rich text content that displays insurance intro
	 *
	 * @memberof IInsuranceProps
	 */
	intro?: Maybe<InsuranceIntro>;
}

export default function Insurance({ title, intro }: IInsuranceProps) {
	const [selectedState, setSelectedState] = React.useState<string>('');
	const [expanded, setExpanded] = React.useState(false);
	const [insuranceNames, setInsuranceNames] = React.useState<IInsuranceListProps['insuranceList']>([]);
	const { facilityBrand } = useFacilityContext();
	const { environment, config, appName, appVersion } = useAppContext();
	const theme = useTheme();
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));
	const isMediumDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'));
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
	const screenSize = isLargeDevice ? 'lg' : isMediumDevice ? 'md' : 'sm';
	const url =
		environment === 'prod'
			? config.services.prod.ENTERPRISE_API_GRAPHQL
			: config.services.nonprod.ENTERPRISE_API_GRAPHQL;
	if (!title) return null;

	const getStateCode = (stateName: string) => {
		const stateObj = STATE_CODES.find((state) => state.state === stateName);
		return stateObj ? stateObj.code : '';
	};

	const handleStateChange = (event: SelectChangeEvent) => {
		const state = event.target.value;
		setSelectedState(state);
		const twoLetterState = getStateCode(state);
		dataLayerConfig({
			event: 'module_click',
			module_name: 'Insurance',
			cta_status: '',
			link_text: state,
			link_url: globalThis?.location?.hostname,
		});
		getInsuranceCompanies(twoLetterState as UsStateCode, facilityBrand as FacilityBrand, url, appName, appVersion)
			.then((availableInsurances) => {
				// eslint-disable-next-line promise/always-return
				setInsuranceNames(availableInsurances || []);
				setExpanded(false);
			})
			.catch((error) => {
				// Handle any errors that occurred during the API call.
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				logWarning(`Error fetching insurance companies: ${error}`);
				// Optionally, setInsuranceNames to an empty array to clear the list on error.
				setInsuranceNames([]);
				setExpanded(false);
			});
	};

	const showMore = isSmallDevice && insuranceNames.length >= 10;

	const expandedList = expanded ? insuranceNames : insuranceNames.slice(0, 9);

	const insuranceList = isSmallDevice ? expandedList : insuranceNames;

	const getStates = (statesArray: IStateCodes[]) => {
		return statesArray.map((stateObj) => stateObj.state);
	};

	const allOperatingStates =
		facilityBrand == FacilityBrand.WellNow ? getStates(ALL_WELLNOW_OPERATING_REGIONS) : getStates(STATE_CODES);

	const InsuranceIntro = () => {
		if (!intro) return null;
		return (
			<Box data-test-id="section_insurance_intro" gap={2}>
				<RichText content={intro?.json as RichTextContent} links={intro?.links} docProps={getDocProps} />
			</Box>
		);
	};

	return (
		<Box sx={containerStyles}>
			<Box
				data-test-id="section_insurance"
				display={'flex'}
				flexDirection={'column'}
				sx={getWrapperStyles(screenSize)}
			>
				<Box
					display={'flex'}
					flexDirection={isSmallDevice ? 'column' : 'row'}
					justifyContent={'space-between'}
					alignContent={'center'}
					gap={2}
					pb={3}
					data-test-id="section_insurance_header"
				>
					{title && <Typography variant="header2">{title}</Typography>}
					{allOperatingStates.length !== 0 && (
						<Box sx={getSelectBoxStyles(isSmallDevice)} gap={2}>
							<Box sx={getSelectSectionStyles(isSmallDevice)}>
								<Typography variant="bodyMediumSemiBold" sx={getSelectTextStyles} color="text.tertiary">
									Choose your state
								</Typography>
							</Box>
							<FormControl fullWidth>
								<InputLabel data-test-id="section_insurance_selectlabel" sx={getInputLabelStyles}>
									State
								</InputLabel>
								<Select
									fullWidth
									sx={getSelectStyles}
									data-test-id="section_insurance_select"
									value={selectedState}
									label="State"
									onChange={handleStateChange}
								>
									{allOperatingStates.map((state) => {
										return (
											<MenuItem value={state} key={state} data-test-id="section_insurance_menu">
												<Typography variant="buttonLabel">{state}</Typography>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Box>
					)}
				</Box>
				<InsuranceIntro />
				<InsuranceList
					insuranceList={insuranceList}
					expanded={expanded}
					showMore={showMore}
					setExpanded={setExpanded}
				/>
			</Box>
		</Box>
	);
}
