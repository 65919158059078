import { Maybe, Facility } from '@/types/generated';

type VariableMap = { [key: string]: string | undefined };

export function resolveContentVariable(template: string, officeInfo: Maybe<Facility>): string {
	const city = officeInfo?.address?.city ?? '';
	const stateCode = officeInfo?.address?.stateCode ?? '';
	const address1 = officeInfo?.address?.address1 ?? '';
	const zipCode = officeInfo?.address?.zipCode ?? '';
	const schedulingUrl = officeInfo?.scheduling?.schedulingUrl ?? '';

	const variables: VariableMap = {
		facilityName: officeInfo?.name || 'Your Facility',
		brandName: officeInfo?.brand || 'Your Brand',
		facilityCityState:
			officeInfo?.address.city && officeInfo.address.stateCode ? `${city}, ${stateCode}` : 'Your Area',
		facilityCity: city || 'Your City',
		facilityState: stateCode || 'Your State',
		facilityCode: officeInfo?.code,
		schedulingUrl: schedulingUrl || '',
		address: officeInfo?.address.address1 ? `${address1}, ${city}, ${stateCode} ${zipCode}` : 'Your Address',
	};

	return Object.keys(variables).reduce((result, key) => {
		if (!result.includes(`[${key}]`)) {
			return result;
		}

		const pat = `\\[${key}\\]`;
		const RE = RegExp;

		const variablePattern = new RE(pat, 'g');
		return result.replace(variablePattern, variables[key] ?? '');
	}, template);
}
