import { Theme } from '@mui/material';

import { Maybe } from '@/types';

export const getCardStyles = ({
	backgroundColor: cardBackgroundColor,
	isDarkWrapperBackground,
	displayAsStepper,
	isSmallScreen,
}: {
	backgroundColor?: Maybe<string>;
	isDarkWrapperBackground: boolean;
	displayAsStepper: boolean;
	isSmallScreen: boolean;
}) => ({
	position: 'relative', // must be non-static because of the AspectRatioPaddingBox
	border: 'none',
	boxShadow: 'none',
	borderRadius: 0,
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	backgroundColor: cardBackgroundColor || 'transparent',
	flexBasis: 0,
	//covers the case when card's background is set but MultiCard's background is dark
	...(cardBackgroundColor !== 'transparent' && isDarkWrapperBackground
		? {
				'& .MuiTypography-root': {
					color: 'text.secondary',
				},
		  }
		: {}),
	...(displayAsStepper && isSmallScreen
		? {
				alignItems: 'flex-start',
		  }
		: {}),
});

export const getCardContentStyles = (
	isSmallScreen: boolean,
	displayAsStepper: boolean,
	cardsAlignment?: Maybe<string>
) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
	...(cardsAlignment === 'center'
		? { padding: '1.25rem 1.25rem 1.75rem 1.25rem' }
		: { padding: '1.25rem 0 1.75rem 0', paddingRight: isSmallScreen ? '2rem' : '2.5rem' }),
	alignItems: displayAsStepper && isSmallScreen ? 'flex-start' : cardsAlignment || 'flex-start',
	zIndex: 1,
	...(displayAsStepper && isSmallScreen
		? {
				paddingLeft: 0,
		  }
		: {}),
});

export const getCardActionStyles = ({
	isSmallScreen,
	cardActionAlignment,
	displayAsStepper,
}: {
	isSmallScreen: boolean;
	cardActionAlignment?: Maybe<string>;
	displayAsStepper: boolean;
}) => ({
	paddingX: isSmallScreen ? '0.5rem' : 0,
	paddingTop: '0',
	paddingBottom: '1.75rem',
	justifyContent: displayAsStepper && isSmallScreen ? 'flex-start' : cardActionAlignment || 'flex-start',
});

export const pdfLinkStyles = {
	zIndex: 10,
	textDecoration: 'none',
};

export const getAvatarStyles = (theme: Theme, hasImageOrIcon: boolean) => ({
	width: { xs: '8.75rem', md: '10rem' },
	height: { xs: '8.75rem', md: '10rem' },
	background: hasImageOrIcon ? theme.palette.backgrounds?.light : theme.palette.neutrals?.dark,
	'.MuiAvatar-fallback': {
		display: 'none',
	},
});

export const getStepperCardBoxStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	justifyContent: isSmallScreen ? 'flex-start' : 'center',
	alignItems: 'center',
	width: '100%',
});
