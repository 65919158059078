import { Grid, Typography, useTheme } from '@mui/material';

import { dataLayerConfig } from '@/utils';
import TAGSvgIcon from '@/components/TAGSvgIcon';
import TAGButton from '@/components/TAGButton';

import { getGridContainerStyles } from './VirtualCareNow.styles';

export interface IVirtualCareNowProps {
	/**
	 * Display alternate headline
	 * @type {boolean}
	 * @memberof IVirtualCareNowProps
	 */
	alternateHeadline?: boolean;
	virtualCareDataLayer?: { [key: string]: string };
}

export default function VirtualCareNow({ alternateHeadline = false, virtualCareDataLayer }: IVirtualCareNowProps) {
	const theme = useTheme();

	const virtualCareClick = () => {
		if (!virtualCareDataLayer) return;

		dataLayerConfig({
			...virtualCareDataLayer,
			event: 'saa_flow',
			step_name: 'virtual_care_now',
			brand: 'WellNow',
		});
	};

	return (
		<Grid
			data-test-id="section_virtual_care_now"
			container
			direction={'column'}
			p={2.5}
			sx={getGridContainerStyles(theme)}
		>
			<Grid item container direction={'column'} mb={2}>
				<Grid item data-test-id={`virtual_care_grid_item_headline`}>
					<Typography variant="bodyMediumBold" color="text.primary">
						{!alternateHeadline
							? 'Virtual Care is available 24/7'
							: `Don't see times that are good for you?`}
					</Typography>
				</Grid>
				<Grid item data-test-id={`virtual_care_grid_item_body`}>
					<Typography variant="bodyMediumBook" color="text.secondary" component={'p'}>
						Connect with a board-certified provider online in 5 minutes for a range of symptoms.
					</Typography>
				</Grid>
			</Grid>
			<Grid item data-test-id={`virtual_care_grid_item_button`}>
				<TAGButton
					variant="secondaryDefault"
					size="S"
					href="https://wellnow.zipnosis.com/"
					dataTestId="virtual_care_button"
					startIcon={<TAGSvgIcon icon={'WellNowVirtualCare'} size={20} fill="currentColor" />}
					onClick={() => virtualCareClick()}
				>
					Virtual Care now
				</TAGButton>
			</Grid>
		</Grid>
	);
}
