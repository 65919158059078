import { createContext } from 'react';

import { useSafeContext } from '@aspendental/shared-utils/react';

export type PageTheme = 'Primary' | 'Allergy' | 'Occupational Medicine' | 'Virtual Care' | 'Motto';

const PageThemeContext = createContext('Primary');
PageThemeContext.displayName = 'PageThemeContext';

interface IPageThemeContextProvider {
	pageTheme: PageTheme;
	children: React.ReactNode;
}

export function PageThemeContextProvider({ pageTheme, children }: IPageThemeContextProvider) {
	return <PageThemeContext.Provider value={pageTheme}>{children}</PageThemeContext.Provider>;
}

export function usePageThemeContext() {
	return useSafeContext(PageThemeContext);
}
