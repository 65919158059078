export const getSwiperContainerStyles = (isDarkBackground: boolean) => ({
	padding: '2.5rem',
	'& .swiper-wrapper': {
		padding: '2rem 0',
	},
	'& .swiper-button-prev, & .swiper-button-next': {
		color: isDarkBackground ? 'text.light' : 'text.interactive',
	},
	'& .swiper-button-disabled': {
		color: isDarkBackground ? 'text.light' : 'text.interactive',
	},
	'& .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination-bullet': {
		backgroundColor: isDarkBackground ? 'text.light' : 'text.interactive',
	},
});

export const getSwiperStyles = ({ isSmallScreen }: { isSmallScreen: boolean }) => ({
	...(isSmallScreen && { paddingBottom: '2rem' }),
});

export const getReviewWrapperStyles = (isSmallScreen: boolean) => ({
	gap: '2rem',
	maxWidth: '48rem',
	margin: '0 auto',
	...(!isSmallScreen && { paddingBottom: '2rem' }),
});
