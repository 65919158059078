import { Box, useMediaQuery, useTheme } from '@mui/material';

import ContentTypeRichText from '@/components/ContentTypeRichText';

import { IDescription } from '../ProductFeature.type';
import ContentfulButton from '../../ContentfulButton';

import { buttonBoxStyles, descriptionWrapperStyles } from './Description.styles';

export default function Description({
	descriptionRichText,
	descriptionButton,
	descriptionButtonsCollection,
}: IDescription) {
	const theme = useTheme();
	const isSmall: boolean = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box data-test-id="product_feature_description" sx={{ ...descriptionWrapperStyles(theme) }}>
			{
				<ContentTypeRichText
					content={descriptionRichText.content}
					dataTestId="product_description_Rich_Text"
					contentMaxWidth="100%"
					backgroundColor="transparent"
					disableGutters
					disablePaddingOn={['Top', 'Bottom']}
					docProps={{
						variant: isSmall ? 'bodyMediumBook' : 'bodyLargeBook',
					}}
				/>
			}
			<Box sx={buttonBoxStyles(isSmall)}>
				{descriptionButtonsCollection ? (
					descriptionButtonsCollection.items.map(
						(button) =>
							button && (
								<ContentfulButton
									key={button.sys.id}
									{...button}
									isSmallScreen={isSmall}
									dataTestId={`product_feature_description_button`}
								/>
							)
					)
				) : descriptionButton ? (
					<ContentfulButton
						{...descriptionButton}
						isSmallScreen={isSmall}
						dataTestId="product_feature_description_button"
					/>
				) : null}
			</Box>
		</Box>
	);
}
