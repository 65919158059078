import { Location } from '@/rest/__generated/revspringApi';
import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

export default async function getNearbyLocations(params: {
	longitude?: number;
	latitude?: number;
	queryString?: string;
}) {
	try {
		const nearbyLocations = await httpClient.request<Location[]>({
			url: `Location`,
			params: {
				...params,
				recordsPerPage: 5,
			},
			requestName: 'getNearbyLocations',
		});

		return nearbyLocations;
	} catch (err) {
		logWarning(`Could not find nearby locations`);
		if (err instanceof Error) {
			logError(`REVSPRING_NEARBY_LOCATIONS_FAILURE: ${err.message}`);
		}
		return [];
	}
}
