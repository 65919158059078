import { Fragment } from 'react';

import { Box, Divider } from '@mui/material';
import dynamic from 'next/dynamic';

import { PatientReviewsV2ReviewsCollection } from '@/types/generated';
import { Maybe, TReviewsMode } from '@/types';
import { IconName } from '@/components/TAGSvgIcon';

import Review, { Score } from './Review';
import { getContainerStyles, getDividerStyles } from './Reviews.styles';

const ReviewsCarousel = dynamic(() => import('./ReviewsCarousel'), { ssr: false });

export interface IReviews {
	reviews: PatientReviewsV2ReviewsCollection['items'];
	trimThreshold: number;
	useDividers?: boolean;
	vertical?: boolean;
	contentAlign?: Maybe<string>;
	isDarkBackground?: boolean;
	mode?: TReviewsMode;
	scoreIcon?: IconName;
}

export default function Reviews({
	reviews,
	trimThreshold,
	useDividers = false,
	vertical = false,
	contentAlign = 'Center',
	isDarkBackground = false,
	mode = 'list',
	scoreIcon,
}: IReviews) {
	const containerStyles = getContainerStyles({
		itemCount: reviews.length,
		useDividers,
		vertical,
	});

	if (mode === 'carousel') {
		return (
			<Box data-test-id="group_patient_reviews_carousel">
				<ReviewsCarousel
					reviews={reviews}
					isDarkBackground={isDarkBackground}
					trimThreshold={trimThreshold}
					contentAlign={contentAlign}
					scoreIcon={scoreIcon}
				/>
			</Box>
		);
	}

	return (
		<Box sx={containerStyles} data-test-id="group_patient_reviews">
			{reviews.map((review, index) =>
				review ? (
					<Fragment key={review.sys.id}>
						{useDividers && index > 0 && (
							<Divider
								data-test-id={`hr_patient_reviews_divider_${index}`}
								variant="middle"
								orientation={vertical ? 'horizontal' : 'vertical'}
								sx={getDividerStyles({ vertical })}
							/>
						)}
						<Review
							isDarkBackground={isDarkBackground}
							score={review.score ? (review.score as Score) : null}
							subText1={review.subText1}
							subText2={review.subText2}
							text={review.text}
							trimThreshold={trimThreshold}
							dataTestIdSuffix={review.sys.id}
							contentAlign={contentAlign}
							scoreIcon={scoreIcon}
						/>
					</Fragment>
				) : null
			)}
		</Box>
	);
}
