import { FacilityDailyWorkingHours } from '@/types/generated';

import { getDayOfWeek, getDayOfWeekFromDate } from '../getDayOfWeek';
import { get12HourTime } from '../get12HourTime/get12HourTime';

export const getTodayWorkingHours = (workingHours: FacilityDailyWorkingHours[]) => {
	const dayOfWeek = getDayOfWeek();
	const hours = workingHours.find((hour) => getDayOfWeekFromDate(hour.date as Date) === dayOfWeek);

	if (hours?.open?.from && hours?.open?.to) {
		return `${get12HourTime(hours.open.from as string)} - ${get12HourTime(hours.open.to as string)}`;
	}

	return 'Closed';
};
