import { Box, Stack, Fab, Typography, useTheme, useMediaQuery } from '@mui/material';

import { Maybe, ContentfulBackground, TemplateWidth, DisableTemplatePaddingOn } from '@/types';
import {
	QuickLinks as TQuickLinks,
	QuickLinksBody,
	QuickLinksButtonsCollection,
	QuickLinksHeading,
	QuickLinksQuickLinksCollection,
	ContentTypeRichTextContent,
	Button as TButton,
} from '@/types/generated';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import { addClassName, resolveContentfulBgColor } from '@/utils';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import TAGLink from '@/components/TAGLink';

import TemplateContainer from '../TemplateContainer';
import ContentfulButton from '../ContentfulButton';
import TAGButton from '../TAGButton';

import QuickLinkType from './QuickLinkType';
import {
	wrapperStyles,
	getEyebrowStyles,
	quickLinkStyles,
	quickLinkLabelStyles,
	quickLinkButtonStyles,
	quickLinkIconBufferStyles,
	getQuickLinksWrapperStyles,
	getQuickLinkAsTitlesStyles,
	getQuickLinkTitleStyles,
} from './QuickLinks.styles';

interface IQuickLinkAsTitle extends TButton {
	/**
	 * if the background color is a dark background
	 *
	 * @type {boolean}
	 * @memberof QuickLinkAsTitle
	 * @default false
	 */
	isDarkBackground: boolean;
	/**
	 * if display is smallScreen
	 *
	 * @type {boolean}
	 * @memberof QuickLinkAsTitle
	 * @default false
	 */
	isSmallScreen: boolean;
	isMediumScreen: boolean;
}

function QuickLink({ ...buttonData }: TButton) {
	return (
		<TAGButton
			key={buttonData.sys.id}
			href={buttonData.buttonLink || '#'}
			className={addClassName(buttonData.buttonLink)}
			sx={quickLinkStyles}
			dataTestId={`button_quick_link_${buttonData.sys.id}`}
			variant="primaryHC"
			size="M"
			analyticsContext={buttonData.analyticsContext}
		>
			<>
				{buttonData.icon && (
					<>
						<TAGSvgIcon
							icon={buttonData.icon as IconName}
							size={30}
							dataTestId={`icon_quick_link_${buttonData.sys.id}`}
						/>
						<Box sx={quickLinkIconBufferStyles} />
					</>
				)}

				<Typography sx={quickLinkLabelStyles} data-test-id={`text_quick_link_${buttonData.sys.id}`}>
					{buttonData.label}
				</Typography>

				<Fab size="small" disabled sx={quickLinkButtonStyles}>
					<TAGSvgIcon icon="ArrowRightFunc" />
				</Fab>
			</>
		</TAGButton>
	);
}

function QuickLinkAsTitle({
	isDarkBackground = false,
	isSmallScreen = false,
	isMediumScreen = false,
	...buttonData
}: Readonly<IQuickLinkAsTitle>) {
	return (
		<Box sx={getQuickLinkAsTitlesStyles(isSmallScreen, isMediumScreen)} data-test-id="quick_link_as_title">
			{buttonData?.buttonLink ? (
				<TAGLink href={buttonData.buttonLink} linkSx={getQuickLinkTitleStyles(isSmallScreen)}>
					<QuickLinkType
						buttonData={buttonData}
						isLink={true}
						isDarkBackground={isDarkBackground}
						isSmallScreen={isSmallScreen}
					/>
				</TAGLink>
			) : buttonData?.label ? (
				<Box sx={getQuickLinkTitleStyles(isSmallScreen)}>
					<QuickLinkType
						buttonData={buttonData}
						isLink={false}
						isDarkBackground={isDarkBackground}
						isSmallScreen={isSmallScreen}
					/>
				</Box>
			) : null}
		</Box>
	);
}

export interface IQuickLinks extends TQuickLinks {
	/**
	 * Background color for the QuickLinks template coming from Contentful.
	 *
	 * @type {ContentfulBackground}
	 * @memberof IQuickLinks
	 * @default 'Neutral'
	 */
	backgroundColor: Maybe<string>;
	/**
	 * Body rich text content.
	 *
	 * @type {Maybe<QuickLinksBody>}
	 * @memberof IQuickLinks
	 */
	body?: Maybe<QuickLinksBody>;
	/**
	 * Buttons to be displayed in the QuickLinks section.
	 *
	 * @type {QuickLinksButtonsCollection}
	 * @memberof IQuickLinks
	 */
	buttonsCollection: QuickLinksButtonsCollection;
	/**
	 * Specifies the eyebrow text content.
	 *
	 * @type {Maybe<string>}
	 * @memberof IQuickLinks
	 */
	eyebrow?: Maybe<string>;
	/**
	 * Specifies the eyebrow style. Can be Standard or Promo.
	 *
	 * @type {Maybe<TAGEyebrowStyle>}
	 * @memberof IQuickLinks
	 * @default 'Standard'
	 */
	eyebrowStyle?: Maybe<string>;
	/**
	 * Heading rich text content.
	 *
	 * @type {Maybe<QuickLinksHeading>}
	 * @memberof IQuickLinks
	 */
	heading?: Maybe<QuickLinksHeading>;
	/**
	 * QuickLinks to be displayed in the QuickLinks section.
	 *
	 * @type {QuickLinksQuickLinksCollection}
	 * @memberof IQuickLinks
	 */
	quickLinksCollection: QuickLinksQuickLinksCollection;
	/**
	 * Specifies the width of the template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof IQuickLinks
	 */
	templateSize: Maybe<string>;
	/**
	 * disables padding on either top or bottom
	 *
	 * @type {Array<'Top' | 'Bottom'>}
	 * @memberof IQuickLinks
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * displays QuickLinks as titles instead of buttons
	 *
	 * @type {boolean}
	 * @memberof IQuickLinks
	 */
	displayAsTitles?: Maybe<boolean>;
}

export default function QuickLinks({
	backgroundColor,
	body,
	buttonsCollection,
	heading,
	eyebrow,
	eyebrowStyle,
	quickLinksCollection,
	templateSize,
	disablePaddingOn = [],
	displayAsTitles = false,
}: Readonly<IQuickLinks>) {
	const theme = useTheme();
	const _templateSize = templateSize ?? 'Full';
	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');
	const bgColor = resolveContentfulBgColor((backgroundColor || 'Neutral') as ContentfulBackground, theme);
	const isSmall = useMediaQuery(theme.breakpoints.down('md'));
	const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

	return (
		<TemplateContainer
			dataTestId="section_quick_links"
			contentfulBackgroundColor={bgColor}
			templateWidth={_templateSize as TemplateWidth}
			containerSx={wrapperStyles(disablePaddingOn, isSmall, displayAsTitles as boolean)}
		>
			<Stack gap="12px">
				{eyebrow && (
					<Typography
						data-test-id="quick_links_eyebrow"
						textAlign="center"
						variant={eyebrowStyle === 'Promo' ? 'bodyLargeBold' : 'bodyMediumBook'}
						sx={{ ...getEyebrowStyles(!!isDarkBackground, eyebrowStyle) }}
					>
						{eyebrow}
					</Typography>
				)}

				<ContentTypeRichText
					disableGutters
					disablePaddingOn={['Top', 'Bottom']}
					contentMaxWidth="100%"
					backgroundColor="transparent"
					dataTestId="quick_links_heading"
					centerAlign
					content={heading as ContentTypeRichTextContent}
					docProps={{
						color: isDarkBackground ? 'text.light' : 'text.primary',
						variant: 'header1',
					}}
				/>

				{body && (
					<ContentTypeRichText
						disableGutters
						disablePaddingOn={['Top', 'Bottom']}
						contentMaxWidth="100%"
						dataTestId="quick_links_body"
						backgroundColor="transparent"
						centerAlign
						content={body as ContentTypeRichTextContent}
						docProps={{
							color: isDarkBackground ? 'text.light' : 'text.secondary',
							variant: 'bodyLargeBook',
						}}
					/>
				)}
			</Stack>

			<Box
				sx={getQuickLinksWrapperStyles(displayAsTitles, isSmall, isMedium)}
				data-test-id="quick_links_collection"
			>
				{quickLinksCollection?.items?.map((linkData) => {
					if (!linkData) {
						return null;
					}
					return displayAsTitles ? (
						<QuickLinkAsTitle
							key={linkData?.sys?.id}
							isDarkBackground={isDarkBackground}
							isSmallScreen={isSmall}
							isMediumScreen={isMedium}
							{...linkData}
						/>
					) : (
						<QuickLink key={linkData?.sys?.id} {...linkData} />
					);
				})}
			</Box>

			<Box
				width="100%"
				justifyContent="center"
				alignItems="center"
				display="flex"
				flexWrap="wrap"
				gap="12px"
				data-test-id="buttons_collection"
			>
				{buttonsCollection?.items?.map((button) =>
					button ? (
						<ContentfulButton key={button.sys.id} {...button} dataTestId="button_quick_links_cta" />
					) : null
				)}
			</Box>
		</TemplateContainer>
	);
}
