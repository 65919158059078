import { Theme } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

export const getGridContainerStyles = (theme: Theme) => ({
	[theme.breakpoints.up('lg')]: { padding: '3.75rem 5%' },
	[theme.breakpoints.down('lg')]: { padding: '3.75rem 2%' },
	[theme.breakpoints.down('md')]: {
		padding: '2.5rem 1.25rem',
	},
});

export const getGridItemStyles = (isSmall: boolean) => ({
	'&.MuiGrid-item': {
		paddingY: isSmall ? '1.25rem' : '2.5rem',
	},
});

export const getEyebrowStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'bodyMediumBook'),
	[theme.breakpoints.down('md')]: {
		...resolveTypographyVariantStyles(theme, 'bodySmallBook'),
	},
});

export const getHeadingStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'header1'),
});

export const paddingStyles = (isPaddingNeeded: boolean) => (isPaddingNeeded ? { padding: '0% 20%' } : {});

export const normalButtonsStyles = {
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	gap: '1rem',
	'&.MuiGrid-item': { paddingTop: '1.25rem' },
};
