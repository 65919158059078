import pino, { Logger } from 'pino';

export const logLevelData = {
	'*': 'silent',
	'design-system': 'info',
};

const logLevels = new Map<string, string>(Object.entries(logLevelData));

const transport = {
	transport: {
		target: 'pino/file',
	},
};

const useTransport = process.env.NODE_ENV === 'production' ? transport : null;

export function getLogLevel(logger: string): string {
	return logLevels.get(logger) || logLevels.get('*') || 'info';
}

export function getLogger(name: string): Logger {
	return pino({
		name,
		level: getLogLevel(name),
		browser: {
			disabled: process.env.NODE_ENV === 'production',
		},
		useTransport,
	}) as Logger;
}
