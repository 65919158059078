import { Theme } from '@mui/material';

export const wrapper = (centerAligned: boolean) => ({
	display: 'flex',
	textAlign: centerAligned ? 'center' : 'left',
	justifyContent: centerAligned ? 'center' : 'start',
	alignItems: centerAligned ? 'center' : 'start',
	width: '100%',
	padding: {
		xs: '2rem 0.625rem',
		sm: '2.625rem 1.5rem',
		md: '2.625rem 2.5rem',
	},
	maxWidth: {
		sm: '100%',
		md: '50%',
	},
});

export const textContainerStyles = (centerAligned: boolean) => ({
	display: 'flex',
	minWidth: { sm: '25.25rem' },
	maxWidth: '42.25rem',
	alignSelf: centerAligned ? 'center' : 'start',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: centerAligned ? 'center' : 'start',
	gap: '1rem',
	height: '100%',
});

export const contentListWrapperStyles = {
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	flexFlow: 'column',
};

export const ctaButtonWrapperStyles = (centerAligned: boolean) => ({
	display: 'flex',
	gap: '0.75rem',
	flexWrap: 'wrap',
	justifyContent: centerAligned ? 'center' : 'start',
	alignItems: centerAligned ? 'center' : 'start',
	paddingTop: '0.75rem',
	'& > a': {
		width: 'auto',
	},
});

export const TACWrapperStyles = { paddingTop: { xs: '1.25rem', md: '0' } };

export const serviceContainerStyles = (centerAligned: boolean) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: '2rem',
	...(centerAligned && { justifyContent: 'center' }),
	'@media screen and (max-width:427px)': {
		justifyContent: 'space-between',
		gap: '1rem',
		margin: 'auto',
	},
});

export const getMedicalServiceButtonStyles = (theme: Theme) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: { xs: '0.5rem', md: '1rem' },
	gap: '0.5rem',
	borderRadius: '1.5rem',
	alignItems: 'center',
	// consistent width needed for button alignment
	width: { xs: '6.5rem', md: '9rem' },
	'@media screen and (max-width:427px)': {
		width: '46%',
	},
	border: 'none',
	color: `${theme.palette.primary?.dark}`,
	'&:hover': {
		backgroundColor: `${theme.palette.backgrounds?.light}`,
		border: 'none',
	},
	'&.active': {
		border: '2px solid',
		backgroundColor: `${theme.palette.backgrounds?.light}`,
		position: 'relative',
	},
	'& svg.occupational-medicine': {
		color: `${theme.palette.text?.primary}`,
	},
});

export const getCheckmarkIconStyles = (theme: Theme) => ({
	position: 'absolute',
	fontSize: '0.75rem',
	marginRight: '0.5rem',
	marginTop: '0.5rem',
	color: `${theme.palette.primary?.dark}`,
	top: 0,
	right: 0,
});
