import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';

import Disclaimer from '@/components/Disclaimer';

import { IProductFeatureProps } from './ProductFeature.type';
import {
	desktopMediaSliderGridItemStyles,
	disclaimerWrapperStyles,
	mobileMediaSliderStyles,
	wrapper,
} from './ProductFeature.styles';
import Heading from './Heading';
import Description from './Description';
import DescriptionList from './DescriptionList';
import ButtonCarousel from './ButtonCarousel';

// TO DO: update swiper version to remove this dynamic import
const MediaSlider = dynamic(() => import('./MediaSlider'), { ssr: false });

export default function ProductFeature({
	heading,
	descriptionRichText,
	descriptionButton,
	descriptionButtonsCollection,
	descriptionListCollection,
	buttonsCollection,
	media,
	disclaimer,
}: IProductFeatureProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const disclaimerData = disclaimer?.disclaimer;

	const GroupedChildComponents = () => {
		return (
			<>
				<Description
					descriptionRichText={descriptionRichText}
					descriptionButton={descriptionButton}
					descriptionButtonsCollection={descriptionButtonsCollection}
				/>
				<DescriptionList descriptionListCollection={descriptionListCollection} />
				<ButtonCarousel buttonsCollection={buttonsCollection} />
				{disclaimerData && (
					<Disclaimer
						disclaimer={disclaimerData}
						dataTestId="product_feature_disclaimer"
						wrapperSx={disclaimerWrapperStyles}
						disableGutters
					/>
				)}
			</>
		);
	};

	if (isMobile) {
		return (
			<Container data-test-id="section_product_feature_mobile" sx={wrapper}>
				<Heading {...heading} />
				<Box sx={mobileMediaSliderStyles}>
					<MediaSlider {...media} />
				</Box>
				<GroupedChildComponents />
			</Container>
		);
	}

	return (
		<Container data-test-id="section_product_feature_desktop" sx={wrapper} maxWidth={false}>
			<Grid container direction="row" spacing={2}>
				<Grid item xs={8} sx={desktopMediaSliderGridItemStyles}>
					<MediaSlider {...media} />
				</Grid>
				<Grid item xs={4}>
					<Heading {...heading} />
					<GroupedChildComponents />
				</Grid>
			</Grid>
		</Container>
	);
}
