import { Maybe } from '@/types';

const TOP_LEFT = { justifyContent: 'flex-start', alignItems: 'flex-start' };
const TOP_CENTER = { justifyContent: 'center', alignItems: 'flex-start' };
const TOP_RIGHT = { justifyContent: 'flex-end', alignItems: 'flex-start' };
const LEFT = { justifyContent: 'flex-start', alignItems: 'center' };
const CENTER = { justifyContent: 'center', alignItems: 'center' };
const RIGHT = { justifyContent: 'flex-end', alignItems: 'center' };
const BOTTOM_LEFT = { justifyContent: 'flex-start', alignItems: 'flex-end' };
const BOTTOM_CENTER = { justifyContent: 'center', alignItems: 'flex-end' };
const BOTTOM_RIGHT = { justifyContent: 'flex-end', alignItems: 'flex-end' };

export function resolveContentPositioning(contentPosition?: Maybe<string>) {
	switch (contentPosition) {
		case 'Top Left':
			return TOP_LEFT;
		case 'Left':
			return LEFT;
		case 'Bottom Left':
			return BOTTOM_LEFT;
		case 'Top Right':
			return TOP_RIGHT;
		case 'Right':
			return RIGHT;
		case 'Bottom Right':
			return BOTTOM_RIGHT;
		case 'Top Center':
			return TOP_CENTER;
		case 'Center':
			return CENTER;
		case 'Bottom Center':
			return BOTTOM_CENTER;
		default:
			return RIGHT;
	}
}

export function resolveEyebrowTextColor({
	isSmallScreen,
	isDarkBackground,
	darkTextColor,
	isPromoEyebrow,
	mediaAspectRatioSmallScreen,
}: {
	isSmallScreen: boolean;
	isDarkBackground: boolean;
	darkTextColor?: Maybe<boolean>;
	isPromoEyebrow?: boolean;
	mediaAspectRatioSmallScreen?: Maybe<string>;
}) {
	if ((isSmallScreen && mediaAspectRatioSmallScreen === 'Portrait (9:16)') || isDarkBackground) {
		return 'text.light';
	}

	if (isPromoEyebrow && isSmallScreen) return 'success.dark';

	if (isSmallScreen) return 'text.secondary';

	return darkTextColor ? 'text.secondary' : 'text.light';
}

export function resolveTACColor(hasVideoBg: boolean, shouldUseDarkText: boolean) {
	if (hasVideoBg) return 'text.light';

	return shouldUseDarkText ? 'text.secondary' : 'text.light';
}
