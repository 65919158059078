import { Theme } from '@mui/material';

import { ContentAlignment, TAGAspectRatio, TAGContentAlignment } from '@/types';

export const containerStyles = (theme: Theme, isSmallScreen: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: isSmallScreen ? '2rem' : '2.5rem',
	[theme.breakpoints.up('lg')]: { padding: '3.75rem 5%' },
	[theme.breakpoints.down('lg')]: { padding: '3.75rem 2%' },
	[theme.breakpoints.down('md')]: { padding: '2.5rem 1.25rem' },
});

export const getBottomButtonsStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	gap: '1rem',
	justifyContent: isSmallScreen ? ContentAlignment['Center'] : ContentAlignment['Left'],
	flexWrap: 'wrap',
});

export const getIndividualVideoButtonStyles = (alignment: TAGContentAlignment) => {
	return {
		display: 'flex',
		gap: '1rem',
		justifyContent: ContentAlignment[alignment],
		flexWrap: 'wrap',
	};
};

export const videosWrapperStyles = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: ContentAlignment['Left'],
	'.swiper,swiper-container': {
		margin: '0 !important',
	},
};

export const individualVideoBoxStyles = (aspectRatio: TAGAspectRatio, isSmallScreen: boolean) => {
	let width;
	let aspectRatioStyle;

	switch (aspectRatio) {
		case '16:9':
			width = isSmallScreen ? 300 : 800;
			aspectRatioStyle = '16 / 9';
			break;
		case '1:1':
		case '9:16':
		default:
			width = isSmallScreen ? 300 : 400;
			aspectRatioStyle = aspectRatio === '1:1' ? '1 / 1' : '9 / 16';
			break;
	}

	return {
		width,
		aspectRatio: aspectRatioStyle,
		position: 'relative',
	};
};

export const videoContentStyles = (aspectRatio: TAGAspectRatio, isSmallScreen: boolean, useAbsoluteStyles: boolean) => {
	let padding;

	switch (aspectRatio) {
		case '16:9':
			padding = isSmallScreen ? '1.25rem 2rem 1.25rem 0' : '3.75rem 2.25rem 2.25rem 2.25rem';
			break;
		case '1:1':
			padding = isSmallScreen ? '1.25rem 2rem 1.25rem 0' : '1.25rem 2.5rem 0 0';
			break;
		case '9:16':
		default:
			padding = isSmallScreen ? '1.25rem 1rem' : '3.75rem 2.25rem 2.25rem 2.25rem';
			break;
	}

	return {
		display: 'flex',
		flexDirection: 'column',
		gap: isSmallScreen ? '1.5rem' : '1.75rem',
		padding,
		...(useAbsoluteStyles && {
			position: 'absolute',
			inset: 'auto 0 0',
			background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
		}),
	};
};

export const videoControlsStyles = {
	display: 'none',
};

export const swiperActionsWrapper = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
};

export const templateFooterStyles = (hasButtons: boolean, isSmallScreen: boolean) => {
	return {
		display: 'flex',
		justifyContent: hasButtons ? 'space-between' : 'flex-end',
		flexDirection: isSmallScreen ? 'column-reverse' : 'row',
		marginTop: isSmallScreen ? '-1rem' : '-1.5rem',
		...(isSmallScreen && {
			gap: '2rem',
		}),
	};
};

export const manualRecordIconWrapper = {
	display: 'flex',
	flexDirection: 'row',
	gap: '0.75rem',
};

export const manualRecordIconStyles = (index: number, activeStep: number, theme: Theme) => {
	return {
		fontSize: '0.5rem',
		cursor: 'pointer',
		color: index === activeStep ? theme.palette.secondary?.main : theme.palette.neutrals?.primary,
	};
};
