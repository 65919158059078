import { Fragment, useEffect, useState } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ContentfulBackground, Maybe, TMarqueeHeight } from '@/types';
import { Marquee as IMarquee } from '@/types/generated';
import { extendArrayToLength, resolveContentfulBgColor } from '@/utils';

import TemplateContainer from '../TemplateContainer/TemplateContainer';
import TAGSvgIcon, { IconName } from '../TAGSvgIcon';

import { getSlideTrackStyles, sliderStyles, slideStyles, sliderWrapStyles } from './Marquee.styles';
import { EXTENDED_TEXT_LIST_SIZE } from './Marquee.helpers';

export interface IMarqueeProps extends IMarquee {
	/**
	 * Background color for the section, and it will also override the background color of the child components.
	 * @type { Maybe<ContentfulBackground>}
	 * @memberof IMarquee
	 * @default 'White'
	 *
	 **/
	backgroundColor?: Maybe<ContentfulBackground>;
	/**
	 * Test scrolling list (10 items)
	 * @type {Maybe<Maybe<string>[]>}
	 * @required
	 * @memberof IMarquee
	 * @default []
	 **/
	textList?: Maybe<Maybe<string>[]>;
	/**
	 * Icon between text
	 * @type {Maybe<IconName>}
	 * @memberof IMarquee
	 */
	icon?: IconName;
	/**
	 * Turn on / off auto-scrolling
	 * @type {Maybe<boolean>}
	 * @memberof IMarquee
	 * @default true
	 **/
	withAutoScrolling?: Maybe<boolean>;
	/**
	 * Change height of the marquee box
	 * @type {Maybe<TMarqueeHeight>}
	 * @memberof IMarquee
	 * @default Small
	 **/
	height?: Maybe<TMarqueeHeight>;
}

export default function Marquee({
	textList,
	backgroundColor = 'White',
	icon,
	withAutoScrolling = true,
	height = 'Small',
}: IMarqueeProps) {
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	const theme = useTheme();
	const isDark = backgroundColor?.toLowerCase().includes('dark');
	const bgColor = resolveContentfulBgColor(backgroundColor as ContentfulBackground, theme);
	const updatedTextList = withAutoScrolling
		? extendArrayToLength(textList ?? [], EXTENDED_TEXT_LIST_SIZE)
		: textList ?? [];
	const textColor = isDark ? 'text.light' : 'primary.dark';

	return (
		<TemplateContainer maxWidth={false} contentfulBackgroundColor={bgColor}>
			<Box data-test-id="section_marquee" sx={sliderWrapStyles}>
				<Box sx={sliderStyles}>
					{isClient && (
						<Box sx={getSlideTrackStyles({ withAutoScrolling, height })} data-test-id="marquee_slide_track">
							{updatedTextList.map((textItem, index) =>
								textItem ? (
									<Fragment key={textItem}>
										<Typography
											color={textColor}
											variant="header4"
											sx={slideStyles}
											data-test-id="marquee_text"
										>
											{index !== 0 && (
												<Typography sx={{ paddingRight: '1rem' }} variant="header2">
													{icon ? (
														<TAGSvgIcon icon={icon} size={24} data-test-id="marquee_icon" />
													) : (
														'•'
													)}
												</Typography>
											)}
											{textItem}
										</Typography>
									</Fragment>
								) : null
							)}
						</Box>
					)}
				</Box>
			</Box>
		</TemplateContainer>
	);
}
