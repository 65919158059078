import { resolveTypographyVariantStyles } from '@aspendental/themes';
import { Theme } from '@mui/material';

import { ContentAlignment, TAGContentAlignment } from '@/types';

export const wrapperStyles = (alignment: TAGContentAlignment) => {
	return {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.75rem',
		justifyContent: ContentAlignment[alignment],
		alignItems: ContentAlignment[alignment],
		textAlign: ContentAlignment[alignment],
	};
};

export const getHeadingStyles = (theme: Theme, titleVariant: string) => ({
	...resolveTypographyVariantStyles(theme, titleVariant),
});

export const richTextDescStyles = (isDarkBackground: boolean) => ({
	'& .MuiLink-root': {
		color: isDarkBackground ? 'text.light' : 'text.interactive',
	},
});
