import { Theme } from '@mui/material';

import { DisableTemplatePaddingOn, TRoundedCornersSize } from '@/types';
import { Maybe } from '@/types';
import { resolveTemplatePadding } from '@/utils/resolveTemplatePadding';

export const getContainerStyles = (
	theme: Theme,
	disablePaddingOn: DisableTemplatePaddingOn,
	isSmallScreen: boolean
) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'stretch',
	margin: '0 auto',
	boxSizing: 'border-box',
	position: 'relative',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},
	...resolveTemplatePadding(disablePaddingOn, isSmallScreen, '0', '2.625rem'),
});

export const getMediaBorderRadius = (
	mediaWithBorderRadius: Maybe<boolean>,
	imgDisplaysFirstOnDesktop?: Maybe<boolean>,
	mediaRoundedCornerSize?: Maybe<TRoundedCornersSize>
): string => {
	const radiusValues = {
		Small: 1,
		Medium: 2.5,
		Large: 5,
	} as { [key: string]: number };
	const radiusValue = radiusValues[mediaRoundedCornerSize ?? 'Large'];
	const rads = `${radiusValue}rem`;
	const leftRightRadius = imgDisplaysFirstOnDesktop ? `0 ${rads} ${rads} 0` : `${rads} 0 0 ${rads}`;
	return mediaWithBorderRadius === false ? 'none' : leftRightRadius;
};

interface IMediaContainerStyles {
	theme: Theme;
	mediaWithBorderRadius: Maybe<boolean>;
	isSmallScreen: boolean;
	isVideo?: boolean;
	imgDisplaysFirstOnDesktop?: Maybe<boolean>;
	mediaRoundedCornerSize?: Maybe<TRoundedCornersSize>;
}

export const getMediaContainerStyles = ({
	theme,
	mediaWithBorderRadius = false,
	isSmallScreen = false,
	isVideo = false,
	imgDisplaysFirstOnDesktop = false,
	mediaRoundedCornerSize,
}: IMediaContainerStyles) => ({
	...(isVideo && { position: 'relative' }),
	display: 'block',
	minHeight: '400px',
	flex: '1',
	width: '100%',
	overflow: 'hidden',
	borderRadius: isSmallScreen
		? 'none'
		: getMediaBorderRadius(mediaWithBorderRadius, imgDisplaysFirstOnDesktop, mediaRoundedCornerSize),
	[theme.breakpoints.down('md')]: {
		minHeight: 'auto',
	},
});

export const getImageStyles = (theme: Theme) => ({
	borderRadius: '5rem 0 0 5rem',
	[theme.breakpoints.down('md')]: {
		borderRadius: '0',
	},
});

export const mobileImageStyles = {
	display: 'flex',
	borderRadius: '0',
};
