import { SxProps } from '@mui/material';

const verticalDividerStyles = {
	borderRightWidth: '0.125rem',
	height: '2.5rem',
	alignSelf: 'center',
};

const horizontalDividerStyles = {
	width: '2.5rem',
	borderBottomWidth: '0.125rem',
	justifySelf: 'center',
};

export const getDividerStyles = ({ vertical }: { vertical: boolean }): SxProps =>
	vertical ? horizontalDividerStyles : verticalDividerStyles;

export const getContainerStyles = ({
	itemCount,
	useDividers,
	vertical,
}: {
	itemCount: number;
	useDividers: boolean;
	vertical: boolean;
}): SxProps => {
	const lineTemplate = Array(itemCount || 1)
		.fill(vertical ? 'auto' : '1fr')
		.join(useDividers ? ' auto ' : ' ');
	return {
		display: 'grid',
		alignItems: 'start',
		gridTemplate: vertical ? `${lineTemplate} / auto` : `auto / ${lineTemplate}`,
		columnGap: useDividers ? '1.25rem' : '2.5rem',
		rowGap: useDividers ? '1.5rem' : '3rem',
	};
};
