import { Maybe } from '@/types';

export const getWrapperStyles = ({
	isSmallScreen,
	shouldDisplayMediaFirst,
}: {
	isSmallScreen: boolean;
	shouldDisplayMediaFirst: boolean;
}) => ({
	padding: isSmallScreen ? '1rem' : '3.75rem 6rem',
	minHeight: isSmallScreen ? 'auto' : '30rem',
	maxHeight: isSmallScreen ? 'auto' : '44rem',
	position: 'relative',
	display: 'flex',
	flexDirection: isSmallScreen
		? `column${shouldDisplayMediaFirst ? '' : '-reverse'}`
		: `row${shouldDisplayMediaFirst ? '' : '-reverse'}`,
	gap: isSmallScreen ? '2rem' : '4rem',
	borderRadius: '0.5rem',
	margin: '0 auto',
	// need inhering here because this template is used inside other templates (Carousel)
	width: 'inherit',
	maxWidth: { md: '80rem' },
});

export const getMediaWrapperStyles = ({
	isSmallScreen,
	imageLayout,
	mediaType,
}: {
	isSmallScreen: boolean;
	mediaType?: Maybe<string>;
	imageLayout?: Maybe<string>;
}) => ({
	position: 'relative',
	width: isSmallScreen ? '100%' : mediaType !== 'Image' || imageLayout === 'rectangle' ? '60%' : '40%',
	textAlign: 'right',
	flexGrow: 1,
});

export const getContentWrapperStyles = ({
	isSmallScreen,
	mediaType,
	imageLayout,
	centerAlign,
}: {
	isSmallScreen: boolean;
	mediaType?: Maybe<string>;
	centerAlign: boolean;
	imageLayout?: Maybe<string>;
}) => ({
	position: 'relative',
	width: isSmallScreen ? '100%' : mediaType !== 'Image' || imageLayout === 'rectangle' ? '40%' : '50%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: isSmallScreen || centerAlign ? 'center' : 'flex-start',
	textAlign: isSmallScreen ? 'center' : 'left',
	gap: '1rem',
	flexBasis: 'auto',
});

const commonMediaStyles = {
	maxWidth: '100%',
	maxHeight: '100%',
};

const circleImageBorderStyles = {
	...commonMediaStyles,
	borderRadius: '50%',
};

const squareImageBorderStyles = {
	...commonMediaStyles,
	borderRadius: '0.5rem',
};

const rectangleImageBorderStyles = {
	...commonMediaStyles,
	borderRadius: '0.5rem',
};

export function resolveImageLayoutStyles(imageLayout?: Maybe<string>) {
	switch (imageLayout) {
		case 'circle':
			return circleImageBorderStyles;
		case 'square':
			return squareImageBorderStyles;
		case 'rectangle':
			return rectangleImageBorderStyles;
		default:
			return circleImageBorderStyles;
	}
}

export const buttonsWrapperStyles = {
	display: 'flex',
	gap: '0.75rem',
	flexWrap: 'wrap',
	justifyContent: 'center',
	alignItems: 'center',
	width: { xs: '80%', md: 'auto' },
	'& > a': {
		width: { xs: '100%', md: 'auto' },
	},
};
