import { Theme } from '@mui/material';

export const wrapper = {
	display: 'block',
};

export const getPaperProps = (theme: Theme) => ({
	backgroundColor: `${theme.palette.backgrounds?.main || theme.palette.backgrounds?.dark}`,
	'&.MuiDrawer-paperAnchorBottom': {
		borderRadius: '1rem 1rem 0 0',
		minHeight: '50%',
		maxHeight: '80%',
	},
});

export const getContainerStyles = (theme: Theme) => ({
	display: 'flex',
	paddingLeft: '1rem',
	paddingRight: '1rem',
	justifyItems: 'end',
	alignItems: 'center',
	gap: '1rem',
	marginTop: '2rem',
	color: `${theme.palette.text?.light}`,
});

export const headlineStyles = {
	flexGrow: 1,
};

export const closeButtonStyles = {
	color: 'text.light',
};

export const contentStyles = { display: 'flex', padding: '1rem' };
