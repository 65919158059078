import { Maybe, AnalyticsContext } from '@/types/generated';

const canUseDom = () => {
	return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customEvent = (eventName: string, data: Maybe<AnalyticsContext>, action: Maybe<string> | undefined) => {
	if (!canUseDom()) {
		return;
	}

	const event = new CustomEvent(eventName, {
		detail: {
			action,
			...data,
		},
	});

	window.dispatchEvent(event);
};

export interface IAnalyticsContext {
	__typename: string | undefined;
	[key: string]: Maybe<string | boolean | number | undefined>;
}

/*
 * Used for stripping out empty fields from contentful model and stringifying to attach to elements as a
 * data attribute. May or may not stick around, depending on needs.
 * See TAGLink.tsx / NextLinkComposed.tsx
 */
export const resolveAnalyticsContext = (context: IAnalyticsContext) => {
	if (!context) {
		return;
	}
	const { __typename, ...dataContext } = context;

	Object.keys(context).forEach(
		(key) =>
			(dataContext[key] === undefined || dataContext[key] === null || dataContext[key] === '') &&
			delete dataContext[key]
	);

	return JSON.stringify(dataContext);
};

/*
 * For CTAs that don't flow through TAGLink and need data sent when interacted with i.e. MUI buttons
 * See HeaderSearch.tsx
 */
export const analyticsButtonEvent = (analyticsContext: Maybe<AnalyticsContext>) => {
	customEvent('tagEvent', analyticsContext, analyticsContext?.eventName);
};
