export const getWrapperStyles = (mobileWidth: boolean) => ({
	padding: { xs: '2.5rem 1.25rem', md: '3.75rem 2%', lg: '3.75rem 5%' },
	display: 'flex',
	flexDirection: 'column',
	gap: mobileWidth ? '1.25rem' : '2.5rem',
});

export const templateTitleStyles = (mobileWidth: boolean) => ({
	display: 'flex',
	justifyContent: mobileWidth ? 'flex-start' : 'center',
});

export const templateBodyStyles = (mobileWidth: boolean) => ({
	display: 'flex',
	flexDirection: mobileWidth ? 'column' : 'row',
	gap: mobileWidth ? '2.438rem' : 'none',
});

export const mediaBoxStyles = (mobileWidth: boolean) => ({
	position: 'relative',
	width: mobileWidth ? '100%' : 'calc(100% - 26.25rem)',
});

export const reviewsBlockBoxStyles = (mobileWidth: boolean) => ({
	display: 'flex',
	width: mobileWidth ? '100%' : '27.438rem',
	padding: mobileWidth ? 0 : '1.75rem',
	flexDirection: 'column',
	alignItems: 'center',
	gap: mobileWidth ? '2.438rem' : '2rem',
});

export const ratingBlockAndBarsBoxStyles = {
	display: 'flex',
	flexDirection: 'row',
	alignSelf: 'stretch',
	gap: '2.5rem',
};

export const ratingBlockBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '0.313rem',
	textWrap: 'nowrap',
};

export const avgRatingTypographyStyles = {
	fontSize: '3.375rem',
	lineHeight: '3.375rem',
};

export const reviewBarsBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
};

export const reviewBarItemStyles = {
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	gap: '0.438rem',
	justifyContent: 'center',
	alignItems: 'center',
};

export const reviewListBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '1.25rem',
	alignItems: 'flex-start',
};

export const individualReviewItemStyles = {
	display: 'flex',
	alignItems: 'center',
	gap: '1.313rem',
	alignSelf: 'stretch',
};

export const reviewerFirstNameFirstLetterBoxStyles = {
	borderRadius: '50%',
	backgroundColor: 'backgrounds.dark',
	height: '5rem',
	width: '5rem',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
};

export const reviewerFirstNameFirstLetterFontStyles = { fontSize: '2.375rem', lineHeight: '3.25rem' };

export const loadMoreBoxStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

export const reviewsDrawerStyles = {
	marginTop: '2.5rem',
	marginBottom: '0.938rem',
};

export const reviewsDialogStyles = {
	'.MuiPaper-root': {
		height: '42rem',
	},
};

export const pullerContainer = { display: 'flex', justifyContent: 'center' };

export const mobileReviewsContainerStyles = { marginTop: '1.375rem', overflow: 'auto' };

export const oneReviewMobileMargin = { marginRight: '2.813rem' };
export const oneReviewBoxStyles = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' };
export const oneReviewRatingBoxMargin = { marginBottom: '0.688rem' };
export const oneReviewReadMoreStyles = { cursor: 'pointer', textDecoration: 'underline' };
export const oneReviewSourceMargins = { margin: '0.625rem 0' };
export const oneReviewDividerStyles = { margin: '1.25rem 0 0.625rem' };

export const ratingBoxStyles = { display: 'flex', lineHeight: 0.5 };
export const ratingArrayBoxColor = { color: 'warning.main' };
export const noOfReviewsLinkStyles = { textDecoration: 'underline', color: 'text.light' };
export const ratingIconSize = { fontSize: '1rem' };

export const pointerStyles = { cursor: 'pointer' };
