import { useEffect } from 'react';

import { z } from 'zod';
import { format } from 'date-fns';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
	Form,
	TextInput,
	DatePickerInput,
	useHookForm,
	MaskedPhoneNumberInput,
	SelectInput,
} from '@aspendental/shared-forms-web';
import { type FieldValues } from 'react-hook-form';

import { PatientInformationSchema, PatientInformationState, usePatientInformationFormStore } from '@/store';
import createPatient from '@/services/createPatient';
import { STATE_CODES, GENDER_OPTIONS } from '@/constants';
import { UsStateCode } from '@/types/generated';
import { TGender } from '@/types';
import { dataLayerConfig } from '@/utils';
import { minBirthdayDate, maxBirthdayDate } from '@/store/modules/patient-information.form.store';

import { wrapper, zipCodeInputStyles } from './PatientInformation.styles';

interface PatientInformation {
	onSubmit: (data: { patientId: string }) => void | Promise<void>;
}

const stateOptions = STATE_CODES.map(({ code }) => ({ value: code, label: code }));

interface SchemaField<ZodType> {
	name: string;
	validation: ZodType;
}

// Avoid SchemaBuilder: Construct each SchemaField object separately
const firstNameField: SchemaField<z.ZodEffects<z.ZodString, string, string>> = {
	name: 'firstName',
	validation: PatientInformationSchema.firstName,
};
const lastNameField: SchemaField<z.ZodEffects<z.ZodString, string, string>> = {
	name: 'lastName',
	validation: PatientInformationSchema.lastName,
};
const dateOfBirthField: SchemaField<z.ZodDate> = {
	name: 'dateOfBirth',
	validation: PatientInformationSchema.dateOfBirth,
};
const genderField: SchemaField<z.ZodString> = { name: 'gender', validation: PatientInformationSchema.gender };
const emailField: SchemaField<z.ZodString> = { name: 'email', validation: PatientInformationSchema.email };
const phoneField: SchemaField<z.ZodString> = { name: 'phone', validation: PatientInformationSchema.phone };
const cityField: SchemaField<z.ZodEffects<z.ZodString, string, string>> = {
	name: 'city',
	validation: PatientInformationSchema.city,
};
const stateField: SchemaField<z.ZodString> = { name: 'state', validation: PatientInformationSchema.state };
const zipCodeField: SchemaField<z.ZodString> = { name: 'zipCode', validation: PatientInformationSchema.zipCode };

// Concatenate and recompose the "schema" z object
const schema = z.object({
	[firstNameField.name]: firstNameField.validation,
	[lastNameField.name]: lastNameField.validation,
	[dateOfBirthField.name]: dateOfBirthField.validation,
	[genderField.name]: genderField.validation,
	[emailField.name]: emailField.validation,
	[phoneField.name]: phoneField.validation,
	[cityField.name]: cityField.validation,
	[stateField.name]: stateField.validation,
	[zipCodeField.name]: zipCodeField.validation,
});

export default function PatientInformation({ onSubmit }: PatientInformation) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const {
		patientId,
		firstName,
		lastName,
		dateOfBirth,
		gender,
		email,
		phone,
		city,
		state,
		zipCode,
		isAllPatientDataValid,
		isFormSubmitting,
		isSuccessfulFormSubmission,
		setFirstName,
		setLastName,
		setDateOfBirth,
		setEmail,
		setPhone,
		setCity,
		setState,
		setZipCode,
		setIsAllPatientDataValid,
		setIsFormSubmitting,
		setIsSuccessfulFormSubmission,
		setGender,
		setPatientId,
	} = usePatientInformationFormStore((store) => store);

	const handleSubmit = async (fields: FieldValues) => {
		const { firstName, lastName, dateOfBirth, gender, email, phone, city, state, zipCode } = fields as {
			firstName: string;
			lastName: string;
			dateOfBirth: Date;
			gender: TGender;
			email: string;
			phone: string;
			city: string;
			state: UsStateCode;
			zipCode: string;
		};
		setFirstName(firstName);
		setLastName(lastName);
		setDateOfBirth(dateOfBirth);
		setGender(gender);
		setEmail(email);
		setPhone(phone);
		setCity(city);
		setState(state);
		setZipCode(zipCode);

		const patient = await createPatient({
			patientId,
			firstName,
			lastName,
			dob: format(dateOfBirth, 'yyyy-MM-dd'),
			emailAddress: email,
			cellPhone: phone,
			gender,
			city,
			state,
			zipCode,
		});
		if (patient && patient.patientId) {
			setPatientId(patient.patientId);
			setIsSuccessfulFormSubmission(true);
		}
		await onSubmit({ patientId });
	};

	const defaultValues: Pick<PatientInformationState, Exclude<keyof PatientInformationState, 'patientId'>> = {
		firstName,
		lastName,
		dateOfBirth,
		gender,
		email,
		phone,
		city,
		state,
		zipCode,
		isAllPatientDataValid,
		isFormSubmitting,
		isSuccessfulFormSubmission,
	};

	const form = useHookForm({
		schema,
		defaultValues,
		mode: 'onBlur',
	});

	const {
		formState: { isValid, isSubmitting },
	} = form;

	//enables Continue button if all data is valid
	useEffect(() => {
		setIsAllPatientDataValid(isValid);
	}, [isValid, setIsAllPatientDataValid]);

	useEffect(() => {
		setIsFormSubmitting(isSubmitting);
	}, [isSubmitting, setIsFormSubmitting]);

	// On Patient Info "page" load
	useEffect(() => {
		dataLayerConfig({
			event: 'saa_flow',
			step_name: 'patient_info',
			brand: 'WellNow',
		});
	}, []);

	return (
		<Box data-test-id="section_patient_information" sx={wrapper}>
			<Form
				form={form}
				onSubmit={handleSubmit}
				data-test-id="form_patient_information"
				/* WORKAROUND: form is validated immediately if submit button is not in 
				the same component as form and formId is provided before submit is enabled */
				formId={isAllPatientDataValid ? 'patient-information-form' : ''}
			>
				<Box data-test-id="headline_patient_information">
					<Typography variant="header2" color="text.primary" textAlign="left">
						Patient Info
					</Typography>
				</Box>
				<Box py={{ xs: 2.5, md: 5 }}>
					<Grid
						container
						direction="row"
						spacing={2}
						justifyContent="flex-start"
						data-test-id="form_grid_container_patient_information"
					>
						<Grid item xs={12} md={6}>
							<TextInput
								name="firstName"
								label="Legal First Name"
								dataTestId="text_input_first_name_patient_information"
								enforceAlphaNumeric={false}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextInput
								name="lastName"
								label="Legal Last Name"
								dataTestId="text_input_last_name_patient_information"
								enforceAlphaNumeric={false}
							/>
						</Grid>
						<Grid item xs={12}>
							<DatePickerInput
								name="dateOfBirth"
								label="Date of Birth"
								required
								minDate={minBirthdayDate}
								maxDate={maxBirthdayDate}
								desktopMode
								disableOpenPicker={isSmallScreen}
								// dataTestId={'text_input_dob_patient_information'}
							/>
						</Grid>
						<Grid item xs={12}>
							<SelectInput name="gender" label="Gender" options={GENDER_OPTIONS} />
						</Grid>
					</Grid>
				</Box>
				<Box data-test-id="headline_contact_information">
					<Typography variant="header2" color="text.primary" textAlign="left">
						Contact Info
					</Typography>
				</Box>
				<Box py={{ xs: 2.5, md: 5 }}>
					<Grid
						container
						direction="row"
						spacing={2}
						justifyContent="flex-start"
						data-test-id="form_grid_container_contact_information"
					>
						<Grid item xs={12}>
							<TextInput
								name="email"
								label="Email"
								enforceAlphaNumeric={false}
								dataTestId="text_input_email_contact_information"
							/>
						</Grid>
						<Grid item xs={12}>
							<MaskedPhoneNumberInput
								name="phone"
								label="Phone"
								data-test-id="text_input_phone_contact_information"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextInput
								name="city"
								label="City"
								dataTestId="text_input_city_contact_information"
								enforceAlphaNumeric={false}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<SelectInput name="state" label="State" options={stateOptions} />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextInput
								name="zipCode"
								label="ZIP code"
								maxLength={5}
								dataTestId="text_input_zip_code_contact_information"
								type="number"
								onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
									e.target.value = e.target.value.toString().slice(0, 5);
								}}
								sx={zipCodeInputStyles}
							/>
						</Grid>
					</Grid>
				</Box>
			</Form>
		</Box>
	);
}
