import { Theme } from '@mui/material';

export const listWrapperStyles = {
	padding: 0,
};

export const listItemWrapperStyles = (theme: Theme) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: '0.75rem',
	padding: '1.25rem 0.75rem',
	borderBottom: '0.063rem solid',
	borderColor: theme.palette.neutrals?.primary,
});
