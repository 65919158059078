export const getDocProps = { color: 'text.tertiary', variant: 'bodyMediumBook', textAlign: 'start' };

export const containerStyles = {
	width: '100vw',
	display: 'flex',
	justifyContent: 'center',
};

function getWrapperPadding(deviceSize: 'sm' | 'md' | 'lg'): string {
	switch (deviceSize) {
		case 'sm':
			return '2.5rem 1.25rem';
		case 'md':
			return '3.75rem 2%';
		case 'lg':
			return '3.75rem 5%';
		default:
			return '3.75rem 5%';
	}
}

export const getWrapperStyles = (deviceSize: 'sm' | 'md' | 'lg') => ({
	display: 'block',
	padding: getWrapperPadding(deviceSize),
});

export const getSelectSectionStyles = (isSmallDevice: boolean) => ({
	verticalAlign: 'middle',
	alignItems: 'center',
	width: '100%',
	display: 'flex',
	textAlign: isSmallDevice ? 'start' : 'end',
});

export const getSelectBoxStyles = (isSmallDevice: boolean) => ({
	display: 'flex',
	flexDirection: isSmallDevice ? 'column' : 'row',
	width: isSmallDevice ? '100%' : '50%',
	alignContent: 'space-around',
});

export const getInputLabelStyles = { color: 'text.interactive' };

export const getSelectStyles = {
	borderRadius: '2rem',
	color: 'text.interactive',
	'.MuiOutlinedInput-notchedOutline': {
		borderColor: 'text.interactive',
	},
};

export const getSelectTextStyles = {
	width: '100%',
};
