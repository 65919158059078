import { accordionClasses } from '@mui/material';

import { Maybe, TAccordionExpandIconPosition, TRoundedCornersSize } from '@/types';

export const wrapper = {
	display: 'block',
	marginBottom: '1.3rem',
};

const getBorderRadius = (roundedCornersSize: Maybe<TRoundedCornersSize> = 'Small'): string => {
	switch (roundedCornersSize) {
		case 'Small':
			return '0.25rem';
		case 'Medium':
			return '0.5rem';
		case 'Large':
			return '0.75rem';
		default:
			return '0.25rem';
	}
};

export const getAccordionStyles = (params: {
	roundedCornersSize?: Maybe<TRoundedCornersSize>;
	withInnerGap?: boolean;
	isSmallScreen?: boolean;
}) => ({
	border: params.isSmallScreen && params.withInnerGap ? 'none' : '1px solid',
	borderColor: 'neutrals.primary',
	...(params.withInnerGap && {
		padding: params.isSmallScreen ? '0.75rem 0' : '0.75rem 1.5rem',
	}),

	'&:not(:last-child)': {
		borderBottom: 'none',
	},
	// The pseudo class ":first-child" is potentially unsafe when doing server-side rendering. Try changing it to ":first-of-type".
	'&:not(:first-child)': {
		borderTop: 'none',
	},
	[`&.${accordionClasses.expanded}`]: {
		margin: 0,
		'&:not(:first-child)': {
			border: '1px solid',
			borderColor: 'neutrals.primary',
		},
	},
	'&:first-of-type': {
		borderRadius: `${getBorderRadius(params.roundedCornersSize)} ${getBorderRadius(params.roundedCornersSize)} 0 0`,
	},
	'&:last-of-type': {
		borderRadius: `0 0 ${getBorderRadius(params.roundedCornersSize)} ${getBorderRadius(params.roundedCornersSize)}`,
	},
	...(params.withInnerGap && {
		'&:before': {
			margin: params.isSmallScreen ? '0' : '0 1.5rem',
		},
	}),
});

export const getPageThemedAccordionStyles = (params: { pageThemeBgColor: string }) => ({
	border: '0',
	borderRadius: '2rem',
	backgroundColor: `${params.pageThemeBgColor}`,
	borderColor: 'transparent',
	'&:before': {
		backgroundColor: 'transparent',
	},
	'&:not(:last-child)': {
		borderBottom: 'none',
	},
	'&:first-of-type': {
		borderRadius: '2rem',
	},
	'&:last-of-type': {
		borderRadius: '2rem',
	},
	'&:not(:first-of-type)': {
		borderTop: 'none',
		margin: '1rem 0',
	},
	[`&.${accordionClasses.expanded}`]: {
		margin: 0,
		'&:not(:first-of-type)': {
			border: '0',
			borderColor: 'transparent',
			margin: '1rem 0',
		},
	},
});

export const getAccordionSummaryStyles = (params?: {
	isSmallScreen?: boolean;
	expandIconPosition?: TAccordionExpandIconPosition;
}) => ({
	display: 'flex',
	flexDirection: params?.isSmallScreen ? 'row' : 'row-reverse',
	[`& .MuiAccordionSummary-content`]: {
		alignItems: 'center',
	},
	[`& .MuiAccordionSummary-expandIconWrapper`]: {
		color: 'text.interactive',
	},
	...(params?.expandIconPosition === 'Right' && {
		[`&.MuiAccordionSummary-root`]: {
			flexDirection: 'row',
		},
	}),
});

export const getPageThemedAccordionSummaryStyles = (params?: {
	isSmallScreen?: boolean;
	expandIconPosition?: TAccordionExpandIconPosition;
}) => ({
	padding: '0 1.25rem',
	flexDirection: 'row-reverse',
	[`& .MuiAccordionSummary-content`]: {
		paddingLeft: '1rem',
		margin: params?.isSmallScreen ? '1rem 0' : '1.5rem 0',
		alignItems: 'center',
	},
	[`& .MuiAccordionSummary-content.${accordionClasses.expanded}`]: {
		margin: params?.isSmallScreen ? '1rem 0' : '1.5rem 0',
	},
	[`& .MuiAccordionSummary-expandIconWrapper`]: { color: 'text.interactive' },
	...(params?.expandIconPosition === 'Right' && {
		[`&.MuiAccordionSummary-root`]: { flexDirection: 'row' },
	}),
});

export const getAccordionDetailsStyles = (isSmallScreen: boolean) => {
	return {
		paddingX: '1.25rem',
		paddingBottom: '2rem',
		'ul > li': {
			width: '100%',
			maxWidth: isSmallScreen ? '90%' : 'none',
			'p > a': {
				display: 'inline-block',
				wordBreak: 'break-word',
			},
		},
	};
};

export const summaryIconStyles = {
	marginRight: '1rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
