export function parseStrToNum(input: string | null | undefined): number | null {
	if (typeof input === 'object' || typeof input === 'undefined') return null;

	const parsedNumber = parseFloat(input);
	if (!isNaN(parsedNumber)) {
		return parsedNumber;
	} else {
		return null;
	}
}
