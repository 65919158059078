import { Theme } from '@mui/material';

export const carouselWrapperStyles = (isSmall: boolean, theme: Theme) => ({
	display: 'flex',
	alignItems: 'center',
	padding: isSmall ? '1.75rem 0' : '2.5rem 0 2.5rem 0.5rem',
	borderBottom: '0.063rem solid',
	borderColor: theme.palette.neutrals?.primary,
});

export const chevronButtonStyles = {
	minWidth: '1.875rem',
	padding: 0,
};

export const scrollableBoxStyles = (isSmall: boolean) => {
	return {
		display: 'flex',
		flexDirection: 'row',
		gap: isSmall ? '1.25rem' : '0.75rem',
		overflowX: 'scroll',
		// chrome and safari
		'::-webkit-scrollbar': { display: 'none' },
		// firefox
		scrollbarWidth: 'none',
	};
};
