import { useState } from 'react';

import { Box, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';

import { AgendaModal } from '@/types/generated';
import { Maybe } from '@/types';

import { IAgendaStepperProps } from '../AgendaStepper';

import { getLinkTitleStyles, stepLabelStyles, stepperContainerStyle, stepperStyles } from './TAGStepper.styles';

const StepperModal = dynamic(() => import('../StepperModal/StepperModal'), { ssr: false });

const TAGStepper = ({
	hideStepperDot,
	stepsCollection,
}: Pick<IAgendaStepperProps, 'stepsCollection' | 'hideStepperDot'>) => {
	const theme = useTheme();
	const [open, setOpen] = useState<boolean>(false);
	const [clickedOnStepIndex, setClickedOnStepIndex] = useState<number>(0);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setClickedOnStepIndex(0);
		setOpen(false);
	};

	if (!stepsCollection?.items?.length) {
		return null;
	}
	return (
		<>
			<StepperModal
				stepsCollection={stepsCollection}
				clickedOnStepIndex={clickedOnStepIndex}
				open={open}
				handleClose={handleClose}
				handleOpen={handleOpen}
			/>
			<Box sx={stepperContainerStyle(isSmallScreen)}>
				<Stepper
					alternativeLabel={isSmallScreen ? false : true}
					activeStep={stepsCollection.items.length}
					orientation={isSmallScreen ? 'vertical' : 'horizontal'}
					sx={stepperStyles(theme, isSmallScreen, hideStepperDot)}
					data-test-id={'agenda_stepper_stepper'}
				>
					{stepsCollection.items.map((step: Maybe<AgendaModal>, index: number) => {
						if (step?.headlineText) {
							return (
								<Step key={step.headlineText} data-test-id={`agenda_stepper_step_index_${index}`}>
									<StepLabel
										sx={stepLabelStyles(theme, isSmallScreen, hideStepperDot)}
										StepIconProps={{ completed: false }}
										data-test-id={`agenda_stepper_step_label_${index}`}
									>
										<Typography
											data-test-id={`agenda_stepper_step_link_${step.headlineText}`}
											onClick={() => {
												setClickedOnStepIndex(index);
												handleOpen();
											}}
											sx={getLinkTitleStyles(theme, isSmallScreen)}
										>
											{step.headlineText}
										</Typography>
									</StepLabel>
								</Step>
							);
						}
						return null;
					})}
				</Stepper>
			</Box>
		</>
	);
};

export default TAGStepper;
