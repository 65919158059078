import { Box, Typography, useTheme } from '@mui/material';

import { ContentSection as TContentSection } from '@/types/generated';
import { HeadingTag, Maybe, TAGContentAlignment } from '@/types';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { textToJumpLink } from '@/utils';

import RichText, { RichTextContent } from '../RichText';

import { getHeadingStyles, wrapperStyles } from './ContentSection.styles';

interface ContentSectionProps extends TContentSection {
	/**
	 * Eyebrow heading that will set the H-tag to appear in the HTML without effecting the style of the element. normal will render a <p> tag.
	 * @type {HeadingTag}
	 * @memberof TContentSection
	 * @default "h4"
	 */
	eyebrowHtag?: Maybe<HeadingTag>;
	/**
	 * Title heading that will set the H-tag to appear in the HTML without effecting the style of the element. normal will render a paragraph tag.
	 * @type {HeadingTag}
	 * @memberof TContentSection
	 * @default "h1"
	 */
	titleHtag?: Maybe<HeadingTag>;
	/**
	 * Optional alignment field, center or left or right
	 * @type {TAGContentAlignment}
	 * @memberof TContentSection
	 * @default "center"
	 */
	alignment?: TAGContentAlignment;
	/**
	 * Decides what style variant to use for text like bodySmallBook or bodyMediumBook
	 * @type {boolean}
	 * @memberof TContentSection
	 * @default false
	 */
	isSmallScreen: boolean;
	/**
	 * Decides what color to use for text like light or secondary
	 * @type {boolean}
	 * @memberof TContentSection
	 * @default false
	 */
	isDarkBackground: boolean;
	/**
	 * Based on this value title will have the H-tag and it's styles. But underlying H-tag can be overwritten by titleHtag but not styles
	 * @type {string}
	 * @memberof ContentSection
	 * @default "header1"
	 */
	titleVariant: string;
	/**
	 * Optional data-test-id attribute.
	 * @type {string}
	 * @memberof TContentSection
	 * @default "content_section"
	 */
	dataTestId?: string;
}

export default function ContentSection({
	eyebrow,
	eyebrowHtag = 'h4',
	title,
	titleHtag = 'h1',
	description,
	alignment = 'Center',
	isDarkBackground,
	isSmallScreen,
	titleVariant,
	dataTestId = 'content_section',
}: ContentSectionProps) {
	const theme = useTheme();

	return (
		<Box sx={wrapperStyles(alignment)} data-test-id={dataTestId}>
			{eyebrow && (
				<Typography
					variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
					color={isDarkBackground ? 'text.light' : 'text.secondary'}
					data-test-id={`${dataTestId}_content_section_eyebrow`}
					component={eyebrowHtag === 'normal' ? 'p' : eyebrowHtag === null ? 'h4' : eyebrowHtag}
				>
					{eyebrow}
				</Typography>
			)}

			{title && (
				<Typography
					variant={getHeadingVariant(titleHtag as HeadingTag)}
					id={textToJumpLink(title)}
					component={titleHtag === 'normal' ? 'p' : titleHtag === null ? 'h1' : titleHtag}
					sx={{
						...getHeadingStyles(theme, titleVariant),
						color: isDarkBackground ? 'text.light' : 'text.primary',
					}}
					whiteSpace="pre-line"
					data-test-id={`${dataTestId}_content_section_title`}
				>
					{title}
				</Typography>
			)}

			{description && (
				<RichText
					docProps={{
						color: isDarkBackground ? 'text.light' : 'text.secondary',
						variant: isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook',
					}}
					links={description?.links}
					content={description?.json as RichTextContent}
				/>
			)}
		</Box>
	);
}
