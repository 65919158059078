export const wrapper = {
	maxWidth: '120rem',
	marginBottom: {
		xs: '2.5rem',
		md: '3.75rem',
	},
	padding: {
		xs: '0 1.25rem',
		md: '3.75rem 2%',
		lg: '3.75rem 5%',
	},
	display: 'flex',
	flexDirection: {
		xs: 'column',
	},
};

// parent component container adds a padding to whole
// but this particular one is to override with margin so that it will be stretched to full
export const mobileMediaSliderStyles = {
	margin: '0 -1.5rem',
};

export const desktopMediaSliderGridItemStyles = {
	'&.MuiGrid-item.MuiGrid-item': {
		height: '100%',
		position: 'sticky',
		top: 0,
	},
};

export const disclaimerWrapperStyles = {
	'&.MuiContainer-root': {
		paddingX: '0',
	},
	paddingY: '1.25rem',
};
