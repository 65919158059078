import { Typography, Grid, Skeleton } from '@mui/material';

export interface ILocationHeading {
	loading?: boolean;
	type: string;
	name: string;
	address: string;
}

export default function LocationHeading({
	loading = false,
	type = 'WellNow Urgent Care',
	name = '',
	address = '',
}: ILocationHeading) {
	return (
		<Grid item container direction={'column'} alignContent={'stretch'} data-test-id={'section_location_heading'}>
			{loading ? (
				<Grid item>
					<Skeleton height={40} />
					<Skeleton height={28} />
				</Grid>
			) : (
				<>
					<Grid container item data-test-id="grid_container_location_title">
						<Grid item data-test-id="grid_item_location_heading_type_name">
							<Typography variant="header2" color="text.primary">
								{type} | {name}
							</Typography>
						</Grid>
					</Grid>
					<Grid item data-test-id="grid_item_location_address">
						<Typography
							variant="bodyMediumBook"
							color="text.secondary"
							data-test-id="location_heading_facility_address"
						>
							{address}
						</Typography>
					</Grid>
				</>
			)}
		</Grid>
	);
}
