import { resolveTypographyVariantStyles } from '@aspendental/themes';
import { Theme } from '@mui/material';

export const headingWrapperStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: {
		xs: '0.75rem',
		md: '1rem',
	},
	padding: {
		xs: '0 0 1.25rem 0',
		md: '1.25rem 0 0 0',
	},
};

export const getEyebrowStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'header4'),
	color: theme.palette.success.dark,
});

export const getHeadingStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'header1'),
});
