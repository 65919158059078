import Head from 'next/head';

import { Maybe, Schema as TSchema } from '@/types/generated';

export interface ISchema extends TSchema {
	/**
	 * Schema from Contentful
	 *
	 * @type {string}
	 * @memberof ISchema
	 */
	schema: Maybe<Record<string, unknown>>;
}

export default function Schema({ schema }: ISchema) {
	// TODO: Sanitize schema to prevent XSS in future.
	// Refer-to:
	// 1. https://pragmaticwebsecurity.com/articles/spasecurity/json-stringify-xss.html
	// 2. https://github.com/vercel/next.js/issues/46377
	return (
		<Head>
			<script defer={false} type="application/ld+json">
				{JSON.stringify(schema)}
			</script>
		</Head>
	);
}
