export const wrapperStyles = {
	padding: '3.75rem',
};

export const getContentWrapperStyles = (isOpen: boolean) => ({
	height: isOpen ? '100%' : '9.375rem',
	overflow: 'hidden',
	transition: 'all 0.3s ease',
	width: '100%',
	maxWidth: '100%',
	'& span, p': {
		color: 'text.secondary',
		lineHeight: '1.25rem',
		letterSpacing: 0.1,
		fontSize: '0.938rem',
	},
	'& h1, h2, h3, h4': {
		color: 'text.secondary',
	},
});

export const getExpandArrowHolderStyles = (isOpen: boolean, divHeight: number) => ({
	p: 0,
	width: '100%',
	textAlign: 'center',
	transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
	display: divHeight <= 150 ? 'none' : 'inline-block',
	marginTop: isOpen ? '0.625rem' : 0,
});
