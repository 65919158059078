import { useEffect } from 'react';

import { Box } from '@mui/material';

import TAGButton from '../TAGButton';

export interface LinkForwarder {
	/**
	 * Required field to identify the webpage to be forwarded to
	 *
	 * @type {string}
	 * @memberof IFrame
	 */
	url: string;
}

export default function LinkForwarder({ url = 'https://www.teamtag.com' }: LinkForwarder) {
	useEffect(() => {
		const delay = 1; //
		const redirectTimeout = setTimeout(() => {
			window.location.replace(`${url}`);
		}, delay);

		return () => clearTimeout(redirectTimeout);
	}, [url]);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around">
			{/* fallback in the case of blocked redirect */}
			<p>Please click the link below if you are not redirected within 5 seconds.</p>
			<TAGButton size="XS" variant="primaryDefault" href={url}>
				Click Here
			</TAGButton>
		</Box>
	);
}
