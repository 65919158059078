import { useState } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { TReview } from '@/types';

import TAGLink from '../TAGLink';

import {
	individualReviewItemStyles,
	reviewListBoxStyles,
	reviewerFirstNameFirstLetterBoxStyles,
	reviewerFirstNameFirstLetterFontStyles,
} from './BirdEyeReviews.styles';
import ReviewsDialogOrDrawer from './ReviewsDialogOrDrawer';
import { IReviewListProps, IReviewListStateObject } from './BirdEyeReviews.helper';

function ReviewList({ reviews, mobileWidth, facilityName }: IReviewListProps) {
	const [state, setState] = useState<IReviewListStateObject>({
		showDialog: false,
		displayedReview: null,
	});
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const filteredReviews = reviews.filter((review: TReview) => review.comments).slice(0, 3) || [];
	const charactersThreshold = smallScreen ? 55 : mobileWidth ? 130 : 90;

	const handleOpenReview = (
		e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement, MouseEvent>,
		index: number
	) => {
		e.preventDefault();
		setState({
			...state,
			showDialog: true,
			displayedReview: filteredReviews[index],
		});
	};
	const handleCloseReview = () => setState({ ...state, showDialog: false });

	return (
		<>
			<Box sx={reviewListBoxStyles} data-test-id="ReviewsBlock_ReviewsList">
				{filteredReviews.map((review: TReview, index: number) => {
					const shortReview = review.comments && review.comments.length <= charactersThreshold;
					return (
						<Box
							sx={individualReviewItemStyles}
							key={`review-item-${review.reviewer.firstName}}`}
							data-test-id={`ReviewsBlock_Review_By_${review.reviewer.firstName}`}
						>
							<Box sx={reviewerFirstNameFirstLetterBoxStyles}>
								<Typography
									variant="lowestLevelBook"
									color="text.light"
									sx={reviewerFirstNameFirstLetterFontStyles}
								>
									{review.reviewer.firstName?.charAt(0)?.toUpperCase()}
								</Typography>
							</Box>
							<Typography
								variant="bodyLargeBook"
								color="text.secondary"
								onClick={(e) => {
									shortReview && handleOpenReview(e, index);
								}}
								sx={{ cursor: shortReview ? 'pointer' : 'default' }}
							>
								&quot;{review.comments && review.comments.slice(0, charactersThreshold)}
								<TAGLink
									onClick={(e) => handleOpenReview(e, index)}
									dataTestId={`ReviewsBlock_Review_Ellipsis_${review.reviewer.firstName}`}
								>
									{!shortReview && '...'}&quot;
								</TAGLink>
							</Typography>
						</Box>
					);
				})}
			</Box>
			{state.showDialog && state.displayedReview && (
				<ReviewsDialogOrDrawer
					mobileWidth={mobileWidth}
					handleCloseDialog={handleCloseReview}
					reviews={[state.displayedReview]}
					facilityName={facilityName}
					showFullReview={Boolean(state.displayedReview)}
				/>
			)}
		</>
	);
}

export default ReviewList;
