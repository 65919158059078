import { useState, useRef, useEffect } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon';

import { IButtonCarousel } from '../ProductFeature.type';
import ContentfulButton from '../../ContentfulButton';
import TAGButton from '../../TAGButton';

import { carouselWrapperStyles, chevronButtonStyles, scrollableBoxStyles } from './ButtonCarousel.styles';

export default function ButtonCarousel({ buttonsCollection }: IButtonCarousel) {
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('md'));
	const scrollableBoxRef = useRef<HTMLInputElement>();

	const [scrollStartFrom, setScrollStartFrom] = useState(0);
	const [isScrollEnd, setIsScrollEnd] = useState(false);
	const [exceedsVisibleWidth, setExceedsVisibleWidth] = useState(false);

	const handleChevronClick = (shift: number) => {
		if (!scrollableBoxRef.current) {
			return;
		}
		scrollableBoxRef.current.scrollLeft += shift;
		setScrollStartFrom(scrollStartFrom + shift);
	};
	const handleScroll = () => {
		if (!scrollableBoxRef.current) {
			return;
		}
		setScrollStartFrom(scrollableBoxRef.current.scrollLeft);
		if (
			Math.floor(scrollableBoxRef.current.scrollWidth - scrollableBoxRef.current.scrollLeft) <=
			scrollableBoxRef.current.offsetWidth
		) {
			setIsScrollEnd(true);
		} else {
			setIsScrollEnd(false);
		}
	};
	useEffect(() => {
		if (scrollableBoxRef.current) {
			setExceedsVisibleWidth(scrollableBoxRef.current.scrollWidth > scrollableBoxRef.current.offsetWidth);
		}
	}, []);

	const fadeCondition = () => {
		if (scrollStartFrom === 0) {
			return 'linear-gradient(90deg, black 90%, transparent 100%)';
		} else if (!isScrollEnd) {
			return 'linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%)';
		} else {
			return 'linear-gradient(270deg, black 90%, transparent 100%)';
		}
	};

	if (!buttonsCollection?.items?.length) {
		return null;
	}

	return (
		<Box sx={{ ...carouselWrapperStyles(isSmall, theme) }} data-test-id="product_feature_button_carousel">
			{!isSmall && scrollStartFrom > 10 && (
				<TAGButton
					onClick={() => handleChevronClick(-200)}
					dataTestId="chevron_left_button"
					sx={chevronButtonStyles}
				>
					<TAGSvgIcon icon={'ChevronLeftFunc'} size={34} />
				</TAGButton>
			)}
			<Box
				ref={scrollableBoxRef}
				onScroll={handleScroll}
				data-test-id="button_carousel_scrollable_box"
				sx={{
					...scrollableBoxStyles(isSmall),
					maskImage: exceedsVisibleWidth ? fadeCondition : 'none',
				}}
			>
				{buttonsCollection.items.map((button) =>
					button ? (
						<ContentfulButton
							key={button.sys.id}
							{...button}
							dataTestId="button_carousel"
							isSmallScreen={isSmall}
						/>
					) : null
				)}
			</Box>
			{exceedsVisibleWidth && !isSmall && !isScrollEnd && (
				<TAGButton
					onClick={() => handleChevronClick(+200)}
					dataTestId="chevron_right_button"
					sx={chevronButtonStyles}
				>
					<TAGSvgIcon icon={'ChevronRightFunc'} size={34} />
				</TAGButton>
			)}
		</Box>
	);
}
