import { Box, useMediaQuery, useTheme } from '@mui/material';

import { resolveContentfulBgColor, resolveContentfulSpacerSize } from '@/utils';
import { ContentfulBackground, ContentfulSpacerSize } from '@/types';

export interface ISpacer {
	/**
	 * Used to identify a component on a page for the purpose of jumping to it using the anchor link.
	 *
	 * @type {string}
	 * @memberof ISpacer
	 */
	anchorId?: string;
	/**
	 * Defines the height of the spacer based on the size of the device
	 *
	 * @type {Maybe<ContentfulSpacerSize>}
	 * @memberof ISpacer
	 */
	size: ContentfulSpacerSize;
	/**
	 * Defines the background color of the spacer.
	 *
	 * @type {Maybe<ContentfulBackground>}
	 * @memberof ISpacer
	 */
	color: string;
	/**
	 * Optional data-test-id attribute for testing purposes.
	 *
	 * @type {string}
	 * @memberof ISpacer
	 */
	dataTestId?: string;
}

export default function Spacer({ dataTestId = 'group_spacer', color = '', anchorId, size = 'Standard' }: ISpacer) {
	const theme = useTheme();
	const bgColor = resolveContentfulBgColor(color as ContentfulBackground, theme);
	const isSmallDevice: boolean = useMediaQuery(theme.breakpoints.down('md'));
	const spacerHeight = resolveContentfulSpacerSize(size, isSmallDevice);

	return (
		<Box
			data-test-id={dataTestId}
			width="100%"
			height={spacerHeight}
			sx={{ backgroundColor: bgColor }}
			id={anchorId}
		/>
	);
}
