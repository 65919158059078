import { useEffect, useState, useCallback, MouseEvent, useMemo } from 'react';

import { Grid, useTheme, Typography, useMediaQuery, Box } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import Image from 'next/image';

import {
	useFormSectionStore,
	useLocationDetailsStore,
	usePatientInformationFormStore,
	useReasonForVisitStore,
	useRevSpringStore,
	useSlotsStore,
} from '@/store';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import { useFacilityContext } from '@/context/FacilityContextProvider.ctx';
import getLocationDetailsById from '@/services/getLocationDetailsById';
import getSession from '@/services/getSession';
import getAppointmentTypes from '@/services/getAppointmentTypes';
import TAGSvgIcon from '@/components/TAGSvgIcon';
import { Asset } from '@/types/generated';
import setSlotAutobook from '@/services/setSlotAutobook';
import setSlotBook from '@/services/setSlotBook';
import { parseStrToNum, formatUsingTZ, supportedTZs } from '@/utils';

import TAGDialog from '../TAGDialog';
import TAGButton from '../TAGButton';

import Confirmation from './Confirmation';
import {
	getGridContainerLeftSideStyles,
	getGridContainerRightSideStyles,
	getGridContainerSectionStyles,
	getButtonsContainerStyles,
	getGridContainerRightSideComponentsStyles,
} from './OnPageScheduling.styles';
import VisitTypeSelector from './VisitTypeSelector';
import { visitOptions, VisitButton } from './VisitTypeButtons/VisitTypeButtons';
import AppointmentReview from './AppointmentReview';
import ErrorDialog from './ErrorDialog';
import PatientInformation from './PatientInformation';
import NoTimeslotsMessage from './NoTimeslotsMessage';
import SchedulingError from './SchedulingError';
import TimeSlotSelector from './TimeSlotSelector';

export interface IOnPageScheduling {
	enableUnavailableAppointmentModal?: boolean;
	showSchedulingError?: boolean;
	showNoAvailableSlotsMessage?: boolean;
	locationCardMedia: Asset;
}

const dayOneDate = new Date();
const mutableDatetime = new Date();
const dayTwoDate = new Date(mutableDatetime.setUTCDate(mutableDatetime.getUTCDate() + 1));

export default function OnPageScheduling({
	enableUnavailableAppointmentModal = false,
	showSchedulingError = false,
	showNoAvailableSlotsMessage = false,
	locationCardMedia,
}: IOnPageScheduling) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const facilityContext = useFacilityContext();
	const { config, environment } = useAppContext();

	const activeSection = useFormSectionStore((store) => store.activeSection);
	const completeActiveSection = useFormSectionStore((store) => store.completeActiveSection);
	const moveToSection = useFormSectionStore((store) => store.moveToSection);
	const reasonForVisit = useReasonForVisitStore((store) => store.reasonForVisit);
	const selectedTimeSlot = useSlotsStore((store) => store.selectedTimeSlot);
	const isGetInLineSelected = useSlotsStore((store) => store.isGetInLineSelected);
	const {
		isAllPatientDataValid,
		patientId,
		isFormSubmitting,
		isSuccessfulFormSubmission,
		setIsSuccessfulFormSubmission,
	} = usePatientInformationFormStore((store) => store);
	const revspringLocationValue = useRevSpringStore((store) => store.revspringLocationValue);
	const revspringAccountId = useRevSpringStore((store) => store.revspringAccountId);
	const { displayName, address, city, state, timezone } = useLocationDetailsStore(
		useShallow((store) => ({
			displayName: store.displayName,
			address: store.address,
			city: store.city,
			state: store.state,
			timezone: store.timezone,
		}))
	);

	const [showBackButton, setShowBackButton] = useState(false);
	const [showButtonsContainer, setShowButtonsContainer] = useState(false);
	const [isContinueDisabled, setIsContinueDisabled] = useState(false);
	const [showError, setShowError] = useState(enableUnavailableAppointmentModal);
	const [showSchedulingErrorMessage, setShowSchedulingErrorMessage] = useState(showSchedulingError);
	const [isAppointmentReviewLoading, setAppointmentReviewLoading] = useState(false);
	const [fetchCalled, setFetchCalled] = useState(false);
	const [vendorApptId, setVendorApptId] = useState('');
	const [visitButtons, setVisitButtons] = useState<Array<VisitButton>>([]);
	const [visitButtonsLoading, setVisitButtonsLoading] = useState(true);
	const [confirmedApptTime, setConfirmedApptTime] = useState('');

	const showLeftSide = useMemo(() => !isSmallScreen, [isSmallScreen]);

	const handleModalClose = () => {
		setShowError(false);
	};

	const handleScrollToTop = useCallback(() => {
		const rightSideContainerNode = document.getElementById('right-side-container');
		if (!rightSideContainerNode) return;

		rightSideContainerNode?.scroll({
			top: 0,
			//@ts-ignore
			behavior: 'instant',
		});
	}, []);

	const moveToNextStep = useCallback(async () => {
		if (activeSection === 'AppointmentReview') {
			setAppointmentReviewLoading(true);
			const {
				status,
				appointmentDTO: { vendorAppointmentId = '', appointmentDateTime },
			} = !isGetInLineSelected
				? await setSlotBook({
						revspringAccountId,
						revspringLocationValue,
						apptType: reasonForVisit,
						duration: parseStrToNum(selectedTimeSlot?.duration) || 0,
						startDate: selectedTimeSlot?.offerDateTimeUTC || '',
						patientId,
				  })
				: await setSlotAutobook({
						revspringAccountId,
						revspringLocationValue,
						apptType: reasonForVisit,
						patientId,
				  });
			setAppointmentReviewLoading(false);
			setConfirmedApptTime(appointmentDateTime);
			setVendorApptId(vendorAppointmentId);
			if (status === 'error') {
				setShowSchedulingErrorMessage(true);
				return;
			}
		}
		completeActiveSection(activeSection);
		moveToSection(1);
	}, [
		activeSection,
		completeActiveSection,
		moveToSection,
		isGetInLineSelected,
		revspringLocationValue,
		revspringAccountId,
		reasonForVisit,
		selectedTimeSlot,
		patientId,
	]);

	const moveToPrevStep = useCallback(() => moveToSection(-1), [moveToSection]);

	const onContinue = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		void moveToNextStep();
		handleScrollToTop();
	};

	const onPrevious = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		void moveToPrevStep();
		handleScrollToTop();

		if (activeSection === 'AppointmentReview') setIsSuccessfulFormSubmission(false);
	};

	useEffect(() => {
		activeSection === 'AppointmentReview' && isSuccessfulFormSubmission && handleScrollToTop();
	}, [activeSection, handleScrollToTop, isSuccessfulFormSubmission]);

	useEffect(() => {
		if (activeSection === 'VisitTypeSelector') {
			setShowBackButton(false);
			setShowButtonsContainer(false);
			return;
		}

		setShowBackButton(true);
		setShowButtonsContainer(true);
	}, [activeSection]);

	useEffect(() => {
		if (activeSection !== 'TimeSlotSelector') return;

		setIsContinueDisabled(!(selectedTimeSlot || isGetInLineSelected));
	}, [activeSection, selectedTimeSlot, isGetInLineSelected]);

	useEffect(() => {
		if (activeSection !== 'PatientInformation') return;

		isAllPatientDataValid ? setIsContinueDisabled(false) : setIsContinueDisabled(true);
		setIsContinueDisabled(isFormSubmitting);
	}, [activeSection, isAllPatientDataValid, isFormSubmitting]);

	useEffect(() => {
		async function fetchData() {
			if (visitButtons.length) {
				setVisitButtonsLoading(false);
				return;
			}

			if (!facilityContext.officeInfo?.location) {
				setVisitButtonsLoading(false);
				return;
			}

			setVisitButtonsLoading(true);
			const featureList = config?.featureFlags?.featureList || [];
			const pilotLocations = new Set(config?.featureFlags?.onPageSchedulingLocations || []);
			const { facilityCode = '' } = facilityContext;
			const {
				revspringLocationValue,
				revspringAccountId,
				schedulingSystem,
				scheduleAppointmentURL = '',
				...locationDetails
			} = await getLocationDetailsById({ facilityCode, featureList, environment });
			if (schedulingSystem !== 'Revspring') {
				setVisitButtons([
					{
						visitType: 'ExternalSource',
						label: 'Reserve Your Spot',
						icon: 'CalendarAlt',
						href: scheduleAppointmentURL,
					},
				]);
				setVisitButtonsLoading(false);
				return;
			}

			await getSession(revspringAccountId);
			const visitTypesData = await getAppointmentTypes({ revspringLocationValue });
			const visitTypes: Array<VisitButton> = visitOptions.filter(({ visitType }) => visitTypesData[visitType]);

			useRevSpringStore.setState({
				revspringLocationValue,
				revspringAccountId,
			});

			useLocationDetailsStore.setState({ ...locationDetails });

			setVisitButtons(
				visitTypes.length &&
					(pilotLocations.has(revspringLocationValue) ||
						!featureList.includes('onPageSchedulingPilotEnabled'))
					? visitTypes
					: [
							{
								visitType: 'ExternalSource',
								label: 'Reserve Your Spot',
								icon: 'CalendarAlt',
								href: scheduleAppointmentURL,
							},
					  ]
			);
			setVisitButtonsLoading(false);
		}

		if (facilityContext.officeInfo && !fetchCalled) {
			setFetchCalled(true);
			void fetchData()
				.catch((error) => {
					console.error(error);
				})
				.then(() => {
					setVisitButtonsLoading(false);
					return false;
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facilityContext]);

	if (activeSection === 'Confirmation') {
		return (
			<Confirmation
				accountId={revspringAccountId}
				vendorAppointmentId={vendorApptId}
				displayName={displayName}
				address={address}
				city={city}
				state={state}
				apptDate={confirmedApptTime}
				apptTime={formatUsingTZ(confirmedApptTime, supportedTZs[timezone], 'h:mmaaa')}
				reason={reasonForVisit}
				timezone={timezone}
			/>
		);
	}

	return (
		<Grid
			data-test-id="section_on_page_scheduling"
			container
			direction={isSmallScreen ? 'column' : 'row'}
			sx={getGridContainerSectionStyles(isSmallScreen)}
		>
			{showLeftSide && (
				<Grid
					item
					container
					md={6}
					alignItems={'center'}
					justifyContent={'center'}
					rowGap={2}
					data-test-id={'on_page_scheduling_left_side_container'}
					sx={getGridContainerLeftSideStyles(isSmallScreen)}
				>
					<Image
						alt="Page Scheduling"
						src={`https://images.ctfassets.net/m8zwsu9tyucg/1uTH1JHi3pJoyB0bmnfUim/6a56a8d751dcdee2415477da589e9580/on_page_scheduling_background.png?h=${
							isSmallScreen ? '320' : '1417'
						}`}
						layout="fill"
						loading="lazy"
						style={{
							objectFit: 'cover',
							position: 'absolute',
							pointerEvents: 'none',
							left: 0,
							top: 0,
							width: '100%',
							height: '100%',
						}}
					/>
					<Grid item sx={{ position: 'relative' }} data-test-id={'on_page_scheduling_title'}>
						<Typography variant="overline" color="text.light" textAlign={'center'}>
							Welcome to WellNow
						</Typography>
					</Grid>
					<Grid item sx={{ position: 'relative' }} data-test-id={'on_page_scheduling_headline'} xs={12}>
						<Typography variant="heroLarge" color="text.light" textAlign={'center'}>
							Save your spot
						</Typography>
					</Grid>
					<Grid item sx={{ position: 'relative' }} data-test-id={'on_page_scheduling_body'} xs={12}>
						<Typography variant="bodyLargeBook" color="text.light" textAlign={'center'} component={'p'}>
							Thank you for choosing WellNow Urgent Care for your healthcare needs. If you think you may
							be experiencing an emergency, please dial 911 immediately.
						</Typography>
					</Grid>
				</Grid>
			)}
			<Grid
				id="right-side-container"
				item
				container
				direction={'column'}
				md={6}
				data-test-id={'on_page_scheduling_right_side_container'}
				sx={getGridContainerRightSideStyles(isSmallScreen, activeSection)}
			>
				<Grid
					item
					container
					direction={'column'}
					data-test-id={'on_page_scheduling_right_side_step_flow_components_container'}
					gap={2}
					sx={getGridContainerRightSideComponentsStyles(isSmallScreen)}
				>
					{showSchedulingErrorMessage ? (
						<SchedulingError />
					) : (
						<>
							{activeSection === 'VisitTypeSelector' && (
								<VisitTypeSelector
									postSelectionAction={moveToNextStep}
									accountId={revspringAccountId}
									buttons={visitButtons}
									loading={visitButtonsLoading}
								/>
							)}
							{activeSection === 'TimeSlotSelector' && (
								<TimeSlotSelector
									locationCardMedia={locationCardMedia}
									dayOneLabel="Today"
									dayOneDateTime={dayOneDate.toISOString()}
									dayTwoLabel="Tomorrow"
									dayTwoDateTime={dayTwoDate.toISOString()}
								/>
							)}
							{activeSection === 'PatientInformation' && <PatientInformation onSubmit={moveToNextStep} />}
							{activeSection === 'AppointmentReview' && (
								<AppointmentReview isLoading={isAppointmentReviewLoading} />
							)}
						</>
					)}
					{showNoAvailableSlotsMessage && <NoTimeslotsMessage locationCardMedia={locationCardMedia} />}
				</Grid>

				<TAGDialog
					open={showError}
					showCloseIcon
					handleClose={handleModalClose}
					maxWidth={false}
					fullWidth
					sx={{ padding: '0px' }}
					data-test-id="on_page_scheduling_expired_dialog"
					renderTextContent={() => <ErrorDialog />}
					renderActions={() => (
						<Box mx={4}>
							<TAGButton
								variant="tertiaryDefault"
								size="S"
								href="/"
								dataTestId="on_page_scheduling_expired_modal_action_button"
							>
								Select another time
							</TAGButton>
						</Box>
					)}
				/>
			</Grid>
			{showButtonsContainer && !showSchedulingErrorMessage && (
				<Grid
					item
					container
					md={6}
					data-test-id={'on_page_scheduling_buttons_container'}
					sx={getButtonsContainerStyles(theme, showBackButton)}
				>
					{showBackButton && (
						<Grid item>
							<TAGButton
								variant="secondaryDefault"
								size="M"
								dataTestId={`on_page_scheduling_back_button`}
								startIcon={<TAGSvgIcon icon={'ChevronLeftFunc'} size={20} />}
								onClick={onPrevious}
							>
								Back
							</TAGButton>
						</Grid>
					)}
					<Grid item>
						{activeSection === 'PatientInformation' ? (
							<TAGButton
								variant="primaryDefault"
								size="M"
								dataTestId="on_page_scheduling_continue_button"
								endIcon={<TAGSvgIcon icon="ChevronRightFunc" size={20} />}
								form="patient-information-form"
								type="submit"
								disabled={isContinueDisabled}
							>
								Continue
							</TAGButton>
						) : (
							<TAGButton
								variant="primaryDefault"
								size="M"
								dataTestId="on_page_scheduling_continue_button"
								endIcon={<TAGSvgIcon icon="ChevronRightFunc" size={20} />}
								onClick={onContinue}
								disabled={activeSection === 'TimeSlotSelector' ? isContinueDisabled : false}
							>
								{activeSection === 'AppointmentReview' ? 'Confirm' : 'Continue'}
							</TAGButton>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}
