import { Grid, Typography, Skeleton } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { useLocationDetailsStore } from '@/store';

import TAGButton from '../../TAGButton';
import TAGSvgIcon from '../../TAGSvgIcon';
import LocationHeading from '../LocationHeading';
import VisitTypeButtons from '../VisitTypeButtons';
import { VisitButton } from '../VisitTypeButtons/VisitTypeButtons';
import VirtualCareNow from '../VirtualCareNow';

export interface IVisitTypeSelector {
	accountId?: string;
	postSelectionAction?: () => void | Promise<void>;
	buttons?: Array<VisitButton>;
	loading?: boolean;
}

export default function VisitTypeSelector({
	postSelectionAction,
	accountId,
	buttons,
	loading = false,
}: IVisitTypeSelector) {
	const { displayName, address, city, state, zip } = useLocationDetailsStore(
		useShallow((store) => ({
			displayName: store.displayName,
			address: store.address,
			city: store.city,
			state: store.state,
			zip: store.zip,
		}))
	);

	return (
		<>
			<Grid
				item
				container
				direction={'column'}
				rowGap={2}
				alignContent={'stretch'}
				data-test-id={'on_page_scheduling_right_side_visit_type_step'}
			>
				<Grid item data-test-id="grid_item_location_heading">
					<LocationHeading
						loading={loading}
						type={displayName}
						name={city}
						address={`${address}, ${city}, ${state} ${zip}`}
					/>
				</Grid>
				<Grid item data-test-id="on_page_scheduling_visit_types_change_location">
					{loading ? (
						<Skeleton height={24} />
					) : (
						<Typography variant="bodySmallBook" color="text.primary" textAlign={'left'}>
							<TAGButton
								variant="tertiaryDefault"
								startIcon={<TAGSvgIcon icon="MapMarker" size={18} />}
								href="/location-search"
							>
								Change Location
							</TAGButton>
						</Typography>
					)}
				</Grid>
				<Grid item data-test-id={'on_page_scheduling_visit_type_headline'}>
					<Typography variant="header2" color="text.primary" textAlign={'left'}>
						{loading ? <Skeleton height={40} /> : <span>Schedule Visit</span>}
					</Typography>
				</Grid>
				<Grid item data-test-id={'on_page_scheduling_visit_type_buttons'}>
					<VisitTypeButtons
						postClickAction={postSelectionAction}
						accountId={accountId}
						buttons={buttons}
						loading={loading}
					/>
				</Grid>
				<Grid item data-test-id={'on_page_scheduling_virtual_care_now'}>
					<VirtualCareNow />
				</Grid>
			</Grid>
		</>
	);
}
