export const reviewBoxesContainerStyle = {
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	padding: '0.75rem 0.75rem 1.25rem 1.25rem',
	border: '1px solid',
	borderRadius: '20px',
	borderColor: 'neutrals.primary',
};

export const reviewBoxTitleContainerStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
};

export const headingContainerStyles = {
	display: 'flex',
	flexDirection: 'column',
	rowGap: '0.75rem',
};
