import { create } from 'zustand';

import { ORDERED_SCHEDULING_SECTIONS } from '@/constants';
import { TSchedulingSectionName } from '@/types';

interface FormSection {
	name: string;
	completed: boolean;
}

const createFormSections = (orderedSectionNames: readonly string[]) => {
	return orderedSectionNames.map((name) => ({ name, completed: false }));
};

const formSections: FormSection[] = createFormSections(ORDERED_SCHEDULING_SECTIONS);

export type State = {
	formSections: FormSection[];
	activeSection: TSchedulingSectionName;
	allSectionsComplete: boolean;
};

type Actions = {
	moveToSection: (step: 1 | -1) => void;
	completeActiveSection: (activeSection: TSchedulingSectionName) => void;
	setActiveSection: (activeSection: TSchedulingSectionName) => void;
};

const useFormSectionStore = create<State & Actions>((set, get) => ({
	formSections,
	activeSection: ORDERED_SCHEDULING_SECTIONS[0],
	allSectionsComplete: false,
	moveToSection: (step) => {
		const activeSectionIndex = ORDERED_SCHEDULING_SECTIONS.findIndex(
			(sectionName) => sectionName === get().activeSection
		);
		if (activeSectionIndex === -1) {
			return new Error('Cannot find an active section');
		}
		const newActiveSection = ORDERED_SCHEDULING_SECTIONS[activeSectionIndex + step];
		set({ activeSection: newActiveSection });
	},
	completeActiveSection: (activeSection: TSchedulingSectionName) => {
		set({
			formSections: get().formSections.map((section) => {
				if (section.name === activeSection) {
					return { ...section, completed: true };
				} else {
					return section;
				}
			}),
		});

		if (get().formSections.every((section) => section.completed)) {
			set({ allSectionsComplete: true });
		}
	},
	// Call this action to navigate to already completed sections
	setActiveSection: (activeSection: TSchedulingSectionName) => set({ activeSection }),
}));

export default useFormSectionStore;
