import { Typography, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import { Button as TButton } from '@/types/generated';

interface IQuickLinkType {
	buttonData: TButton;
	isLink?: boolean;
	isDarkBackground?: boolean;
	isSmallScreen?: boolean;
}
export default function QuickLinkType({
	buttonData,
	isLink,
	isDarkBackground,
	isSmallScreen,
}: Readonly<IQuickLinkType>) {
	const theme = useTheme();
	return (
		<>
			{buttonData.icon && (
				<TAGSvgIcon
					icon={buttonData.icon as IconName}
					size={24}
					dataTestId={`icon_quick_link_title_icon_${buttonData.sys.id}`}
				/>
			)}
			<Typography
				data-test-id={`quick_link_title_button_label_${buttonData.sys.id}`}
				variant={isSmallScreen ? 'bodyXLBook' : 'header4'}
				color={isDarkBackground ? 'text.light' : 'text.interactive'}
				component={'span'}
				sx={{
					display: 'flex',
					alignItems: 'center',
					...(isLink && {
						gap: '0.5rem',
					}),
					...(isLink &&
						isSmallScreen && {
							width: '100%',
							justifyContent: 'space-between',
						}),
				}}
			>
				{buttonData.label}
				{isLink && (
					<ArrowForwardIcon
						data-test-id={`icon_quick_link_title_arrow_${buttonData.sys.id}`}
						fontSize="small"
						sx={{
							color: isDarkBackground ? theme.palette.text.light : theme.palette.text.interactive,
						}}
					/>
				)}
			</Typography>
		</>
	);
}
