import { create } from 'zustand';

export interface ReasonForVisitState {
	reasonForVisit: string;
}

export interface ReasonForVisitActions {
	setReasonForVisit: (reasonForVisit: string) => void;
}
// Combining state and actions for the store
type ReasonForVisitStore = ReasonForVisitState & ReasonForVisitActions;

const useReasonForVisitStore = create<ReasonForVisitStore>((set) => ({
	reasonForVisit: '',
	setReasonForVisit: (reasonForVisit: string) => set({ reasonForVisit }),
}));

export default useReasonForVisitStore;
