import { Grid, Typography } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { useLocationDetailsStore } from '@/store';

import LocationHeading from '../LocationHeading';

export default function SchedulingError() {
	const { displayName, city, address, state, zip } = useLocationDetailsStore(
		useShallow((store) => ({
			displayName: store.displayName,
			city: store.city,
			address: store.address,
			state: store.state,
			zip: store.zip,
		}))
	);

	return (
		<Grid
			item
			container
			direction={'column'}
			rowGap={6}
			alignContent={'stretch'}
			data-test-id={'section_scheduling_error'}
		>
			<Grid item data-test-id="grid_item_location_heading_scheduling_error">
				<LocationHeading type={displayName} name={city} address={`${address}, ${city}, ${state} ${zip}`} />
			</Grid>
			<Grid item container rowGap={1} data-test-id="grid_item_scheduling_error">
				<Grid item data-test-id="grid_item_scheduling_error_headline">
					<Typography variant="header2" color="error.dark">
						We are currently experiencing issues scheduling your spot online.
					</Typography>
				</Grid>
				<Grid item data-test-id="grid_item_scheduling_error_body">
					<Typography variant="bodyLargeBook" color="text.secondary">
						Please call your local office for assistance or simply walk-in and register with the front desk.
						We apologize for the inconvenience.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}
