import { isToday, format } from 'date-fns';

import { formatUsingTZ, joinValidStrs, supportedTZs } from '@/utils';
import { LocationDetailsState, PatientInformationState } from '@/store';
import { OfferDTO } from '@/rest/__generated/revspringApi';
import { TSchedulingSectionName } from '@/types';

export const getReviewInfoBoxesArr = (
	patientInformation: PatientInformationState,
	reasonForVisit: string,
	selectedTimeSlot: OfferDTO,
	isGetInLineSelected: boolean,
	locationDetails: LocationDetailsState,
	setActiveSection: (index: TSchedulingSectionName) => void
) => {
	const { firstName, lastName, dateOfBirth, email, phone } = patientInformation;
	const { offerDateTimeUTC } = selectedTimeSlot;
	const { displayName, address, city, state, zip, timezone } = locationDetails;

	const timeslotDate = new Date(offerDateTimeUTC || '2010-01-01T00:00:00Z');

	return [
		{
			title: 'Patient Information',
			body: {
				fullName: `${firstName} ${lastName}`,
				dob: format(dateOfBirth ?? new Date('1970-01-01T00:00:00Z'), 'MM/dd/yyyy'),
				email: email,
				phone: phone,
			},
			editSectionLink: () => setActiveSection('PatientInformation'),
		},
		{
			title: 'Location',
			body: {
				address1: displayName,
				address2: joinValidStrs(' ', [address, joinValidStrs(', ', [city, state])]),
				address3: zip,
			},
			editSectionLink: () => setActiveSection('VisitTypeSelector'),
		},
		{
			title: 'Date & Time',
			body: !isGetInLineSelected
				? {
						caption: 'Local time of location is displayed',
						date: `${isToday(timeslotDate) ? 'Today, ' : ''}${formatUsingTZ(
							offerDateTimeUTC,
							supportedTZs[timezone],
							'MMM dd, yyyy'
						)}`,
						time: formatUsingTZ(offerDateTimeUTC, supportedTZs[timezone], 'h:mmaaa'),
				  }
				: {
						gettingInLineMsg: 'Next available time',
				  },
			editSectionLink: () => setActiveSection('TimeSlotSelector'),
		},
		{
			title: 'Reason For Visit',
			body: {
				visitReason: reasonForVisit,
			},
			editSectionLink: () => setActiveSection('VisitTypeSelector'),
		},
	];
};
