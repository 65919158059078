import { Maybe } from '@/types';

import { resolveContentAlignment } from '../../PatientReviews.helpers';

export const getContainerStyles = (contentAlign?: Maybe<string>) => ({
	alignItems: resolveContentAlignment(contentAlign, false),
	gap: '0.75rem',
});

export const getSubTextContainerStyles = (contentAlign?: Maybe<string>) => ({
	alignItems: resolveContentAlignment(contentAlign, false),
	gap: '0.25rem',
});

export const avatarStyles = { borderRadius: '50%', overflow: 'hidden', width: '4.5rem', height: '4.5rem' };
