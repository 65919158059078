export const wrapper = {
	display: 'block',
	paddingY: '3.75rem',
	width: 'auto',
	maxWidth: '100%',
	'& .swiper-button-prev, & .swiper-button-next': {
		color: 'text.primary',
		display: { xs: 'none', md: 'flex' },
	},
	'& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
		backgroundColor: 'text.primary',
	},
};

export const getSectionWrapperStyles = (isDarkBackground: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	gap: '0.75rem',
	...(isDarkBackground
		? {
				'& .MuiTypography-root': {
					color: 'text.light',
				},
		  }
		: {}),
});
