import { TAGBackground } from '@aspendental/themes';
import dynamic from 'next/dynamic';
import { useMediaQuery, useTheme } from '@mui/material';

import { TDoctor, TAGContentAlignment, TContentMaxWidthSize } from '@/types';
import { useOfficeLocationContext } from '@/context';
import { Maybe } from '@/types/generated';
import { reverseLookupContentfulBgColor } from '@/utils/reverseLookupContentfulBgColor';
import TemplateContainer from '@/components/TemplateContainer';
import { useTemplateContainerFullWidthStyles } from '@/utils';

import { getSwiperContainerStyles } from './MeetOurStaff.styles';
import MeetOurStaffSection from './Staff/Section/Section';

const MeetOurStaffCarousel = dynamic(() => import('./MeetOurStaffCarousel'), { ssr: false });

export interface IStaffImage {
	url?: string;
	file?: object;
}

export interface IStaffInfo {
	biography?: Maybe<string>;
	collegeCity?: Maybe<string>;
	collegeName?: Maybe<string>;
	collegeState?: Maybe<string>;
	firstName?: Maybe<string>;
	graduationDate?: Maybe<string>;
	hireDate?: Maybe<string>;
	image?: Maybe<IStaffImage>;
	jobTitle?: Maybe<string>;
	languages?: Array<string>;
	lastName?: Maybe<string>;
	location?: Maybe<string>;
	name?: Maybe<string>;
	specialties?: Maybe<string>;
}

export interface IStaffCollection {
	items?: Array<IStaffInfo>;
}

export interface IMeetOurStaff {
	sectionBackground: TAGBackground;
	description?: Maybe<string>;
	title: Maybe<string>;
	dataTestId?: string;
	alignment?: TAGContentAlignment;
	contentMaxWidth?: TContentMaxWidthSize;
	showDisabledSwiperArrows?: boolean;
	withDesktopPaginationNavigation?: boolean;
	withMobileArrowsNavigation?: boolean;
}

export default function MeetOurStaff({
	sectionBackground = 'white',
	description,
	title,
	alignment = 'Center',
	contentMaxWidth = 'Medium',
	showDisabledSwiperArrows = false,
	withDesktopPaginationNavigation = false,
	withMobileArrowsNavigation = false,
	dataTestId = 'meet_our_staff_container',
}: IMeetOurStaff) {
	const colorScheme = reverseLookupContentfulBgColor(`backgrounds.${sectionBackground}`)?.toLowerCase();
	const { officeInfo } = useOfficeLocationContext();
	const staffCollection: TDoctor[] = officeInfo?.doctors || [];
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const wrapperStyles = useTemplateContainerFullWidthStyles(null, isSmallScreen);

	if (!staffCollection.length) return null;

	return (
		<TemplateContainer
			containerSx={{
				...wrapperStyles,
				...getSwiperContainerStyles({
					showDisabledSwiperArrows,
					contentMaxWidth,
					isSmallScreen,
					withMobileArrowsNavigation,
				}),
			}}
			disableGutters={true}
			dataTestId={dataTestId}
			{...(contentMaxWidth === 'Large' && { maxWidth: false })}
		>
			{Array.isArray(staffCollection) && staffCollection.length > 0 && (
				<MeetOurStaffSection
					title={title}
					sectionBackground={colorScheme}
					description={description}
					alignment={alignment}
					contentMaxWidth={contentMaxWidth}
				>
					<MeetOurStaffCarousel
						staffCollection={staffCollection}
						withDesktopPaginationNavigation={withDesktopPaginationNavigation}
						withMobileArrowsNavigation={withMobileArrowsNavigation}
					/>
				</MeetOurStaffSection>
			)}
		</TemplateContainer>
	);
}
