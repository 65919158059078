import { TAGBackground } from '@aspendental/themes';

import { TAGContentAlignment, TContentMaxWidthSize } from '@/types';
import { getFlexAlignment, getTextAlignment } from '@/utils/getAlignment';

export const getGridContainerWrapStyles = (sectionBackground: TAGBackground | string) => ({
	backgroundColor: `backgrounds.${sectionBackground}`,
	p: { xs: '2.75rem 1rem', md: '3rem 1.25rem' },
});

export const getGridContainerStyles = (params?: {
	alignment?: TAGContentAlignment;
	contentMaxWidth?: TContentMaxWidthSize;
	isSmallScreen?: boolean;
}) => ({
	alignItems: getFlexAlignment(params?.alignment),
	marginBottom: { xs: '2.5rem', md: '3rem' },
	...(params?.contentMaxWidth === 'Large' && !params?.isSmallScreen && { padding: '0 4rem' }),
});

export const getTypographyStyles = (params?: { alignment?: TAGContentAlignment }) => ({
	display: 'block',
	textAlign: getTextAlignment(params?.alignment),
});
