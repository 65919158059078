import z from 'zod';
import { create } from 'zustand';
import { TextInputSchema, MaskedPhoneNumberInputSchema } from '@aspendental/shared-forms-web';

import { isValidName } from '@/utils';
import { UsStateCode } from '@/types/generated';
import { Maybe, TGender } from '@/types';

export const minBirthdayDate = new Date();
minBirthdayDate.setFullYear(minBirthdayDate.getFullYear() - 100);
export const maxBirthdayDate = new Date();

export type State = {
	patientId: string;
	firstName: string;
	lastName: string;
	dateOfBirth: Maybe<Date>;
	gender: TGender | '';
	email: string;
	phone: string;
	city: string;
	state: UsStateCode | '';
	zipCode: string;
	isAllPatientDataValid: boolean;
	isFormSubmitting: boolean;
	isSuccessfulFormSubmission: boolean;
};

export type Actions = {
	setPatientId: (patientId: string) => void;
	setFirstName: (input: string) => void;
	setLastName: (input: string) => void;
	setDateOfBirth: (input: Date) => void;
	setGender: (input: TGender) => void;
	setEmail: (input: string) => void;
	setPhone: (input: string) => void;
	setCity: (input: string) => void;
	setState: (input: UsStateCode) => void;
	setZipCode: (input: string) => void;
	setIsAllPatientDataValid: (bool: boolean) => void;
	setIsFormSubmitting: (bool: boolean) => void;
	setIsSuccessfulFormSubmission: (bool: boolean) => void;
};

export const StateSchema = {
	firstName: z
		.string({ invalid_type_error: 'Required' })
		.max(30)
		.refine(isValidName, { message: 'First Name must not contain special characters' }),
	lastName: z
		.string({ invalid_type_error: 'Required' })
		.max(30)
		.refine(isValidName, { message: 'Last Name must not contain special characters' }),
	dateOfBirth: z
		.date({ invalid_type_error: 'Required' })
		.min(minBirthdayDate, 'Valid Date of Birth required')
		.max(maxBirthdayDate, 'Valid Date of Birth required'),
	gender: z.string({ invalid_type_error: 'Required' }).regex(/^[a-z]+$/i, 'Valid Gender required'),
	email: z.string().email('Valid Email required'),
	phone: MaskedPhoneNumberInputSchema.options.default,
	city: z
		.string({ invalid_type_error: 'Required' })
		.max(30)
		.refine(isValidName, { message: 'City must not contain special characters' }),
	state: z.string({ invalid_type_error: 'Required' }).regex(/^[a-z]+$/i, 'Valid State required'),
	zipCode: TextInputSchema.options.zip,
};

export const dateInvalid = (date: unknown) => {
	if (!(date instanceof Date) || isNaN(date.getTime())) {
		return true;
	}
	return false;
};

const usePatientInformationFormStore = create<State & Actions>((set) => ({
	patientId: '',
	firstName: '',
	lastName: '',
	dateOfBirth: null,
	gender: '',
	email: '',
	phone: '',
	city: '',
	state: '',
	zipCode: '',
	isAllPatientDataValid: false,
	isFormSubmitting: false,
	isSuccessfulFormSubmission: false,
	setPatientId: (patientId: string) => set({ patientId }),
	setFirstName: (input: string) => {
		try {
			StateSchema.firstName.parse(input);
			set({ firstName: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}
			throw new Error(
				'setFirstName() input did not match expected schema, make sure you are validating from StateSchema.firstName'
			);
		}
	},
	setLastName: (input: string) => {
		try {
			const { lastName } = StateSchema;
			lastName.parse(input);
			set({ lastName: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setLastName() input did not match expected schema, make sure you are validating from StateSchema.lastName'
			);
		}
	},
	setDateOfBirth: (input: Date) => {
		if (dateInvalid(input)) {
			throw new Error('setDateOfBirth() input was not a valid date object');
		}

		set({ dateOfBirth: input });
	},
	setGender: (input: TGender) => {
		try {
			const { gender } = StateSchema;
			gender.parse(input);
			set({ gender: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setGender() input did not match expected schema, make sure you are validating from StateSchema.gender'
			);
		}
	},
	setEmail: (input: string) => {
		try {
			const { email } = StateSchema;
			email.parse(input);
			set({ email: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setEmail() input did not match expected schema, make sure you are validating from StateSchema.email'
			);
		}
	},
	setPhone: (input: string) => {
		try {
			const { phone } = StateSchema;
			phone.parse(input);
			set({ phone: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setPhone() input did not match expected schema, make sure you are validating from StateSchema.phone'
			);
		}
	},
	setCity: (input: string) => {
		try {
			const { city } = StateSchema;
			city.parse(input);
			set({ city: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setCity() input did not match expected schema, make sure you are validating from StateSchema.city'
			);
		}
	},
	setState: (input: UsStateCode) => {
		try {
			const { state } = StateSchema;
			state.parse(input);
			set({ state: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setState() input did not match expected schema, make sure you are validating from StateSchema.state'
			);
		}
	},
	setZipCode: (input: string) => {
		try {
			const { zipCode } = StateSchema;
			zipCode.parse(input);
			set({ zipCode: input });
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}

			throw new Error(
				'setZipCode() input did not match expected schema, make sure you are validating from StateSchema.zipCode'
			);
		}
	},
	setIsAllPatientDataValid: (bool: boolean) => {
		set({ isAllPatientDataValid: bool });
	},
	setIsFormSubmitting: (bool: boolean) => {
		set({ isFormSubmitting: bool });
	},
	setIsSuccessfulFormSubmission: (bool: boolean) => {
		set({ isSuccessfulFormSubmission: bool });
	},
}));

export default usePatientInformationFormStore;
