import { create } from 'zustand';

export interface RevSpringState {
	revspringAccountId: string;
	revspringLocationValue: string;
}

export interface RevSpringActions {
	setRevspringAccountId: (accountId: string) => void;
	setRevspringLocationValue: (revspringLocationValue: string) => void;
}

// Combining state and actions for the store
type RevSpringStore = RevSpringState & RevSpringActions;

const useRevSpringStore = create<RevSpringStore>((set) => ({
	revspringAccountId: '',
	setRevspringAccountId: (revspringAccountId: string) => set({ revspringAccountId }),
	revspringLocationValue: '',
	setRevspringLocationValue: (revspringLocationValue: string) => set({ revspringLocationValue }),
}));

export default useRevSpringStore;
