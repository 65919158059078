import { Box, Grid } from '@mui/material';

import { Accordions as TAccordions, TabsTabsCollection, Tab, Tabs as ITabs } from '@/types/generated';
import Accordions from '@/components/Accordions';
import { ContentfulBackground, Maybe, TTabsSize, TTabRowVariant } from '@/types';

import TemplateContainer from '../TemplateContainer/TemplateContainer';
import TAGTabs from '../TAGTabs';
import { IAccordions } from '../Accordions/Accordions';

export interface ITabsProps extends ITabs {
	/**
	 * Background color for the section, and it will also override the background color of the child components.
	 * @type { Maybe<ContentfulBackground>}
	 * @memberof ITabs
	 * @default 'White'
	 *
	 **/
	backgroundColor?: Maybe<ContentfulBackground>;
	/**
	 * Theme for tabs
	 * @type {Maybe<TTabsTheme>}
	 * @memberof ITabs
	 * @default 'Standard'
	 **/
	tabRowVariant?: Maybe<TTabRowVariant>;
	/**
	 * Tabs contents collection (2-4 items).
	 * Could be Accordions
	 * @type {TabsTabsCollection}
	 * @required
	 * @memberof ITabs
	 * @example
	 **/
	tabsCollection: TabsTabsCollection;
	/**
	 * Size for tabs
	 * @type {Maybe<TTabsSize>}
	 * @memberof ITabs
	 * @default 'small'
	 **/
	tabsSize?: Maybe<TTabsSize>;
	/**
	 * Use for content padding
	 * @type {Maybe<boolean>}
	 * @memberof ITabs
	 * @default false
	 **/
	withContentInnerGap?: Maybe<boolean>;
}

function isAccordions(content?: Maybe<TAccordions>): content is IAccordions {
	if (!content) return false;

	return content.__typename === 'Accordions';
}

export default function Tabs({
	tabsCollection,
	backgroundColor = 'White',
	tabRowVariant = 'Standard',
	tabsSize = 'Small',
	withContentInnerGap = false,
}: ITabsProps) {
	const tabsItems = ((tabsCollection?.items as Tab[]) || []).map((tab, i) => {
		const content = isAccordions(tab?.content) ? (
			<Grid item key={i} data-test-id={`section_tabs_${i + 1}`}>
				<Accordions {...tab.content} backgroundColor={backgroundColor} />
			</Grid>
		) : null;

		return { label: tab?.label ?? '', content, value: tab.sys.id };
	});

	return (
		<TemplateContainer>
			<Box
				data-test-id="section_tabs"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				<TAGTabs
					variant="fullWidth"
					tabs={tabsItems}
					centered
					dataTestId="tabs"
					tabRowVariant={tabRowVariant ?? 'Standard'}
					tabsSize={tabsSize ?? 'Small'}
					withContentInnerGap={withContentInnerGap ?? false}
				/>
			</Box>
		</TemplateContainer>
	);
}
