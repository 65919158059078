import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

interface AppointmentTypes {
	[key: string]: string;
}

export default async function getAppointmentTypes(params: {
	revspringLocationValue: string;
}): Promise<AppointmentTypes> {
	try {
		const appointmentTypes = await httpClient.request<AppointmentTypes>({
			url: `Appointment`,
			params,
			requestName: 'getAppointmentTypes',
		});

		if (!appointmentTypes) {
			throw new Error('Could not find appointment types');
		}

		return Promise.resolve(appointmentTypes);
	} catch (err) {
		logWarning(`Could not find facility.`);
		if (err instanceof Error) {
			logError(`REVSPRING_APPOINTMENT_TYPES_FAILURE: ${err.message}`);
		}
		return Promise.resolve({});
	}
}
