import { useState, useEffect, FC, memo } from 'react';

import { Typography, IconButton, InputAdornment, useTheme, useMediaQuery, Grid, Skeleton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import TAGTextFieldInput from '@/components/TAGTextFieldInput';
import { Asset, FacilityBrand, NearbyFacility, BffBrandType } from '@/types/generated';
import { SchedulingSystemType } from '@/components/GoogleMap/LocationCard/LocationCard';
import { useFacilityContext } from '@/context/FacilityContextProvider.ctx';
import getNearbyLocations from '@/services/getNearbyLocations';
import { Location } from '@/rest/__generated/revspringApi';
import { parseStrToNum } from '@/utils';
import useDebounce from '@/hooks/useDebounce/useDebounce';
import LocationCard from '@/components/GoogleMap/LocationCard';

import { textInputFieldStyles, locationCardSectionStyles } from './NearbyLocations.styles';

export interface INearbyLocations {
	locationCardMedia: Asset;
}

interface INearbyLocationsCards {
	locationCardMedia: Asset;
	nearbyLocationsList: Location[];
	facilityBrand: FacilityBrand | BffBrandType;
	loading?: boolean;
}

const NearbyLocationsCards: FC<INearbyLocationsCards> = ({
	nearbyLocationsList,
	facilityBrand,
	locationCardMedia,
	loading,
}) => {
	return (
		<>
			{loading
				? Array.from({ length: 5 }).map((_, index) => (
						<Grid item key={index} sx={{ pr: '0.5rem', height: '100%', width: '18.75rem' }}>
							<Skeleton variant="rounded" width={'100%'} height={'100%'} />
						</Grid>
				  ))
				: nearbyLocationsList.map((locationObj, index) => {
						const {
							displayName,
							scheduleAppointmentURL,
							phone: locationPhoneNumber,
							address,
							city,
							zip,
							state,
							distanceInMiles,
							schedulingSystem,
							code,
							latitude,
							longitude,
						} = locationObj;

						const formattedLocation = {
							facility: {
								address: {
									address1: address,
									address2: '',
									city,
									zipCode: zip,
									stateCode: state,
								},
								technicalInfrastructure: {
									phoneNumber: locationPhoneNumber,
								},
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								name: displayName,
								scheduling: {
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									schedulingUrl: scheduleAppointmentURL,
								},
								code,
								brand: facilityBrand,
								location: {
									latitude,
									longitude,
								},
							} as NearbyFacility['facility'],
							distanceInMiles: parseStrToNum(distanceInMiles) ?? 0,
							locationScheduling: {
								schedulingSystem: schedulingSystem as SchedulingSystemType,
							},
							revspringAccountId: locationObj.revspringAccountId,
						};

						return (
							<Grid item key={index} sx={{ pr: '0.5rem' }}>
								<LocationCard
									location={formattedLocation.facility}
									isSmallScreen={true}
									displayAsScrollableInOneRow
									defaultImage={locationCardMedia}
									distance={formattedLocation.distanceInMiles}
									locationCardSx={{ marginX: 0 }}
									locationScheduling={formattedLocation.locationScheduling}
									revspringAccountId={formattedLocation.revspringAccountId}
								/>
							</Grid>
						);
				  })}
		</>
	);
};

function NearbyLocations({ locationCardMedia }: INearbyLocations) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const inputProps = { 'data-test-id': 'section_input_search' };

	const { facilityBrand, locationState } = useFacilityContext();

	const [nearbyLocationsList, setNearbyLocationsList] = useState<Location[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState('');
	const debouncedSearch = useDebounce(searchQuery, 500);

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.currentTarget.value);
	};

	useEffect(() => {
		const fetchData = async ({
			longitude,
			latitude,
			queryString,
		}: {
			longitude?: number;
			latitude?: number;
			queryString?: string;
		}) => {
			setLoading(true);
			const nearbyLocations = await getNearbyLocations({ longitude, latitude, queryString });
			setNearbyLocationsList(nearbyLocations);
			setLoading(false);
		};

		if (debouncedSearch) {
			void fetchData({ queryString: debouncedSearch });
			return;
		}

		if (!locationState?.user?.coordinates.latitude || !locationState?.user?.coordinates.longitude) {
			return;
		}
		// TODO: the following geo location should be using nearby facility's geo location instead of user's
		// We're seeing issue with revspring not using the matching geo location
		// That's why we are using user's coordinates for now
		const { longitude, latitude } = locationState.user.coordinates;
		void fetchData({ longitude, latitude });
	}, [locationState, debouncedSearch]);

	if (!facilityBrand) return null;

	return (
		<Grid
			item
			container
			data-test-id="section_nearby_locations"
			display="flex"
			flexDirection="column"
			rowSpacing={3}
		>
			{/* title */}
			<Grid item>
				<Typography data-test-id="section_title" variant={isSmallScreen ? 'header3' : 'header2'}>
					{'Nearby Locations'}
				</Typography>
			</Grid>
			{/* search bar */}
			<Grid item>
				<TAGTextFieldInput
					fieldPlaceHolder="Enter Zip, City, or State"
					onChange={handleSearchInputChange}
					value={searchQuery}
					inputSx={textInputFieldStyles}
					inputProps={inputProps}
					endAdornment={
						<InputAdornment position="end">
							<IconButton data-test-id="button_input_icon">
								<SearchIcon data-test-id="icon_search" />
							</IconButton>
						</InputAdornment>
					}
				/>
			</Grid>
			{/* location cards */}
			<Grid
				data-test-id="section_location_cards_container"
				item
				container
				display="flex"
				direction="column"
				sx={locationCardSectionStyles}
			>
				<NearbyLocationsCards
					nearbyLocationsList={nearbyLocationsList}
					loading={loading}
					facilityBrand={facilityBrand}
					locationCardMedia={locationCardMedia}
				/>
			</Grid>
		</Grid>
	);
}

export default memo(NearbyLocations);
