import { Maybe } from '@/types';

export function resolveImageAspectRatio(iamgeLayout?: Maybe<string>) {
	switch (iamgeLayout) {
		case 'circle':
		case 'square':
			return '1:1';
		case 'rectangle':
			return '16:9';
		default:
			return '16:9';
	}
}

export const enum ServiceMediaType {
	Image = 'Image',
	Video = 'Video',
	YouTubeVideo = 'YouTube Video',
}
