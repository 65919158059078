import { useState } from 'react';

import { Grid, useMediaQuery, useTheme, Typography } from '@mui/material';

import { IconName } from '@/components/TAGSvgIcon/TAGSvgIcon';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { HeadingTag } from '@/types';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import {
	Maybe,
	CategoryPickerCategoriesCollection,
	Category as TCategory,
	CategoryPickerButtonsCollection,
	ImageAsset,
	ContentTypeRichText as TContentTypeRichText,
} from '@/types/generated';

import TAGButton from '../TAGButton';
import ContentfulButton from '../ContentfulButton';

import Category from './Category/Category';
import {
	getGridContainerStyles,
	getGridItemStyles,
	getEyebrowStyles,
	getHeadingStyles,
	paddingStyles,
	normalButtonsStyles,
} from './CategoryPicker.styles';
export interface ICategoryPickerProps {
	/**
	 * Categories content collection (4-12 items)
	 * @type {CategoryPickerCategoriesCollection}
	 * @required
	 * @memberof ICategoryPickerProps
	 */
	categoriesCollection: CategoryPickerCategoriesCollection;
	/**
	 * Description of Category Picker below the title headline. It is an alias of description field.
	 * @type {TContentTypeRichText}
	 * @memberof ICategoryPickerProps
	 */
	bodyCopy?: Maybe<TContentTypeRichText>;
	/**
	 * Eyebrow text that can be set.
	 * @type {string}
	 * @memberof ICategoryPickerProps
	 */
	eyebrow?: string;
	/**
	 * Eyebrow heading that will set the H-tag to appear in the HTML without effecting the style of the element. normal will render a <p> tag.
	 * @type {string}
	 * @memberof ICategoryPickerProps
	 */
	eyebrowHeading?: HeadingTag;
	/**
	 * Display the amount of categories before displaying the show more button.
	 * @type {number}
	 * @memberof ICategoryPickerProps
	 */
	mobileThreshold?: number;
	/**
	 * Sets the boolean value for one or two rows when the categories length is 6 or less
	 * @type {boolean}
	 * @memberof ICategoryPickerProps
	 */
	setTwoRows?: boolean;
	/**
	 * Title text that can be set.
	 * @type {string}
	 * @memberof ICategoryPickerProps
	 */
	title?: string;
	/**
	 * Title heading that will set the H-tag to appear in the HTML without effecting the style of the element. normal will render a <p> tag.
	 * @type {string}
	 * @memberof ICategoryPickerProps
	 */
	titleHeading?: HeadingTag;
	/**
	 * This determines if the categories should have a transparent background.
	 * @type {boolean}
	 * @memberof ICategoryPickerProps
	 */
	transparentCategories?: boolean;
	/**
	 * This adds up to 2 buttons at the bottom of the categories
	 * @type {CategoryPickerNormalButtonsCollection}
	 * @memberof ICategoryPickerProps
	 */
	buttonsCollection?: Maybe<CategoryPickerButtonsCollection>;
}

interface StateObject {
	/**
	 * The item category from a collection.
	 * @type {TCategory}
	 * @memberof StateObject
	 */
	categoryItems: Array<Maybe<TCategory>>;
	/**
	 * Show more button.
	 * @type {boolean}
	 * @memberof StateObject
	 */
	showMore: boolean;
	/**
	 * determines if the amount of categories is even or odd. the rows on the grid will adjust accordingly
	 * @type {boolean}
	 * @memberof StateObject
	 */
	isEvenAmount: boolean;
}

export default function CategoryPicker({
	bodyCopy: description,
	eyebrow,
	eyebrowHeading = 'h4',
	title,
	titleHeading = 'h1',
	categoriesCollection,
	mobileThreshold = 6,
	setTwoRows = false,
	transparentCategories = false,
	buttonsCollection,
}: Readonly<ICategoryPickerProps>) {
	const mobileThresholdValue = !mobileThreshold ? 6 : mobileThreshold;
	const categoriesCollectionItems = categoriesCollection?.items || [];
	const categoryLength = categoriesCollectionItems.length;

	const theme = useTheme();
	const isSmall: boolean = useMediaQuery(theme.breakpoints.down('md'));
	const isMedium: boolean = useMediaQuery(theme.breakpoints.between('md', 'lg'), { noSsr: false });

	const isThresholdExceeded: boolean = categoriesCollectionItems?.length > mobileThreshold;

	const [state, setState] = useState<StateObject>({
		categoryItems:
			isSmall && isThresholdExceeded
				? categoriesCollectionItems?.slice(0, mobileThresholdValue)
				: categoriesCollectionItems,
		showMore: isSmall && isThresholdExceeded,
		isEvenAmount: categoryLength % 2 === 0,
	});

	const { categoryItems, showMore, isEvenAmount } = state;

	if (!categoryItems) {
		return null;
	}

	const handleShowMore = () => {
		setState({
			...state,
			categoryItems: categoriesCollectionItems,
			showMore: false,
		});
	};

	const handleShowLess = () => {
		setState({
			...state,
			categoryItems: categoriesCollectionItems?.slice(0, mobileThresholdValue),
			showMore: true,
		});
	};

	const categoryColumnThreshold = 6;
	const evenCategories = 2;
	const xsScreenColumns = 12;
	const isColumnThresholdExceeded = categoryLength > categoryColumnThreshold;

	// conditions for one or two rows when the categories length is 6 or less
	const columnCondition = isColumnThresholdExceeded ? categoryLength : setTwoRows ? 6 : 12;
	const oddCategories = isColumnThresholdExceeded ? 1.75 : 2;
	const isPaddingNeeded = !isSmall && categoryLength <= categoryColumnThreshold && setTwoRows;

	// Check if any category has an image
	const hasImages = categoryItems?.some((category) => category?.imageAsset);
	const filteredCategoryItems = hasImages
		? categoryItems.filter((category) => category?.imageAsset) // If there's an image, only show categories with images
		: categoryItems; // If no images are found, show all categories (icons only)

	return (
		<Grid
			// spacing prop will not work here because of other conditional padding in the child components
			data-test-id="category_picker_grid_container"
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={1.5}
			sx={getGridContainerStyles(theme)}
		>
			{eyebrow && (
				<Grid item data-test-id="category_picker_eyebrow_item">
					<Typography
						variant={getHeadingVariant(eyebrowHeading)}
						color="text.secondary"
						sx={getEyebrowStyles(theme)}
						component={eyebrowHeading === 'normal' ? 'p' : eyebrowHeading}
					>
						{eyebrow}
					</Typography>
				</Grid>
			)}
			{title && (
				<Grid item data-test-id="category_picker_title_item">
					<Typography
						variant={getHeadingVariant(titleHeading)}
						color="text.primary"
						sx={getHeadingStyles(theme)}
						component={titleHeading === 'normal' ? 'p' : titleHeading}
					>
						{title}
					</Typography>
				</Grid>
			)}
			{description && description?.content && (
				<Grid item data-test-id="category_picker_description_item">
					<ContentTypeRichText
						{...description}
						centerAlign={true}
						dataTestId="category_picker_description_item_Rich_Text"
						contentMaxWidth="100%"
						disablePaddingOn={['Top', 'Bottom']}
						backgroundColor="transparent"
					/>
				</Grid>
			)}
			<Grid item sx={getGridItemStyles(isSmall)} data-test-id="category_picker_categories_item" container>
				<Grid
					container
					justifyContent={'center'}
					spacing={isMedium ? 1.5 : 2.5}
					sx={paddingStyles(isPaddingNeeded)}
					columns={{ xs: xsScreenColumns, md: columnCondition }}
					data-columns={JSON.stringify({ xs: xsScreenColumns, md: columnCondition })}
					data-test-id="category_picker_categories_item_container"
				>
					{filteredCategoryItems?.length > 0 &&
						filteredCategoryItems?.map((category, index) => (
							<Grid
								item
								key={`category-picker-button-${category?.title as string}`}
								data-test-id={`category_picker_button_number_${index}`}
								mt={{ marginTop: !isSmall ? '20px' : '' }}
								xs={6}
								md={isEvenAmount ? evenCategories : oddCategories}
								sx={{ alignItems: 'stretch' }} // Ensuring consistent height
							>
								<Category
									imageAsset={category?.imageAsset as ImageAsset}
									icon={category?.icon as IconName}
									title={category?.title as string}
									link={category?.link as string}
									isSmall={isSmall}
									transparentBg={transparentCategories}
									showLinearBackground={
										isSmall &&
										showMore &&
										(index === categoryItems?.length - 1 || index === categoryItems.length - 2)
									}
								/>
							</Grid>
						))}
				</Grid>
			</Grid>
			{isSmall && isThresholdExceeded && (
				<Grid item data-test-id="category_picker_show_more_or_less">
					<TAGButton
						variant="tertiaryDefault"
						size="M"
						onClick={showMore ? handleShowMore : handleShowLess}
						dataTestId={showMore ? 'show_more_button' : 'show_less_button'}
					>
						{showMore ? 'Show More' : 'Show Less'}
					</TAGButton>
				</Grid>
			)}
			{buttonsCollection && (
				<Grid item sx={normalButtonsStyles} data-test-id={'normal_buttons_collection'}>
					{buttonsCollection.items.map((button, index) =>
						button ? (
							<ContentfulButton
								key={button.sys.id}
								{...button}
								dataTestId={`category_picker_normal_button_index_${index}`}
								isSmallScreen={isSmall}
							/>
						) : null
					)}
				</Grid>
			)}
		</Grid>
	);
}
