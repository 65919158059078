import { useState } from 'react';

import { Link, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Maybe, TReviewsMode } from '@/types';

import { resolveContentAlignment } from '../../../PatientReviews.helpers';

import { linkStyles } from './ExpandableText.styles';

export interface IExpandableText {
	text: string;
	trimThreshold: number;
	dataTestIdSuffix: string;
	contentAlign?: Maybe<string>;
	isDarkBackground?: boolean;
	mode?: TReviewsMode;
}

export default function ExpandableText({
	text,
	trimThreshold,
	dataTestIdSuffix,
	contentAlign = 'Center',
	isDarkBackground = false,
	mode = 'list',
}: IExpandableText) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [isExpanded, setIsExpanded] = useState(false);

	const isTextTooLong = text.length > trimThreshold;
	const shouldShowFullText = !isTextTooLong || isExpanded;
	const displayedText = shouldShowFullText ? text : text.substring(0, trimThreshold).trim() + '...';

	return (
		<Typography
			variant={mode === 'carousel' && !isSmallScreen ? 'bodyXLBook' : 'bodyLargeBook'}
			sx={{
				textAlign: resolveContentAlignment(contentAlign, true),
			}}
			color={isDarkBackground ? 'text.light' : 'text.secondary'}
			data-test-id={`text_${dataTestIdSuffix}`}
		>
			{displayedText}
			{isTextTooLong && (
				<>
					{' '}
					<Link
						component="button"
						variant={mode === 'carousel' ? 'bodyXLBook' : 'bodyLargeBook'}
						sx={linkStyles}
						underline={isDarkBackground ? 'always' : 'none'}
						color={isDarkBackground ? 'text.light' : 'text.interactive'}
						onClick={() => setIsExpanded(!isExpanded)}
						data-test-id={`link_${dataTestIdSuffix}_ellipsis`}
					>
						{isExpanded ? 'Less' : 'More'}
					</Link>
				</>
			)}
		</Typography>
	);
}
