import { Theme } from '@mui/material';

export const descriptionWrapperStyles = (theme: Theme) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: '1.25rem 0',
	gap: {
		xs: '1.25rem',
		md: '1.75rem',
	},
	borderBottom: '0.063rem solid',
	borderColor: theme.palette.neutrals?.primary,
});

export const buttonBoxStyles = (isSmall: boolean) => {
	return {
		display: 'flex',
		alignItems: 'flex-start',
		columnGap: isSmall ? '.5rem' : '.75rem',
		rowGap: isSmall ? '.75rem' : '1rem',
		flexDirection: isSmall ? 'column' : 'row',
		flexWrap: 'wrap',
	};
};
