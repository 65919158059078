import { FC, useEffect } from 'react';

import { Box } from '@mui/material';

import { dataLayerConfig, joinValidStrs, formatUsingTZ, supportedTZs } from '@/utils';
import SingleMessage from '@/components/SingleMessage';

import { confirmationScreenProps } from './Confirmation.data';

export interface ConfirmationProps {
	accountId: string;
	vendorAppointmentId: string;
	displayName: string;
	address: string;
	city: string;
	state: string;
	apptDate: string;
	apptTime: string;
	reason: string;
	timezone: string;
}

const Confirmation: FC<ConfirmationProps> = ({
	accountId = '',
	vendorAppointmentId = '',
	displayName = '',
	address = '',
	city = '',
	state = '',
	apptDate = '',
	apptTime = '',
	reason = '',
	timezone = '',
}) => {
	useEffect(() => {
		if (apptTime === '') return;

		dataLayerConfig({
			event: 'saa_flow',
			step_name: 'schedule_complete',
			brand: 'WellNow',
			reason_for_visit: reason,
			clinic_location: address,
			clinic_state: state,
			clinic_city: city,
			appointment_date: formatUsingTZ(apptDate, supportedTZs[timezone], 'MM/dd/yyyy'),
			appointment_time: apptTime,
		});
	}, [address, city, state, apptDate, apptTime, reason, timezone]);

	return (
		<Box data-test-id="section_confirmation">
			<SingleMessage
				{...confirmationScreenProps({
					env: `${process.env.NODE_ENV}`,
					accountId,
					vendorAppointmentId,
					confirmedApptTime: `${apptTime}, ${formatUsingTZ(
						apptDate,
						supportedTZs[timezone],
						'MMM dd, yyyy'
					)}`,
					locStr: joinValidStrs(' ', [displayName, address, joinValidStrs(', ', [city, state])]),
				})}
				disablePaddingOn={['Top', 'Bottom']}
			/>
		</Box>
	);
};

export default Confirmation;
