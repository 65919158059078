import { Grid, Typography } from '@mui/material';

import { BffWorkingHours, FacilityDailyWorkingHours } from '@/types/generated';
import { getTodayWorkingHours, getTodayWorkingHoursFromBff, getWorkingHours, getWorkingHoursFromBff } from '@/utils';
import TAGDialog from '@/components/TAGDialog';

export interface IOfficeHours {
	workingHours: FacilityDailyWorkingHours[];
	showCallCenterHours?: boolean;
	handleClose: () => void;
	useFacilityBeffeDataSource?: boolean;
	officeHoursLabel?: string;
	callCenterLabel?: string;
}

const OfficeHours = ({
	workingHours,
	handleClose,
	showCallCenterHours = false,
	useFacilityBeffeDataSource = false,
	officeHoursLabel,
	callCenterLabel,
}: IOfficeHours) => {
	const todayWorkingHours = useFacilityBeffeDataSource
		? getTodayWorkingHoursFromBff(workingHours as BffWorkingHours[])
		: getTodayWorkingHours(workingHours);
	const dayHours = useFacilityBeffeDataSource
		? getWorkingHoursFromBff(workingHours as BffWorkingHours[])
		: getWorkingHours(workingHours);

	const Hours = ({ hoursType }: { hoursType: 'callCenter' | 'office' }) => {
		return (
			<Grid item key={`${hoursType}-item`} data-test-id={`${hoursType}_item`}>
				<Grid item container alignItems="center" direction="column">
					<Grid item sx={{ paddingBottom: 1 }}>
						<Typography variant="bodyLargeBold" color="text.primary">
							{hoursType === 'callCenter'
								? callCenterLabel || 'Call Center Hours'
								: officeHoursLabel || 'Office Hours'}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container>
							<Grid item container gap={1} justifyContent="center" sx={{ paddingBottom: 1 }}>
								<Grid item xs={3} sx={{ textAlign: 'end' }}>
									<Typography variant="bodyMediumBook" color="success.dark">
										Today:
									</Typography>
								</Grid>
								<Grid item xs={showCallCenterHours ? 8 : 6}>
									<Typography variant="bodyMediumBook" color="text.tertiary">
										{todayWorkingHours}
									</Typography>
								</Grid>
							</Grid>

							<Grid container gap={1} sx={{ flexDirection: 'column' }}>
								{dayHours.map((item) => (
									<Grid
										item
										container
										key={`${hoursType}-${item.day}`}
										gap={1}
										justifyContent="center"
									>
										<Grid item xs={3} sx={{ textAlign: 'end' }}>
											<Typography variant="bodyMediumBook" color="text.primary">
												{item.day}:
											</Typography>
										</Grid>
										<Grid item xs={showCallCenterHours ? 8 : 6}>
											<Typography variant="bodyMediumBook" color="text.tertiary">
												{item.hours}
											</Typography>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<TAGDialog
			open
			showCloseIcon
			handleClose={handleClose}
			maxWidth={false}
			fullWidth
			sx={{ padding: '0px' }}
			data-test-id="office_hours_dialog"
			renderActions={() => (
				<Grid sx={{ padding: '40px' }}>
					<Grid container>
						<Grid item xs={showCallCenterHours ? 6 : 12}>
							<Hours hoursType="office" />
						</Grid>
						{showCallCenterHours && (
							<Grid item xs={6}>
								<Hours hoursType="callCenter" />
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		/>
	);
};

export default OfficeHours;
