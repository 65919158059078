import { metaData, sys } from '@/constants';
import { SingleMessageButtonsCollection } from '@/types/generated';

const emptyMetaData = {
	contentfulMetadata: {
		...metaData,
	},
	_id: 'emptyMetaDataId',
	sys: { ...sys },
};
const confirmationBackgroundImage = {
	contentType: 'image/png',
	url: 'https://images.ctfassets.net/m8zwsu9tyucg/1uTH1JHi3pJoyB0bmnfUim/6a56a8d751dcdee2415477da589e9580/on_page_scheduling_background.png',
	description: 'WellNow Scheduling background image',
	...emptyMetaData,
};

const confirmationFocalImage = {
	image: confirmationBackgroundImage,
	focalPoint: {
		x: 172,
		y: 144,
	},
	...emptyMetaData,
};

export type ConfirmationMsg = { confirmedApptTime: string; locStr: string };
export type FormsButtonVars = { env: string; accountId: string; vendorAppointmentId: string };

export const confirmationRichText = ({ confirmedApptTime, locStr }: ConfirmationMsg) => ({
	json: {
		data: {},
		content: [
			{
				data: {},
				content: [
					{
						data: {},
						content: [
							{
								data: {},
								marks: [],
								value: `Your visit is confirmed for ${confirmedApptTime} at ${locStr}. Please check your email or text for further information. `,
								nodeType: 'text',
							},
						],
						nodeType: 'heading-1',
					},
					{
						data: {},
						content: [
							{
								data: {},
								content: [
									{
										data: {},
										marks: [],
										value: 'For the fastest experience, complete your registration before arrival',
										nodeType: 'text',
									},
								],
								nodeType: 'paragraph',
							},
						],
						nodeType: 'list-item',
					},
					{
						data: {},
						content: [
							{
								data: {},
								content: [
									{
										data: {},
										marks: [],
										value: 'We have sent you a link by email and text to continue your registration and manage your visit',
										nodeType: 'text',
									},
								],
								nodeType: 'paragraph',
							},
						],
						nodeType: 'list-item',
					},
					{
						data: {},
						content: [
							{
								data: {},
								content: [
									{
										data: {},
										marks: [],
										value: 'Check your spam folders if you do not see a text or email, or call your office for assistance',
										nodeType: 'text',
									},
								],
								nodeType: 'paragraph',
							},
						],
						nodeType: 'list-item',
					},
				],
				nodeType: 'unordered-list',
			},
		],
		nodeType: 'document',
	},
	links: {
		entries: {
			inline: [],
			hyperlink: [],
			block: [],
		},
		assets: {
			hyperlink: [],
			block: [],
		},
		resources: {
			block: [
				{
					sys: {
						type: '',
						urn: '',
						linkType: '',
					},
				},
			],
			hyperlink: [],
			inline: [],
		},
	},
	...emptyMetaData,
});

const backToHomeButton = {
	label: 'Back to Home',
	variant: 'Tertiary High Contrast',
	buttonLink: '/',
	size: 'medium',
	...emptyMetaData,
	sys: {
		...emptyMetaData.sys,
		id: 'backToHomeButton1',
	},
};

const formsButton = ({ env, accountId, vendorAppointmentId }: FormsButtonVars) => ({
	label: 'Fill Out Patient Forms',
	variant: 'Primary High Contrast',
	buttonLink: `https://api${
		['production', 'prod'].includes(env) ? '' : '-qa2'
	}.talksoftonline.com/v1.0/intakeForm/getRedirect?accountId=${accountId}&vendorAppointmentId=${vendorAppointmentId}`,
	size: 'medium',
	...emptyMetaData,
	sys: {
		...emptyMetaData.sys,
		id: 'formButton1',
	},
});

const callToActionButtonsCollection = (props: FormsButtonVars) =>
	({
		items: [formsButton(props), backToHomeButton],
		limit: 1,
		total: 1,
		skip: 0,
	} as unknown as SingleMessageButtonsCollection);

export const confirmationScreenProps = (props: ConfirmationMsg & FormsButtonVars) => ({
	eyebrow: "You're all set!",
	templateWidth: 'Full',
	templateHeight: 'Large',
	focalPointImage: confirmationFocalImage,
	buttonsCollection: { ...callToActionButtonsCollection(props) },
	content: confirmationRichText(props),
	tc: null,
	...emptyMetaData,
});
