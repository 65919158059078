import { Theme } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

import { IconName } from '../TAGSvgIcon';

export const templateContainerStyles = {
	textAlign: 'center',
	backgroundColor: 'background.secondary',
	padding: '3.5rem 2.5rem',
};

export const headingStyles = (isSmallScreen: boolean, icon?: IconName) => {
	return {
		display: 'flex',
		flexDirection: 'column',
		gap: isSmallScreen ? '1.25rem' : '.75rem',
		marginTop: icon ? (isSmallScreen ? '1.5rem' : '1.75rem') : '0',
	};
};

export const getTitleStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'header1'),
});
