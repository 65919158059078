import { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import { useFacilityContext } from '@/context';
import { Maybe, SegmentsContainerContentsItem, SegmentsContainerContentsCollection } from '@/types/generated';
import HalfScreen from '@/components/HalfScreen';
import HeroV2 from '@/components/Hero';
import Service from '@/components/Service';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import MultiCardV2 from '@/components/MultiCard';
import SingleMessage from '@/components/SingleMessage';
import RichTextBlockSeo from '@/components/RichTextBlockSeo';
import OdpHero from '@/components/Hero/ODP/ODPHero';
import ServiceLineHero from '@/components/ServiceLineHero';

import { IFacilitySegmentCollection } from '../../types';

export type TemplateName = keyof typeof componentMap;

const componentMap = {
	HalfScreen,
	HeroV2,
	Service,
	ContentTypeRichText,
	MultiCardV2,
	SingleMessage,
	RichTextBlockSeo,
	OdpHero,
	ServiceLineHero,
};
export interface ISegmentsContainerProps {
	/**
	 * Segments Container contents collection.
	 *
	 * @type {SegmentsContainerContentsCollection}
	 * @memberof ISegmentsContainerProps
	 */
	contentsCollection: Maybe<SegmentsContainerContentsCollection>;
}

export default function SegmentsContainer({ contentsCollection }: ISegmentsContainerProps) {
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	const { facilityCode } = useFacilityContext();
	const segments = contentsCollection?.items || [];

	if (!segments.length) {
		return <Box component={'section'} data-test-id="empty-segments-container" />;
	}

	const findDefaultIndex = () => {
		// if there are more than one default element, then return first one
		const defaultIdx = segments.findIndex((segment) => !segment?.facilitySegmentCollection);
		// if without default element, then last one will be the default
		if (defaultIdx < 0) {
			return segments.length - 1;
		}
		return defaultIdx;
	};
	const facilityList = (facilities: IFacilitySegmentCollection) => {
		return facilities ? facilities.split(',') : [];
	};

	const isFacilityInList = (segment: Maybe<SegmentsContainerContentsItem>) => {
		if (facilityCode) {
			const facilitySegmentCollection = segment?.facilitySegmentCollection;
			const facilityCollection = facilityList(facilitySegmentCollection);
			return facilityCollection.includes(facilityCode);
		}
		return false;
	};

	const findSegmentIndex = () => {
		const defaultIdx = findDefaultIndex();
		const index = segments.findIndex((segment) => isFacilityInList(segment));
		if (index < 0) {
			return defaultIdx;
		} else {
			return index;
		}
	};

	const componentIndex = findSegmentIndex();
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const Component = componentMap[segments[componentIndex]?.__typename as TemplateName];

	const fallBackIndex = findDefaultIndex();
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const FallBackComponent = componentMap[segments[fallBackIndex]?.__typename as TemplateName];

	const dataTestId = isClient
		? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		  `Segment_${segments[componentIndex]?.sys?.id}`
		: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		  `Segment_${segments[fallBackIndex]?.sys?.id}`;

	return (
		<Box component={'section'} data-test-id={dataTestId}>
			{segments[componentIndex]?.__typename &&
			componentMap[segments[componentIndex]?.__typename as TemplateName] &&
			isClient ? (
				//@ts-ignore
				<Component {...segments[componentIndex]} />
			) : (
				//@ts-ignore
				<FallBackComponent {...segments[fallBackIndex]} />
			)}
		</Box>
	);
}
