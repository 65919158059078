import Accordions from '@/components/Accordions';
import AgendaStepper from '@/components/AgendaStepper';
import BirdEyeReviews from '@/components/BirdEyeReviews';
import Carousel from '@/components/Carousel';
import CategoryPicker from '@/components/CategoryPicker';
import Columns from '@/components/Columns';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import DisclaimerV2 from '@/components/Disclaimer';
import Footer from '@/components/Footer';
import GoogleMapTemplate from '@/components/GoogleMap';
import HalfScreen from '@/components/HalfScreen';
import Header from '@/components/Header';
import HeroV2 from '@/components/Hero';
import Iframe from '@/components/Iframe';
import Insurance from '@/components/Insurance';
import MeetOurStaff from '@/components/MeetOurStaff';
import MultiCardV2 from '@/components/MultiCard';
import PatientReviewsV2 from '@/components/PatientReviews';
import QuickLinks from '@/components/QuickLinks';
import RichTextBlockSeo from '@/components/RichTextBlockSeo';
import Schema from '@/components/Schema';
import Service from '@/components/Service';
import ServiceLineHero from '@/components/ServiceLineHero';
import SegmentsContainer from '@/components/SegmentsContainer';
import SingleMessage from '@/components/SingleMessage';
import SmileAssessmentForm from '@/components/SmileAssessmentForm';
import Spacer from '@/components/Spacer';
import Table from '@/components/Table';
import TermsAndConditions from '@/components/TermsAndConditions';
import TextBlockSeo from '@/components/TextBlockSEO';
import LinkForwarder from '@/components/LinkForwarder';
import OdpHero from '@/components/Hero/ODP/ODPHero';
import BlogDetailsPage from '@/components/BlogDetailsPage';
import OnPageScheduling from '@/components/OnPageScheduling';
import ProductFeature from '@/components/ProductFeature';
import BlogListPage from '@/components/BlogListPage';
import BlogList from '@/components/BlogList';
import StateLinks from '@/components/Centers/StateLinks';
import CityLinks from '@/components/Centers/CityLinks';
import VideoCarousel from '@/components/VideoCarousel';
import Tabs from '@/components/Tabs';
import Marquee from '@/components/Marquee';
import Quiz from '@/components/Quiz';

export const componentMap = {
	Accordions,
	AgendaStepper,
	Carousel,
	CategoryPicker,
	CityLinks,
	Columns,
	ContentTypeRichText,
	DisclaimerV2,
	Footer,
	GoogleMapTemplate,
	HalfScreen,
	Header,
	HeroV2,
	OdpHero,
	Iframe,
	Insurance,
	LinkForwarder,
	MeetOurStaff,
	MultiCardV2,
	PatientReviewsV2,
	QuickLinks,
	RichTextBlockSeo,
	Schema,
	SegmentsContainer,
	Service,
	ServiceLineHero,
	SingleMessage,
	StateLinks,
	SmileAssessmentForm,
	Spacer,
	Table,
	TermsAndConditions,
	TextBlockSeo,
	BlogDetailsPage,
	OnPageScheduling,
	ProductFeature,
	BlogListPage,
	BlogList,
	BirdEyeReviews,
	VideoCarousel,
	Tabs,
	Marquee,
	Quiz,
};
