import { Theme } from '@mui/material';

import { ImageAsset } from '@/types/generated';

export const getGridItemStyles = (theme: Theme, showLinearBackground: boolean, transparentBg: boolean) => ({
	background: transparentBg
		? 'transparent'
		: showLinearBackground
		? 'linear-gradient(to bottom, rgb(238, 239, 240) 0%, white 100%)'
		: `${theme.palette.backgrounds?.neutral}`,
	borderRadius: '0.5rem',
	width: '100%',
	textAlign: 'center',
	'&.MuiGrid-item': {
		paddingLeft: 0,
		paddingTop: 0,
		padding: '26%',
	},
	justifyContent: 'center',
	alingItems: 'center',
});

export const imageItemStyles = (isSmallScreen: boolean, imageAsset: ImageAsset) => {
	/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
	const desktopImageUrl: string = imageAsset?.desktopImage?.image.cdn_url;
	const mobileImageUrl: string = imageAsset?.mobileImage?.image.cdn_url;
	/* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
	const imageToDisplay: string = isSmallScreen ? mobileImageUrl : desktopImageUrl;
	/* eslint-enable @typescript-eslint/no-unsafe-assignment */
	return {
		/* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
		backgroundImage: `url(${imageToDisplay})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		'&.MuiGrid-item': {
			paddingLeft: 0,
			paddingTop: 0,
			padding: '46%',
		},
		borderRadius: '0.5rem',
	};
};
