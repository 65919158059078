import { Box, IconButton, SwipeableDrawer, Typography, useTheme } from '@mui/material';

import ContentTypeRichText from '@/components/ContentTypeRichText';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import { Maybe } from '@/types';
import { MedicalService, ContentTypeRichTextContent } from '@/types/generated';

import {
	closeButtonStyles,
	contentStyles,
	getContainerStyles,
	getPaperProps,
	headlineStyles,
	wrapper,
} from './ServiceLineHeroMobileDrawer.styles';

export interface IServiceLineHeroMobileDrawer {
	closeDrawer: () => void;
	setDrawerOpen: (open: boolean) => void;
	isDrawerOpen: boolean;
	activeMedicalService: Maybe<MedicalService>;
}

export default function ServiceLineHeroMobileDrawer({
	closeDrawer,
	setDrawerOpen,
	isDrawerOpen,
	activeMedicalService,
}: IServiceLineHeroMobileDrawer) {
	const theme = useTheme();
	return (
		<Box data-test-id="section_service_line_hero_mobile_drawer" sx={wrapper}>
			<SwipeableDrawer
				anchor={'bottom'}
				open={isDrawerOpen}
				onClose={() => {
					closeDrawer();
				}}
				onOpen={() => {
					setDrawerOpen(true);
				}}
				variant="temporary"
				PaperProps={{
					sx: getPaperProps(theme),
				}}
			>
				{activeMedicalService && (
					<>
						<Box sx={getContainerStyles(theme)}>
							<TAGSvgIcon
								icon={activeMedicalService.iconName as IconName}
								size={32}
								fill="currentColor"
							/>
							<Typography
								color={'text.light'}
								variant="header2"
								data-test-id="text_medical_service_title"
								sx={headlineStyles}
							>
								{activeMedicalService?.title}
							</Typography>
							<IconButton onClick={closeDrawer} sx={closeButtonStyles}>
								<TAGSvgIcon icon="Times" size={28} />
							</IconButton>
						</Box>
						<Box sx={contentStyles}>
							<ContentTypeRichText
								key={activeMedicalService.sys.id}
								disableGutters
								disablePaddingOn={['Top', 'Bottom']}
								contentMaxWidth="100%"
								content={activeMedicalService.description as ContentTypeRichTextContent}
								dataTestId="section_service_line_hero_drawer"
								backgroundColor={'transparent'}
								docProps={{
									color: 'text.light',
									variant: 'bodyMediumBook',
								}}
							/>
						</Box>
					</>
				)}
			</SwipeableDrawer>
		</Box>
	);
}
