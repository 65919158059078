import { useEffect, useRef, useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TAGBackground } from '@aspendental/themes';

import { DisableTemplatePaddingOn } from '@/types';
import { Maybe, TextBlockSeo as TTextBlockSeo, TextBlockSeoContent } from '@/types/generated';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import { incorrectUsageWarning } from '@/utils/miscUtils';
import { resolveTemplatePadding } from '@/utils/resolveTemplatePadding';

import TemplateContainer from '../TemplateContainer';

import { wrapperStyles, getContentWrapperStyles, getExpandArrowHolderStyles } from './TextBlockSEO.styles';

export const OpacityGenerator = ({
	divHeight,
	backgroundColor,
	isOpen,
}: {
	divHeight: number;
	backgroundColor: TAGBackground;
	isOpen: boolean;
}) => {
	if (divHeight <= 150) return null;

	return (
		<>
			{[...Array<undefined>(5)].map((_, i) => {
				return (
					<Box
						key={i}
						sx={{
							backgroundColor: `backgrounds.${backgroundColor}`,
							height: `${(120 - (i + 1) * 20) / 16}rem`,
							display: !isOpen ? 'block' : 'none',
							marginTop: `-${(120 - (i + 1) * 20) / 16}rem`,
							opacity: `.${i + 1}`,
							width: '100%',
						}}
					/>
				);
			})}
		</>
	);
};

export interface ITextBlockSEO extends TTextBlockSeo {
	/**
	 * Specifies the background color of the template.
	 *
	 * @type {Maybe<TAGBackground>}
	 * @memberof ITextBlockSEO
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof ITextBlockSEO
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * Rich text content
	 *
	 * @type {TextBlockSeoContent}
	 * @memberof IMultiCard
	 */
	content?: Maybe<TextBlockSeoContent>;
}

export default function TextBlockSEO({ backgroundColor, disablePaddingOn = [], content }: ITextBlockSEO) {
	const section = useRef<HTMLDivElement>(null);

	const [divHeight, setDivHeight] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const theme = useTheme();
	const isMedium = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		if (section.current) setDivHeight(section.current.scrollHeight);
	}, []);

	if (!content) {
		incorrectUsageWarning('TextBlockSEO', 'content is required');
		return null;
	}

	return (
		<TemplateContainer
			containerSx={{ ...resolveTemplatePadding(disablePaddingOn, isMedium), wrapperStyles }}
			backgroundColor={backgroundColor as TAGBackground}
			dataTestId="text_block_wrapper"
		>
			<Box ref={section} sx={getContentWrapperStyles(isOpen)}>
				<ContentTypeRichText
					centerAlign
					disableGutters
					disablePaddingOn={['Top', 'Bottom']}
					content={content}
					contentMaxWidth="100%"
					backgroundColor="transparent"
					dataTestId="text_block_rich_text"
				/>
			</Box>

			<OpacityGenerator
				isOpen={isOpen}
				divHeight={divHeight}
				backgroundColor={backgroundColor as TAGBackground}
			/>

			<Box sx={getExpandArrowHolderStyles(isOpen, divHeight)}>
				<ExpandMoreIcon data-test-id="icon_expand" fontSize="large" onClick={() => setIsOpen(!isOpen)} />
			</Box>
		</TemplateContainer>
	);
}
