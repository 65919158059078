import { create } from 'zustand';

import { OfferDTO } from '@/rest/__generated/revspringApi';

export interface SlotsState {
	selectedTimeSlot: OfferDTO | null;
	isGetInLineSelected: boolean;
}

export interface SlotsActions {
	setSelectedTimeSlot: (selectedTimeSlot: OfferDTO | null) => void;
	setGetInLineSelected: (isGetInLineSelected: boolean) => void;
}
// Combining state and actions for the store
type SlotsStore = SlotsState & SlotsActions;

const useSlotsStore = create<SlotsStore>((set) => ({
	selectedTimeSlot: null,
	setSelectedTimeSlot: (selectedTimeSlot: OfferDTO | null) => set({ selectedTimeSlot }),
	isGetInLineSelected: false,
	setGetInLineSelected: (isGetInLineSelected: boolean) => set({ isGetInLineSelected }),
}));

export default useSlotsStore;
