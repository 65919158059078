import { Session } from '@/rest/__generated/revspringApi';
import { httpClient } from '@/utils';
import { logError, logWarning } from '@/utils/miscUtils';

export default async function getSession(revspringAccountId: string): Promise<void> {
	try {
		const session = await httpClient.request<Session>({
			url: `Session`,
			requestName: 'getSession',
			method: 'POST',
			params: { accountId: revspringAccountId },
		});
		if (!session) {
			throw new Error('Could not find session');
		}

		if (!session.jwt || !session.sid) {
			throw new Error('Could not find JWT or SID');
		}
		const { jwt, sid } = session;
		httpClient.updateDefaultHeaders({ ssId: sid, tokenStr: jwt });
	} catch (err) {
		logWarning(`Could not fetch session.`);
		if (err instanceof Error) {
			logError(`REVSPRING_SESSION_FAILURE: ${err.message}`);
		}
	}
}
