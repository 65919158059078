import { logWarning, logError } from '@/utils/miscUtils';
import { httpClient } from '@/utils';
import { Slot } from '@/rest/__generated/revspringApi';
import { Maybe } from '@/types';

interface IMessage {
	[key: string]: Maybe<string | boolean | null | undefined>;
	IsSuccessStatusCode: boolean;
}

export default async function getSlots(data: {
	revspringLocationValue: string;
	revspringAccountId: string;
	apptType: string;
	startDate: string;
	endDate: string;
}) {
	try {
		const { revspringAccountId: accountId, revspringLocationValue: location, ...rest } = data;
		const slots = await httpClient.request<Slot>({
			url: `Slots`,
			requestName: 'getSlots',
			method: 'POST',
			data: {
				accountId,
				location,
				...rest,
			},
		});

		const message = JSON.parse(slots?.message || '{}') as IMessage;

		return { data: slots, isFailed: message.IsSuccessStatusCode === false };
	} catch (err) {
		logWarning(`Could not fetch slots`);
		if (err instanceof Error) {
			logError(`REVSPRING_SLOTS_FAILURE: ${err.message}`);
		}
		return { data: null, isFailed: true };
	}
}
