import { useEffect, useState } from 'react';

import { Box, Grid, Typography, Chip, useTheme, useMediaQuery } from '@mui/material';
import dynamic from 'next/dynamic';

import { OfferDTO } from '@/rest/__generated/revspringApi';
import { useLocationDetailsStore, useReasonForVisitStore, useRevSpringStore, useSlotsStore } from '@/store';
import { Asset } from '@/types/generated';
import { dataLayerConfig, groupBy, formatUsingTZ, supportedTZs } from '@/utils';
import getPeopleInLine, { defaultProvidersLineLength, type ProvidersLineLength } from '@/services/getPeopleInLine';
import getSlots from '@/services/getSlots';

import LocationHeading from '../LocationHeading';
import VirtualCareNow from '../VirtualCareNow';
import NearbyLocations from '../NearbyLocations';
import SchedulingError from '../SchedulingError';

import { noSlotsAtAllMsgRT, noSlotsTodayMsgRT, noSlotsTomorrowMsgRT } from './TimeSlotSelector.data';

const NoSlotsAlert = dynamic(() => import('./SlotList/SlotList').then((module) => module.NoSlotsAlert), { ssr: false });

const SlotList = dynamic(() => import('./SlotList/SlotList').then((module) => module.default), { ssr: false });

export interface ITimeSlotSelector {
	locationCardMedia: Asset;
	dayOneLabel: string;
	dayOneDateTime: string;
	dayTwoLabel: string;
	dayTwoDateTime: string;
}

export const NoSlotsAtAll = () => (
	<>
		<Typography variant="header3" color="text.primary" data-test-id="headline_no_timeslots_message">
			Walk ins always welcome
		</Typography>
		<NoSlotsAlert msg={noSlotsAtAllMsgRT} />
	</>
);

export default function TimeSlotSelector({
	locationCardMedia,
	dayOneLabel,
	dayOneDateTime,
	dayTwoLabel,
	dayTwoDateTime,
}: ITimeSlotSelector) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const reasonForVisit = useReasonForVisitStore((store) => store.reasonForVisit);
	const selectedTimeSlot = useSlotsStore((store) => store.selectedTimeSlot);
	const setSelectedTimeSlot = useSlotsStore((store) => store.setSelectedTimeSlot);
	const isGetInLineSelected = useSlotsStore((store) => store.isGetInLineSelected);
	const setGetInLineSelected = useSlotsStore((store) => store.setGetInLineSelected);
	const revspringLocationValue = useRevSpringStore((store) => store.revspringLocationValue);
	const revspringAccountId = useRevSpringStore((store) => store.revspringAccountId);
	const locationDetails = useLocationDetailsStore((store) => store);
	const [isAutobookEligible, setAutobookEligible] = useState(false);
	const [peopleInLine, setPeopleInLine] = useState<ProvidersLineLength>(defaultProvidersLineLength);
	const [peopleInLineLoading, setPeopleInLineLoading] = useState(false);
	const [showPeopleInLine, setShowPeopleInLine] = useState(false);

	const dayOneDateStr = formatUsingTZ(dayOneDateTime, supportedTZs[locationDetails.timezone], 'yyyy-MM-dd');
	const dayTwoDateStr = formatUsingTZ(dayTwoDateTime, supportedTZs[locationDetails.timezone], 'yyyy-MM-dd');

	const [groupedTimeSlots, setGroupedTimeSlots] = useState<Record<string, Array<OfferDTO>>>({});
	const [isTimeSlotsFailed, setTimeSlotsFailed] = useState(false);
	const [isTimeSlotsLoading, setTimeSlotsLoading] = useState(true);

	const handleChipClick = ({ timeSlot, label, title }: { timeSlot: OfferDTO; label: string; title: string }) => {
		if (selectedTimeSlot?.offerDateTimeUTC === timeSlot.offerDateTimeUTC) {
			setSelectedTimeSlot(null);
			return;
		}

		dataLayerConfig({
			event: 'saa_flow',
			step_name: 'times',
			brand: 'WellNow',
			clinic_city: locationDetails.city,
			clinic_location: locationDetails.address,
			clinic_state: locationDetails.state,
			appointment_date: title,
			appointment_time: label,
		});
		setSelectedTimeSlot(timeSlot);
		setGetInLineSelected(false);
	};

	useEffect(() => {
		const defaultGroupedTimeSlots = {
			[dayOneDateStr]: [],
			[dayTwoDateStr]: [],
		};

		async function fetchData() {
			const { data: timeSlotsData, isFailed } = await getSlots({
				revspringLocationValue,
				revspringAccountId,
				apptType: reasonForVisit,
				startDate: dayOneDateStr,
				endDate: dayTwoDateStr,
			});
			setTimeSlotsLoading(false);
			setTimeSlotsFailed(isFailed);

			if (!timeSlotsData) {
				return;
			}

			setGroupedTimeSlots(
				groupBy(
					(ts: OfferDTO) =>
						formatUsingTZ(
							ts?.offerDateTimeUTC ?? '2010-01-01T00:00:00Z',
							locationDetails.timezone,
							'yyyy-MM-dd'
						),
					(timeSlotsData?.offerDTOs || [])
						.filter((timeSlot) => timeSlot.offerDateTimeUTC && timeSlot.offerDateTimeUTC !== null)
						.sort((a, b) => {
							const left = b?.offerDateTimeUTC ?? '0';
							const right = a?.offerDateTimeUTC ?? '0';
							return left < right ? 1 : left > right ? -1 : 0;
						}),
					defaultGroupedTimeSlots
				)
			);

			if (timeSlotsData.autobookEligible) {
				setAutobookEligible(true);
			}
		}

		void fetchData();
	}, [
		dayOneDateStr,
		dayTwoDateStr,
		locationDetails.timezone,
		reasonForVisit,
		revspringAccountId,
		revspringLocationValue,
	]);

	useEffect(() => {
		async function fetchData() {
			if (isAutobookEligible) {
				setPeopleInLineLoading(true);
				const peopleInLineData = await getPeopleInLine(revspringAccountId);
				setPeopleInLineLoading(false);

				setPeopleInLine(peopleInLineData);
				setShowPeopleInLine(peopleInLine.Onsite >= 0 && peopleInLine.Onsite <= 8);
			}
		}

		void fetchData();
	}, [isAutobookEligible, peopleInLine, revspringAccountId]);

	if (isTimeSlotsFailed) return <SchedulingError />;

	return (
		<Grid
			item
			container
			direction="column"
			rowGap={{ xs: 2.5, md: 3 }}
			alignContent="stretch"
			data-test-id="section_time_slot_selector"
		>
			<Grid item data-test-id="grid_item_location_heading">
				<LocationHeading
					type={locationDetails.displayName}
					name={locationDetails.city}
					address={`${locationDetails.address}, ${locationDetails.city}, ${locationDetails.state} ${locationDetails.zip}`}
				/>
			</Grid>

			{!isTimeSlotsLoading &&
			!groupedTimeSlots?.[dayOneDateStr]?.length &&
			!groupedTimeSlots?.[dayTwoDateStr]?.length ? (
				<NoSlotsAtAll data-test-id="grid_item_no_timeslots_message" />
			) : (
				<>
					<Grid item data-test-id="grid_item_timeslots_message">
						<Typography variant="header3" color="text.primary">
							Available Times
						</Typography>
						<Typography variant="bodyMediumBook" color="text.secondary">
							Local time of location is displayed
						</Typography>
					</Grid>

					<SlotList
						title={dayOneLabel}
						swiperId={dayOneLabel}
						slots={groupedTimeSlots?.[dayOneDateStr] || []}
						timezone={locationDetails.timezone}
						loading={isTimeSlotsLoading}
						selectedTime={selectedTimeSlot?.offerDateTimeUTC || ''}
						onMemberClick={handleChipClick}
						isSmallScreen={isSmallScreen}
						noSlotsMsg={noSlotsTodayMsgRT}
						data-test-id="grid_item_timeslots_list"
					/>

					<SlotList
						title={dayTwoLabel}
						swiperId={dayTwoLabel}
						slots={groupedTimeSlots?.[dayTwoDateStr] || []}
						timezone={locationDetails.timezone}
						loading={isTimeSlotsLoading}
						selectedTime={selectedTimeSlot?.offerDateTimeUTC || ''}
						onMemberClick={handleChipClick}
						isSmallScreen={isSmallScreen}
						noSlotsMsg={noSlotsTomorrowMsgRT}
						data-test-id="grid_item_timeslots_list"
					/>
				</>
			)}

			{isAutobookEligible && !peopleInLineLoading && (
				<Grid item>
					<Box sx={{ display: 'flex', mb: 1, gap: '.4rem' }}>
						<Typography variant="header4" color="text.primary">
							<span>Walk in now</span>
							{showPeopleInLine && <span>:</span>}
						</Typography>
						{showPeopleInLine && (
							<Typography variant="bodyLargeBook" color="success.dark">
								{peopleInLine.Onsite} people in line
							</Typography>
						)}
					</Box>
					<Grid item>
						<Chip
							label={'Get in line'}
							variant={isGetInLineSelected ? 'selected' : 'default'}
							clickable
							onClick={() => {
								dataLayerConfig({
									event: 'saa_flow',
									step_name: 'times',
									brand: 'WellNow',
									clinic_city: locationDetails.city,
									clinic_location: locationDetails.address,
									clinic_state: locationDetails.state,
									appointment_date: 'Walk-in now',
									click_text: 'Get in line',
								});
								setGetInLineSelected(true);
								setSelectedTimeSlot(null);
							}}
						/>
					</Grid>
				</Grid>
			)}
			<Grid item data-test-id="grid_item_virtual_care_now" sx={{ marginBottom: 2.5 }}>
				{reasonForVisit === 'Illness / Injury' && (
					<VirtualCareNow
						alternateHeadline
						virtualCareDataLayer={{
							clinic_city: locationDetails.city,
							clinic_location: locationDetails.address,
							clinic_state: locationDetails.state,
						}}
					/>
				)}
			</Grid>
			<NearbyLocations locationCardMedia={locationCardMedia} />
		</Grid>
	);
}
