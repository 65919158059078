import { Box, SvgIcon } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

import { ratingArrayBoxColor, ratingBoxStyles, ratingIconSize } from './BirdEyeReviews.styles';

export default function Rating({ rating }: { rating: number }) {
	const ratingString = rating.toString();
	const arr = ratingString.split('.').map(Number);
	let full = arr[0];
	const half = arr[1];

	if (half >= 6) {
		full++;
	}

	return (
		<Box style={ratingBoxStyles} data-test-id={'rating_box'}>
			{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
			{[...Array(+full)].map((_, i) => (
				<Box key={i} sx={ratingArrayBoxColor}>
					<SvgIcon sx={ratingIconSize}>
						<StarIcon sx={ratingIconSize} />
					</SvgIcon>
				</Box>
			))}
			{half > 2 && half < 6 && (
				<Box data-test-id={'half_rating_box'} sx={ratingArrayBoxColor}>
					<SvgIcon sx={ratingIconSize}>
						<StarHalfIcon sx={ratingIconSize} />
					</SvgIcon>
				</Box>
			)}
		</Box>
	);
}
