import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

import { BookedAppointment } from './setSlotBook';

export default async function setSlotAutobook(data: {
	revspringLocationValue: string;
	revspringAccountId: string;
	apptType: string;
	patientId: string;
}): Promise<BookedAppointment> {
	try {
		const { revspringAccountId: accountId, revspringLocationValue: location, ...rest } = data;
		const {
			status,
			appointmentDTO: { vendorAppointmentId = '', appointmentDateTime = '' },
		} = await httpClient.request<BookedAppointment>({
			url: `Slots/autobook`,
			requestName: 'setSlotAutobook',
			method: 'POST',
			data: {
				createdByApp: 'wellnow-webapp',
				accountId,
				location,
				...rest,
			},
		});

		return {
			status: status === 'BOOKED' ? 'success' : 'error',
			appointmentDTO: { vendorAppointmentId, appointmentDateTime },
		};
	} catch (err) {
		logWarning(`Could not use autobook`);
		if (err instanceof Error) {
			logError(`REVSPRING_SLOTS_AUTOBOOK_FAILURE: ${err.message}`);
		}
		return {
			status: 'error',
			appointmentDTO: { vendorAppointmentId: '0', appointmentDateTime: '2010-01-01T00:00:00Z' },
		};
	}
}
