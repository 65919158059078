import { Fragment } from 'react';

import { Box, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';

import ContentTypeRichText from '@/components/ContentTypeRichText';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import { Maybe, ProductFeatureDescriptionListItem } from '@/types/generated';

import { IDescriptionList } from '../ProductFeature.type';

import { listItemWrapperStyles, listWrapperStyles } from './DescriptionList.styles';

export default function DescriptionList({ descriptionListCollection }: IDescriptionList) {
	const theme = useTheme();
	const isSmall: boolean = useMediaQuery(theme.breakpoints.down('md'));

	const descriptionList = descriptionListCollection?.items;

	if (!descriptionList?.length) {
		return null;
	}

	return (
		<List data-test-id="product_feature_description_list" sx={listWrapperStyles}>
			{descriptionList.map((listItem: Maybe<ProductFeatureDescriptionListItem>, index: number) => (
				<Fragment key={`product_description_fragment_${index}`}>
					<ListItem
						sx={{ ...listItemWrapperStyles(theme) }}
						data-test-id={`product_description_list_item_${index}`}
					>
						{listItem?.icon && (
							<Box data-test-id={`product_description_list_item_icon_box_${index}`}>
								<TAGSvgIcon
									dataTestId={`product_description_list_item_icon_${index}`}
									size={48}
									icon={listItem?.icon as IconName}
								/>
							</Box>
						)}
						<ListItemText>
							<ContentTypeRichText
								disableGutters
								content={listItem?.content?.content}
								dataTestId={`product_description_list_item_richtext_${index}`}
								contentMaxWidth="100%"
								backgroundColor="transparent"
								disablePaddingOn={['Top', 'Bottom']}
								docProps={{
									variant: isSmall ? 'bodyMediumBook' : 'bodyLargeBook',
								}}
							/>
						</ListItemText>
					</ListItem>
				</Fragment>
			))}
		</List>
	);
}
