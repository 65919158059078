import { Maybe } from '@/types';

import { resolveContentAlignment } from './PatientReviews.helpers';

export const getTitleContainerStyles = (contentAlign?: Maybe<string>) => ({
	alignItems: resolveContentAlignment(contentAlign, false),
	justifyContent: 'space-between',
	gap: '0.75rem',
});

export const buttonContainerStyles = {
	flexDirection: 'row',
	justifyContent: 'center',
	spacing: '0.25rem',
};

export const getContainerStyles = (contentAlign?: Maybe<string>) => ({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	alignItems: resolveContentAlignment(contentAlign, false),
	justifyContent: 'space-between',
	gap: '2rem',
	margin: '0 auto',
	paddingX: { xs: '1.25rem', md: '2%', lg: '5%' },
	paddingY: { xs: '2rem', md: '3rem' },
});
