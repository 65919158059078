import { Maybe, Asset } from '@/types/generated';
import ImageWrapper from '@/components/ImageWrapper';
import AspectRatioPaddingBox from '@/components/AspectRatioPaddingBox';
import VideoContent from '@/components/VideoContent';
import YouTubeVideoEmbed from '@/components/YouTubeVideoEmbed';

import { resolveImageAspectRatio, ServiceMediaType } from '../Service.helpers';
import { resolveImageLayoutStyles } from '../Service.styles';

import { videoWrapperStyles, getImageWrapperStyles } from './ServiceMedia.styles';

export interface IServiceMediaProps {
	direction?: Maybe<string>;
	image?: Maybe<Asset>;
	imageLayout?: Maybe<string>;
	isSmallScreen: boolean;
	mediaType?: Maybe<ServiceMediaType>;
	mobileImage?: Maybe<Asset>;
	video?: Maybe<Asset>;
	videoImage?: Maybe<Asset>;
	youTubeVideoId?: Maybe<string>;
	youTubeVideoImage?: Maybe<Asset>;
	shouldDisplayMediaFirst: boolean;
	sixteenByNineVideoRatio?: boolean;
}

function resolveVideoUrl({ image, mobileImage, mediaType, videoImage, isSmallScreen }: Partial<IServiceMediaProps>) {
	if (isSmallScreen) {
		const imageUrl = mobileImage?.url || image?.url;

		if (mediaType === ServiceMediaType.Video) return videoImage?.url || imageUrl;

		return imageUrl;
	}

	return image?.url as string;
}

export default function ServiceMedia({
	image,
	imageLayout,
	isSmallScreen,
	mediaType,
	mobileImage,
	video,
	videoImage,
	youTubeVideoId,
	youTubeVideoImage,
	shouldDisplayMediaFirst,
	sixteenByNineVideoRatio,
}: IServiceMediaProps) {
	if (!mediaType) return null;

	const imageWrapperSx = getImageWrapperStyles(shouldDisplayMediaFirst);

	if (mediaType === ServiceMediaType.Image || (mediaType === ServiceMediaType.Video && isSmallScreen)) {
		const imageUrl = resolveVideoUrl({ image, mobileImage, isSmallScreen, videoImage, mediaType });

		return (
			<AspectRatioPaddingBox
				aspectRatio={resolveImageAspectRatio(imageLayout)}
				placeholderColor="transparent"
				imageWrapperSx={imageWrapperSx}
			>
				<ImageWrapper
					src={imageUrl}
					alt={image?.description ?? ''}
					data-test-id="image_service_media"
					style={{
						...resolveImageLayoutStyles(imageLayout),
						objectFit: 'cover',
						width: '100%',
					}}
				/>
			</AspectRatioPaddingBox>
		);
	}

	if (mediaType === ServiceMediaType.Video) {
		return (
			<AspectRatioPaddingBox aspectRatio="16:9" placeholderColor="transparent" imageWrapperSx={imageWrapperSx}>
				<VideoContent
					video={video}
					posterImage={videoImage}
					videoElSx={videoWrapperStyles}
					dataTestId="video_service_media"
				>
					<track src={video?.url as string} kind="captions" srcLang="en" label="english_captions"></track>
				</VideoContent>
			</AspectRatioPaddingBox>
		);
	}

	if (mediaType === ServiceMediaType.YouTubeVideo && youTubeVideoId) {
		return (
			<AspectRatioPaddingBox aspectRatio="16:9" placeholderColor="transparent" imageWrapperSx={imageWrapperSx}>
				<YouTubeVideoEmbed
					youTubeVideoId={youTubeVideoId}
					isSmallScreen={isSmallScreen}
					youTubeVideoImage={youTubeVideoImage}
					isBorderRadius={true}
					sixteenByNineVideoRatio={sixteenByNineVideoRatio}
				/>
			</AspectRatioPaddingBox>
		);
	}

	return null;
}
