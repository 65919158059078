import { Dispatch, SetStateAction } from 'react';

import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material';

import { SortDown, SortUp } from '@/assets/SVG/functionalIcon';
import TAGButton from '@/components/TAGButton';

import { getDividerStyles, getInsuranceListItemStyles, getInsuranceListStyles } from './InsuranceList.style';

export interface IInsuranceListProps {
	insuranceList: { name: string }[];
	expanded?: boolean;
	showMore?: boolean;
	withDivider?: boolean;
	setExpanded?: Dispatch<SetStateAction<boolean>>;
}

const InsuranceList = ({
	insuranceList,
	expanded = false,
	showMore = false,
	setExpanded = () => undefined,
	withDivider = true,
}: IInsuranceListProps) => {
	if (!insuranceList) {
		return null;
	}
	return (
		<Box gap={2} data-test-id="section_insurance_list">
			{insuranceList?.length > 0 && (
				<>
					{withDivider && <Divider sx={getDividerStyles} />}
					<List sx={getInsuranceListStyles(showMore, expanded)}>
						{insuranceList.map((insurance) => (
							<ListItem
								key={insurance.name}
								sx={getInsuranceListItemStyles}
								data-test-id="section_insurance_listItem"
							>
								<Typography variant="bodyMediumSemiBold" color="text.tertiary">
									{insurance.name}{' '}
								</Typography>
							</ListItem>
						))}
					</List>

					{showMore && (
						<Grid container justifyContent="center" alignItems="center">
							<Grid item>
								<TAGButton
									size="medium"
									onClick={() => setExpanded(!expanded)}
									endIcon={expanded ? <SortDown /> : <SortUp />}
									dataTestId="section_insurance_showmore"
								>
									{expanded ? 'Close' : 'Show more'}
								</TAGButton>
							</Grid>
						</Grid>
					)}
				</>
			)}
		</Box>
	);
};

export default InsuranceList;
