import { Grid, useTheme, Skeleton, Box } from '@mui/material';

import { dataLayerConfig } from '@/utils';
import { useReasonForVisitStore } from '@/store';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import TAGButton from '@/components/TAGButton';

import { getButtonStyles } from './VisitTypeButtons.styles';
export interface IVisitTypeButtons {
	accountId?: string;
	postClickAction?: () => void;
	buttons?: Array<VisitButton>;
	loading?: boolean;
}

export interface VisitButton {
	visitType: string;
	label: string;
	icon?: IconName;
	href?: string | null;
}

const LoadingSkeleton = () => (
	<Grid container direction={'column'} data-test-id="loading-skeleton">
		{Array.from({ length: 4 }).map((item, index) => (
			<Box key={index} sx={{ marginRight: 0.5, my: 0.5 }}>
				<Skeleton height={58} />
			</Box>
		))}
	</Grid>
);
export const visitOptions: Array<VisitButton> = [
	{
		visitType: 'Illness / Injury',
		label: 'Illness or Injury',
		icon: 'Illnesses',
	},
	{
		visitType: 'Occupational Medicine / Employer Services',
		label: 'Occupational Medicine',
		icon: 'OccMed',
	},
	{
		visitType: 'School / Sport Physical',
		label: 'School & Sport (Physical)',
		icon: 'Physicals',
	},
	{
		visitType: 'Workers Compensation',
		label: 'Workers Compensation',
		icon: 'UrgentCare',
	},
	{
		visitType: 'NO-FAULT',
		label: 'No Fault or Auto Accident',
		icon: 'Injury',
	},
];

export const externalRevSpringOptions: Array<string> = [
	'Occupational Medicine / Employer Services',
	'School / Sport Physical',
];

export default function VisitTypeButtons({
	postClickAction,
	accountId = '',
	buttons = [],
	loading = true,
}: IVisitTypeButtons) {
	const theme = useTheme();
	const setReasonForVisit = useReasonForVisitStore((store) => store.setReasonForVisit);
	const reasonForVisit = useReasonForVisitStore((store) => store.reasonForVisit);

	const setReason = (reason: string) => {
		if (reasonForVisit !== reason) {
			setReasonForVisit(reason);
		}
		if (postClickAction && typeof postClickAction === 'function') {
			dataLayerConfig({
				event: 'schedule_start',
				brand: 'WellNow',
				reason_for_visit: reason,
			});

			postClickAction();
		}
	};

	if (loading) return <LoadingSkeleton />;

	return (
		<Grid data-test-id="section_visit_type_buttons" container direction={'column'}>
			{buttons?.length > 0 &&
				buttons?.map((visitButton, index) => (
					<Grid
						item
						key={`visit-type-button-grid-item-${index}`}
						data-test-id={`visit_type_grid_item_${index}`}
						mb={index < buttons?.length - 1 ? 1 : 0}
					>
						{visitButton.visitType === 'ExternalSource' ? (
							<TAGButton
								fullWidth
								variant="primaryHC"
								size="M"
								dataTestId={`button_visit_type_${index}`}
								startIcon={
									visitButton?.icon ? (
										<TAGSvgIcon
											icon={visitButton?.icon}
											size={24}
											fill={theme.palette.primary.main}
										/>
									) : null
								}
								endIcon={<TAGSvgIcon icon={'ChevronRightFunc'} size={24} />}
								sx={getButtonStyles}
								href={visitButton.href ?? '/'}
								target="_blank"
							>
								{visitButton?.label}
							</TAGButton>
						) : externalRevSpringOptions.indexOf(visitButton?.visitType) > -1 ? (
							<TAGButton
								fullWidth
								variant="primaryHC"
								size="M"
								dataTestId={`button_visit_type_${index}`}
								startIcon={
									visitButton?.icon ? (
										<TAGSvgIcon
											icon={visitButton?.icon}
											size={24}
											fill={theme.palette.primary.main}
										/>
									) : null
								}
								endIcon={<TAGSvgIcon icon={'ChevronRightFunc'} size={24} />}
								sx={getButtonStyles}
								href={`https://visit.wellnow.com/static/wellnow?accountId=${accountId}`}
							>
								{visitButton?.label}
							</TAGButton>
						) : (
							<TAGButton
								fullWidth
								variant="primaryHC"
								size="M"
								dataTestId={`button_visit_type_${index}`}
								startIcon={
									visitButton?.icon ? (
										<TAGSvgIcon
											icon={visitButton?.icon}
											size={24}
											fill={theme.palette.primary.main}
										/>
									) : null
								}
								endIcon={<TAGSvgIcon icon={'ChevronRightFunc'} size={24} />}
								sx={getButtonStyles}
								onClick={() => setReason(visitButton?.visitType)}
							>
								{visitButton?.label}
							</TAGButton>
						)}
					</Grid>
				))}
		</Grid>
	);
}
