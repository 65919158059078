import { SimplePaletteOptions } from '@mui/material/styles';

import { ContentfulPageThemeOptions } from '../types';

export function resolvePageThemePalette(
	pageTheme: ContentfulPageThemeOptions,
	palette: Partial<SimplePaletteOptions>
): Partial<SimplePaletteOptions> {
	switch (pageTheme) {
		case 'Virtual Care':
			return {
				primary: {
					...palette?.accent02,
				},
				secondary: {
					...palette?.accent02,
				},
				text: {
					...palette?.text,
				},
				backgrounds: {
					...palette?.accent02,
					default: palette?.accent02?.lightest,
				},
			};
		case 'Allergy':
			return {
				primary: {
					...palette?.tertiary,
				},
				secondary: {
					...palette?.tertiary,
				},
				text: {
					...palette?.text,
				},
				backgrounds: {
					...palette?.tertiary,
					default: palette?.tertiary?.lightest,
				},
			};
		case 'Occupational Medicine':
			return {
				primary: {
					...palette?.accent01,
				},
				secondary: {
					...palette?.accent01,
				},
				text: {
					...palette?.text,
					light: palette?.text?.secondary,
				},
				backgrounds: {
					...palette?.accent01,
					default: palette?.accent01?.lightest,
				},
			};
		default:
			return {
				primary: {
					...palette?.primary,
				},
				secondary: {
					...palette?.primary,
				},
				text: {
					...palette?.text,
				},
				backgrounds: {
					...palette?.primary,
					default: palette?.primary?.lightest,
				},
			};
	}
}
