import React, { FC } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import { Maybe } from '@/types/generated';
import { useOfficeLocationContext } from '@/context';
import { textToJumpLink } from '@/utils';
import { TAGContentAlignment, TContentMaxWidthSize } from '@/types';

import { getGridContainerWrapStyles, getGridContainerStyles, getTypographyStyles } from './Section.styles';

export interface IMeetOurStaffSection {
	children: React.ReactNode;
	description?: Maybe<string>;
	sectionBackground: TAGBackground | string;
	title: Maybe<string>;
	alignment?: TAGContentAlignment;
	contentMaxWidth?: TContentMaxWidthSize;
}

const MeetOurStaffSection: FC<IMeetOurStaffSection> = ({
	children,
	description,
	sectionBackground,
	title,
	alignment,
	contentMaxWidth = 'Medium',
}: IMeetOurStaffSection) => {
	const theme = useTheme();
	const isDark = sectionBackground === 'dark';
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const { officeInfo: legalCenterName } = useOfficeLocationContext();
	const centerName = legalCenterName?.legalBillingName;

	return (
		<Grid>
			<Grid item sx={getGridContainerWrapStyles(sectionBackground)}>
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					sx={getGridContainerStyles({ alignment, contentMaxWidth, isSmallScreen: isExtraSmallScreen })}
				>
					<Grid item>
						<Typography
							variant="header2"
							color={isDark ? 'text.light' : 'text.primary'}
							sx={getTypographyStyles({ alignment })}
							id={textToJumpLink(title)}
						>
							{title}
						</Typography>
					</Grid>

					{centerName && (
						<Grid item data-test-id="odp_hero_legal_name">
							<Typography
								data-test-id="odp_hero_legal_name_text"
								variant="bodyLargeBook"
								color={isDark ? 'text.light' : 'text.tertiary'}
								sx={getTypographyStyles({ alignment })}
							>
								{centerName}
							</Typography>
						</Grid>
					)}

					{description && (
						<Grid item data-test-id="meet_our_staff_description">
							<Typography
								variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
								color={isDark ? 'text.light' : 'text.secondary'}
								sx={getTypographyStyles({ alignment })}
							>
								{description}
							</Typography>
						</Grid>
					)}
				</Grid>
				{children}
			</Grid>
		</Grid>
	);
};

export default MeetOurStaffSection;
