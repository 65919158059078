import { Theme } from '@mui/material';

export const getContainerStyles = (theme: Theme) => ({
	position: 'absolute',
	right: '0',
	width: '50%',
	borderRadius: '5rem 0 0 5rem',
	backgroundColor: `${theme.palette.backgrounds?.main || theme.palette.backgrounds?.dark}`,
	height: '100%',
	overflow: 'auto',
	padding: '2rem',
});

export const getContentContainerStyles = (theme: Theme) => ({
	display: 'flex',
	padding: '0 1rem',
	justifyItems: 'end',
	alignItems: 'center',
	gap: '1rem',
	marginTop: '2rem',
	color: `${theme.palette.text?.light}`,
});

export const headlineStyles = {
	flexGrow: 1,
};

export const closeButtonStyles = {
	color: 'text.light',
};

export const contentStyles = { display: 'flex', padding: '1rem' };
