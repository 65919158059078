import { Grid, Typography } from '@mui/material';

import { Page } from '@/types/generated';
import { ComponentResolver } from '@/components/ComponentResolver';
interface INextPage {
	pageData: Page;
}

export default function NextPage({ pageData }: INextPage) {
	const { contentsCollection, pageTitle } = pageData;

	return (
		<>
			{pageTitle && (
				<Grid container alignItems="center" wrap="nowrap">
					<Typography variant="header1" color="primary" data-test-id="text_page_title">
						{pageTitle}
					</Typography>
				</Grid>
			)}

			{contentsCollection &&
				contentsCollection.items &&
				contentsCollection.items.map((content, idx) => {
					if (!content?.__typename || Object.keys(content).length <= 2) {
						console.warn(
							`⚠️⚠️⚠️ Shared Components Web - No content __typename found for ${
								content?.sys?.id || 'Unknown entryId'
							} ⚠️⚠️⚠️`
						);
						return null;
					}

					// @ts-ignore
					return (
						<ComponentResolver
							key={`${content.sys.id}-${content.__typename}-${idx}`}
							componentProps={content}
							typename={content.__typename}
						/>
					);
				})}
		</>
	);
}
