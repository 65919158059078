import { useEffect } from 'react';

import { Grid, Box, Typography, Skeleton } from '@mui/material';

import {
	useFormSectionStore,
	useLocationDetailsStore,
	usePatientInformationFormStore,
	useReasonForVisitStore,
	useSlotsStore,
} from '@/store';
import TAGButton from '@/components/TAGButton';
import { dataLayerConfig } from '@/utils';

import {
	reviewBoxesContainerStyle,
	reviewBoxTitleContainerStyles,
	headingContainerStyles,
} from './AppointmentReview.styles';
import { getReviewInfoBoxesArr } from './AppointmentReview.helpers';

const LoadingAppointmentReviewBox = () => <Skeleton variant="rounded" height={125} />;

interface IAppointmentReview {
	isLoading?: boolean;
}

export default function AppointmentReview({ isLoading }: IAppointmentReview) {
	const setActiveSection = useFormSectionStore((store) => store.setActiveSection);
	const reasonForVisit = useReasonForVisitStore((store) => store.reasonForVisit);
	const patientInformation = usePatientInformationFormStore((store) => store);
	const { selectedTimeSlot, isGetInLineSelected } = useSlotsStore((store) => store);
	const locationDetails = useLocationDetailsStore((store) => store);

	const reviewInformationBoxesArr = getReviewInfoBoxesArr(
		patientInformation,
		reasonForVisit,
		selectedTimeSlot ?? {},
		isGetInLineSelected,
		locationDetails,
		setActiveSection
	);

	useEffect(() => {
		dataLayerConfig({
			event: 'saa_flow',
			step_name: 'review',
			brand: 'WellNow',
		});
	}, []);

	return (
		<Grid
			item
			container
			direction={'column'}
			rowGap={{ xs: 2.5, md: 5 }}
			alignContent={'stretch'}
			data-test-id={'section_appointment_review'}
		>
			<Grid item data-test-id={'heading_grid_item_appointment_review'} sx={headingContainerStyles}>
				<Typography variant="header2">Review & Confirm Appointment</Typography>
				<Typography variant="bodyLargeBook" component={'p'}>
					Click ‘Confirm’ to complete your scheduling
				</Typography>
			</Grid>

			<Grid container item spacing={{ xs: 1, md: 1.5 }} data-test-id="grid_appointment_review">
				{reviewInformationBoxesArr.map((box, index) => (
					<Grid key={index} item xs={12} md={6} data-test-id="grid_item_appointment_review">
						{isLoading ? (
							<LoadingAppointmentReviewBox />
						) : (
							<Box sx={reviewBoxesContainerStyle}>
								<Box sx={reviewBoxTitleContainerStyles} data-test-id="title_box_appointment_review">
									<Typography variant="header4" color="text.primary">
										{box.title}
									</Typography>
									<TAGButton
										variant="tertiaryDefault"
										size="S"
										onClick={box.editSectionLink}
										dataTestId="edit_button_appointment_review"
									>
										Edit
									</TAGButton>
								</Box>

								<Box data-test-id="body_box_appointment_review">
									{Object.entries(box.body).map(([fieldName, fieldValue]) => (
										<Typography
											key={fieldName}
											variant={
												fieldName === 'address2' || fieldName === 'address3'
													? 'bodyMediumSemiBold'
													: fieldName === 'caption'
													? 'bodyMediumBook'
													: 'bodyMediumBold'
											}
											color="text.secondary"
											component={'p'}
										>
											{fieldValue}
										</Typography>
									))}
								</Box>
							</Box>
						)}
					</Grid>
				))}
			</Grid>
		</Grid>
	);
}
