import { useTheme, Typography, Box } from '@mui/material';

import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';

import { IHeading } from '../ProductFeature.type';

import { getEyebrowStyles, getHeadingStyles, headingWrapperStyles } from './Heading.styles';

export default function Heading({ eyebrowText, eyebrowHtag = 'h4', headingText, headingHtag = 'h1' }: IHeading) {
	const theme = useTheme();

	return (
		<Box data-test-id="product_feature_heading_box" sx={headingWrapperStyles}>
			{eyebrowText && (
				<Typography
					data-test-id="product_feature_eyebrow_text"
					variant={getHeadingVariant(eyebrowHtag)}
					sx={getEyebrowStyles(theme)}
					component={eyebrowHtag === 'normal' ? 'p' : eyebrowHtag}
				>
					{eyebrowText}
				</Typography>
			)}
			<Typography
				data-test-id="product_feature_heading_text"
				variant={getHeadingVariant(headingHtag)}
				sx={getHeadingStyles(theme)}
				component={headingHtag === 'normal' ? 'p' : headingHtag}
			>
				{headingText}
			</Typography>
		</Box>
	);
}
