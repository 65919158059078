import { Theme } from '@mui/material';

export const getGridContainerSectionStyles = (isSmallScreen: boolean) => ({
	position: 'relative',
	height: isSmallScreen ? 'initial' : 'calc(100vh - 120px)',
	maxWidth: '1920px',
	margin: '0 auto',
	overflow: isSmallScreen ? 'initial' : 'hidden',
});

export const getGridContainerLeftSideStyles = (isSmallScreen: boolean) => ({
	position: 'relative',
	padding: isSmallScreen ? '2.5rem 1.25rem' : '3.75rem 4.5rem 21.25rem 4.5rem',
	height: isSmallScreen ? 'initial' : '100%',
});

export const getGridContainerRightSideStyles = (isSmallScreen: boolean, activeSection: string) => ({
	paddingBottom: '94px',
	flexWrap: 'noWrap',
	height: isSmallScreen && activeSection !== 'VisitTypeSelector' ? 'calc(100vh - 160px)' : '100%',
	...(isSmallScreen ? { overflowY: 'scroll' } : { overflow: 'auto' }),
});

export const getButtonsContainerStyles = (theme: Theme, showBackButton: boolean) => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	padding: '20px',
	justifyContent: showBackButton ? 'space-between' : 'flex-end',
	boxShadow: '0px -4px 20px 0px rgba(0, 0, 0, 0.06)',
	backgroundColor: theme.palette.backgrounds?.white,
	zIndex: 1,
});

export const getGridContainerRightSideComponentsStyles = (isSmallScreen: boolean) => ({
	padding: isSmallScreen ? '2.5rem 1.25rem' : '3rem 2.5rem',
});
