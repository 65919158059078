import { forwardRef } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { Maybe, THeroContentWidth } from '@/types';
import {
	HeroV2Body,
	HeroV2Heading,
	HeroV2ButtonsCollection,
	ContentTypeRichTextContent,
	TermsAndConditions as TTermsAndConditions,
	SmallScreenContent,
	HeroV2PromoHeadline,
} from '@/types/generated';
import ContentTypeRichText from '@/components/ContentTypeRichText';
import TermsAndConditions from '@/components/TermsAndConditions';
import ContentfulButton from '@/components/ContentfulButton';

import {
	TACWrapperStyles,
	eyebrowStyles,
	getBodyContainerStyles,
	getInnerWrapperStyles,
	getWrapperStyles,
	getHeroButtonWrapperStyles,
	heroHeadingStyles,
	getPromoHeadlineSizeVariant,
	getHeroHeadingVariant,
	outerWrapperStyles,
} from './HeroContent.styles';
import { resolveEyebrowTextColor, resolveTACColor } from './HeroContent.helpers';

interface IHeroContent {
	backgroundColorSmallScreen?: Maybe<string>;
	body?: Maybe<HeroV2Body>;
	bodySize?: Maybe<string>;
	buttons?: HeroV2ButtonsCollection['items'];
	contentAlign?: Maybe<string>;
	contentPosition?: Maybe<string>;
	contentWidth?: Maybe<THeroContentWidth>;
	darkTextColor?: Maybe<boolean>;
	eyebrow?: Maybe<string>;
	eyebrowStyle?: Maybe<string>;
	hasVideoBackground: boolean;
	heading?: Maybe<HeroV2Heading>;
	headingSize?: Maybe<string>;
	isSmallScreen: boolean;
	mediaAspectRatioSmallScreen?: Maybe<string>;
	promoHeadline?: Maybe<HeroV2PromoHeadline>;
	promoHeadlineSize?: Maybe<string>;
	termsConditions?: Maybe<TTermsAndConditions>;
	contentSmallScreen?: Maybe<SmallScreenContent>;
	children?: React.ReactNode;
}

const HeroContent = forwardRef<HTMLDivElement, IHeroContent>(
	(
		{
			backgroundColorSmallScreen,
			body,
			bodySize,
			buttons,
			contentAlign,
			contentPosition,
			contentWidth = 'Medium',
			darkTextColor,
			eyebrow,
			eyebrowStyle,
			hasVideoBackground,
			heading,
			headingSize,
			isSmallScreen,
			mediaAspectRatioSmallScreen,
			promoHeadline,
			promoHeadlineSize,
			termsConditions,
			contentSmallScreen,
			children,
		},
		ref
	) => {
		const isDarkBackground = !!backgroundColorSmallScreen?.toLowerCase().includes('dark');

		const { bodySmallScreen, eyebrowSmallScreen, headingSmallScreen } = Object(
			contentSmallScreen
		) as SmallScreenContent;

		const eyebrowText = isSmallScreen && eyebrowSmallScreen ? eyebrowSmallScreen : eyebrow;
		const headingContent = isSmallScreen && headingSmallScreen ? headingSmallScreen : heading;
		const bodyContent = isSmallScreen && bodySmallScreen ? bodySmallScreen : body;

		const theme = useTheme();
		return (
			<Box
				data-test-id="section_hero_content"
				sx={getWrapperStyles({
					isSmallScreen,
					contentPosition,
					backgroundColorSmallScreen,
					theme,
					mediaAspectRatioSmallScreen,
				})}
			>
				{children}
				<Box
					sx={!isSmallScreen ? outerWrapperStyles(contentWidth) : null}
					ref={ref}
					data-test-id="hero_content_main_box"
				>
					<Box sx={getInnerWrapperStyles(isSmallScreen, contentAlign)} data-test-id="hero_content_inner_box">
						{eyebrow && (
							<Typography
								sx={eyebrowStyles}
								color={resolveEyebrowTextColor({
									isSmallScreen,
									isDarkBackground,
									darkTextColor,
									isPromoEyebrow: eyebrowStyle === 'Promo',
									mediaAspectRatioSmallScreen,
								})}
								data-test-id="text_hero_eyebrow"
								fontWeight={eyebrowStyle === 'Promo' ? 700 : 400}
								variant={eyebrowStyle === 'Promo' ? 'bodyXLBook' : 'bodyLargeBook'}
							>
								{eyebrowText}
							</Typography>
						)}
						{promoHeadline && (
							<ContentTypeRichText
								disableGutters
								disablePaddingOn={['Top', 'Bottom']}
								contentMaxWidth="100%"
								backgroundColor="transparent"
								dataTestId="text_promo_headline"
								centerAlign={isSmallScreen || contentAlign === 'Center'}
								content={promoHeadline as ContentTypeRichTextContent}
								containerSx={{ ...(contentAlign === 'Left' && { marginLeft: 0 }) }}
								docProps={{
									color: darkTextColor ? 'text.primary' : 'text.light',
									variant: getPromoHeadlineSizeVariant(promoHeadlineSize as string, isSmallScreen),
								}}
							/>
						)}

						{heading && (
							<ContentTypeRichText
								disableGutters
								disablePaddingOn={['Top', 'Bottom']}
								contentMaxWidth="100%"
								backgroundColor="transparent"
								dataTestId="group_hero_heading"
								containerSx={{
									...heroHeadingStyles,
									...(contentAlign === 'Left' && { marginLeft: 0 }),
								}}
								centerAlign={isSmallScreen || contentAlign === 'Center'}
								content={headingContent as ContentTypeRichTextContent}
								docProps={{
									color: darkTextColor ? 'text.primary' : 'text.light',
									variant: getHeroHeadingVariant(headingSize as string, isSmallScreen),
								}}
							/>
						)}

						{body && (
							<ContentTypeRichText
								disableGutters
								disablePaddingOn={['Top', 'Bottom']}
								contentMaxWidth="100%"
								dataTestId="group_hero_body"
								backgroundColor="transparent"
								centerAlign={isSmallScreen || contentAlign === 'Center'}
								content={bodyContent as ContentTypeRichTextContent}
								containerSx={{
									...getBodyContainerStyles(contentAlign || 'center', !!darkTextColor, isSmallScreen),
									...(contentAlign === 'Left' && { marginLeft: 0 }),
								}}
								docProps={{
									color: darkTextColor ? 'text.secondary' : 'text.light',
									variant: isSmallScreen
										? 'bodyMediumBook'
										: bodySize === 'Medium'
										? 'bodyLargeBook'
										: 'bodyXLBook',
								}}
							/>
						)}

						{buttons && buttons.length > 0 && (
							<Box
								data-test-id="group_hero_buttons_wrapper"
								sx={getHeroButtonWrapperStyles(contentAlign, isSmallScreen)}
							>
								{buttons.map((button) =>
									button ? (
										<ContentfulButton
											key={button.sys.id}
											{...button}
											dataTestId="button_hero_cta"
										/>
									) : null
								)}
							</Box>
						)}
					</Box>
					<Box>
						{termsConditions && (
							<Box data-test-id="group_hero_terms_and_conditions_wrapper" sx={TACWrapperStyles}>
								<TermsAndConditions
									{...termsConditions}
									textColor={resolveTACColor(hasVideoBackground, !!darkTextColor)}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		);
	}
);

HeroContent.displayName = 'HeroContent';

export default HeroContent;
