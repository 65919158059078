export const links = {
	entries: {
		inline: [],
		hyperlink: [],
		block: [],
	},
	assets: {
		hyperlink: [],
		block: [],
	},
	resources: {
		block: [
			{
				sys: {
					type: '',
					urn: '',
					linkType: '',
				},
			},
		],
		hyperlink: [],
		inline: [],
	},
};

export const noSlotsAtAllMsgRT = {
	json: {
		nodeType: 'document',
		data: {},
		content: [
			{
				nodeType: 'paragraph',
				data: {},
				content: [
					{
						nodeType: 'text',
						value: 'All reserved spots are fully booked for today. But don’t worry; your health matters to us, and we have options for you:',
						marks: [],
						data: {},
					},
				],
			},
			{
				nodeType: 'unordered-list',
				data: {},
				content: [
					{
						nodeType: 'list-item',
						data: {},
						content: [
							{
								nodeType: 'paragraph',
								data: {},
								content: [
									{
										nodeType: 'text',
										value: 'Walk-In: Feel free to walk in; our team will do their best to assist you promptly',
										marks: [],
										data: {},
									},
								],
							},
						],
					},
					{
						nodeType: 'list-item',
						data: {},
						content: [
							{
								nodeType: 'paragraph',
								data: {},
								content: [
									{
										nodeType: 'text',
										value: 'Virtual Vists: Prefer the comfort of your home? Be seen in less than 5 minutes with a virtual visit. ',
										marks: [],
										data: {},
									},
									{
										nodeType: 'hyperlink',
										data: {
											uri: 'https://wellnow.zipnosis.com',
										},
										content: [
											{
												nodeType: 'text',
												value: 'https://wellnow.zipnosis.com',
												marks: [
													{
														type: 'underline',
													},
												],
												data: {},
											},
										],
									},
									{
										nodeType: 'text',
										value: '/ It’s a convenient way to address the most common concerns.',
										marks: [],
										data: {},
									},
								],
							},
						],
					},
				],
			},
			{
				nodeType: 'paragraph',
				data: {},
				content: [
					{
						nodeType: 'text',
						value: '',
						marks: [],
						data: {},
					},
				],
			},
		],
	},
	links: links,
};

export const noSlotsTodayMsgRT = {
	json: {
		nodeType: 'document',
		data: {},
		content: [
			{
				nodeType: 'paragraph',
				data: {},
				content: [
					{
						nodeType: 'text',
						value: 'Sorry, there are no online appointments available today. Please select a different day or use the next available day below.',
						marks: [],
						data: {},
					},
				],
			},
		],
	},
	links: links,
};

export const noSlotsTomorrowMsgRT = {
	json: {
		nodeType: 'document',
		data: {},
		content: [
			{
				nodeType: 'paragraph',
				data: {},
				content: [
					{
						nodeType: 'text',
						value: 'Sorry, there are no online appointments available tomorrow. Please select a different day or use a time above.',
						marks: [],
						data: {},
					},
				],
			},
		],
	},
	links: links,
};
