export const textInputFieldStyles = {
	height: '3.5rem', 
	'& input::placeholder': { 
		opacity: 1, 
		fontWeight: '350'
	}
}

export const locationCardSectionStyles = {
	overflowX: 'scroll', 
	height: '28rem'
}