/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import { type Swiper as SwiperRef } from 'swiper';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import TemplateContainer from '@/components/TemplateContainer';
import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';
import { ContentfulBackground, HeadingTag, TAGAspectRatio, TAGContentAlignment } from '@/types';
import { VideoCarousel as TVideoCarousel, VideoAsset as TVideoAsset, VideoWithContent } from '@/types/generated';

import ContentfulButton from '../ContentfulButton';
import ContentSection from '../ContentSection';
import TAGSvgIcon from '../TAGSvgIcon';

import {
	containerStyles,
	getBottomButtonsStyles,
	manualRecordIconStyles,
	manualRecordIconWrapper,
	swiperActionsWrapper,
	templateFooterStyles,
} from './VideoCarousel.styles';

// TO DO: update swiper version to remove this dynamic import
const VideoCarouselSwiper = dynamic(() => import('./VideoCarouselSwiper'), { ssr: false });

interface ICustomHTMLElement extends HTMLElement {
	play?: () => void;
	muted: boolean;
	webkitRequestFullscreen?: () => Promise<void>;
	mozRequestFullScreen?: () => Promise<void>;
	msRequestFullscreen?: () => Promise<void>;
	webkitEnterFullscreen?: () => Promise<void>;
}
interface IDocument extends Document {
	webkitFullscreenElement?: Element;
	mozFullScreenElement?: Element;
	msFullscreenElement?: Element;
	webkitEnterFullscreen?: Element;
}

export default function VideoCarousel({
	backgroundColor = 'White',
	topFields,
	desktopVideosAspectRatio,
	mobileVideosAspectRatio,
	videosCollection,
	buttonsCollection,
}: TVideoCarousel) {
	const [activeStep, setActiveStep] = useState<number>(0);
	const [loadVideoInPreviewMode, setLoadVideoInPreviewMode] = useState<boolean>(true);
	const [playingVideoId, setPlayingVideoId] = useState<string>('');

	// Check if the device is using Safari (which is only available on iOS)
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const swiperRef = useRef<SwiperRef>();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const templateBackground = resolveContentfulBgColor(backgroundColor as ContentfulBackground, theme);

	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');
	const aspectRatio = (
		isSmallScreen ? mobileVideosAspectRatio || desktopVideosAspectRatio : desktopVideosAspectRatio
	) as TAGAspectRatio;
	const videosWithContent = videosCollection?.items || [];
	const buttons = buttonsCollection?.items;

	useEffect(() => {
		const handleFullScreenChange = () => {
			const doc = document as IDocument;
			if (
				!doc.fullscreenElement &&
				!doc.webkitFullscreenElement &&
				!doc.mozFullScreenElement &&
				!doc.msFullscreenElement
			) {
				// Mute all videos inside section-video-carousel
				const videoCarouselElement = document.querySelector('[data-test-id="section_video_carousel"]');
				if (videoCarouselElement) {
					const videos = videoCarouselElement.querySelectorAll<HTMLVideoElement>('video');
					videos.forEach((video) => {
						video.muted = true;

						//Needed in order to put the video back in place from where it came from.
						//without this the video sits on top of the video_carousel_footer_section on desktop and
						//it is insanely large on both mobile and desktop.
						if (isSafari) {
							video.style.objectFit = ''; //Needed to prevent safari desktop wide screen aspect-ratio in fullscreen
							const videoParent = video.parentElement as HTMLElement;

							if (videoParent) {
								// Remove the video element and re-insert it to force reflow
								const newVideo = video.cloneNode(true) as HTMLVideoElement;

								// Set width and height before reinserting
								newVideo.style.width = '100%';
								newVideo.style.height = 'auto';
								newVideo.play();
								newVideo.muted = true;

								videoParent.removeChild(video); // Remove the old video
								videoParent.insertBefore(newVideo, videoParent.firstChild); // Insert the new video at the beginning
							}
						}
					});
				}
				setLoadVideoInPreviewMode(true);
				setPlayingVideoId('');
			}
		};
		const handleUnmuteVideo = () => {
			const videoCarouselElement = document.querySelector('[data-test-id="section_video_carousel"]');
			if (videoCarouselElement) {
				const videos = videoCarouselElement.querySelectorAll<HTMLVideoElement>('video');
				videos.forEach((video) => {
					playingVideoId === video.id ? (video.muted = false) : (video.muted = true);
				});
			}
		};
		window.addEventListener('fullscreenchange', handleFullScreenChange);
		window.addEventListener('webkitfullscreenchange', handleFullScreenChange);
		window.addEventListener('mozFullScreenElement', handleFullScreenChange);
		window.addEventListener('msFullscreenElement', handleFullScreenChange);
		handleUnmuteVideo();
		return () => {
			window.removeEventListener('fullscreenchange', handleFullScreenChange);
			window.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
			window.removeEventListener('mozFullScreenElement', handleFullScreenChange);
			window.removeEventListener('msFullscreenElement', handleFullScreenChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playingVideoId]);

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	const handlePlayVideoClick = (video?: TVideoAsset | null) => {
		if (!video) {
			return null;
		}

		const videoIdToUse =
			isSmallScreen && video.mobileVideo?.sys?.id ? video.mobileVideo?.sys?.id : video.desktopVideo?.sys?.id;
		/* eslint-disable @typescript-eslint/no-floating-promises */
		if (videoIdToUse) {
			setPlayingVideoId(videoIdToUse);
			const videoToGoFullScreen = document.getElementById(videoIdToUse) as ICustomHTMLElement;
			videoToGoFullScreen.muted = false;

			// Check if the device is using Safari (which is only available on iOS)
			if (isSafari && videoToGoFullScreen.webkitEnterFullscreen) {
				// Apply overrides to the parent container to prevent resizing
				const videoParent = videoToGoFullScreen.parentElement as HTMLElement;
				if (videoParent) {
					videoParent.style.setProperty('transform', 'none', 'important');
					videoParent.style.setProperty('max-width', '100%', 'important');
					videoParent.style.setProperty('max-height', 'auto', 'important');
					videoParent.style.setProperty('overflow', 'hidden', 'important');
				}
				/* eslint-disable @typescript-eslint/no-floating-promises */
				videoToGoFullScreen.webkitEnterFullscreen();
				return null;
			}

			// Apply overrides to the parent container to prevent resizing
			const videoParent = videoToGoFullScreen.parentElement as HTMLElement;
			if (videoParent) {
				videoParent.style.setProperty('transform', 'none', 'important');
				videoParent.style.setProperty('max-width', '100%', 'important');
				videoParent.style.setProperty('max-height', 'auto', 'important');
				videoParent.style.setProperty('overflow', 'hidden', 'important');
			}

			if (videoToGoFullScreen?.requestFullscreen) {
				/* eslint-disable @typescript-eslint/no-floating-promises */
				videoToGoFullScreen.requestFullscreen();
				// Safari compatibility
			} else if (videoToGoFullScreen?.webkitRequestFullscreen) {
				/* eslint-disable @typescript-eslint/no-floating-promises */
				videoToGoFullScreen.webkitRequestFullscreen();
				// Firefox compatibility
			} else if (videoToGoFullScreen.mozRequestFullScreen) {
				/* eslint-disable @typescript-eslint/no-floating-promises */
				videoToGoFullScreen.mozRequestFullScreen();
				// IE/Edge compatibility
			} else if (videoToGoFullScreen.msRequestFullscreen) {
				/* eslint-disable @typescript-eslint/no-floating-promises */
				videoToGoFullScreen.msRequestFullscreen();
			}
		}
		/* eslint-enable @typescript-eslint/no-floating-promises */

		return null;
	};

	return (
		<TemplateContainer
			dataTestId="section_video_carousel"
			contentfulBackgroundColor={templateBackground}
			templateWidth="Full"
			containerSx={containerStyles(theme, isSmallScreen)}
		>
			{topFields && (
				<ContentSection
					{...topFields}
					titleHtag={(topFields.titleHtag || 'h1') as HeadingTag}
					eyebrowHtag={(topFields.eyebrowHtag || 'h4') as HeadingTag}
					alignment={isSmallScreen ? 'Center' : (topFields.alignment as TAGContentAlignment)}
					isDarkBackground={isDarkBackground}
					isSmallScreen={isSmallScreen}
					titleVariant="header1"
					dataTestId="section_video_carousel_top_fields"
				/>
			)}

			<VideoCarouselSwiper
				aspectRatio={aspectRatio}
				isSmallScreen={isSmallScreen}
				isDarkBackground={isDarkBackground}
				handleStepChange={handleStepChange}
				handlePlayVideoClick={handlePlayVideoClick}
				setLoadVideoInPreviewMode={setLoadVideoInPreviewMode}
				videosWithContent={videosWithContent as Array<VideoWithContent>}
				loadVideoInPreviewMode={loadVideoInPreviewMode}
				playingVideoId={playingVideoId}
				swiperRef={swiperRef}
			/>

			<Box
				sx={templateFooterStyles(Boolean(buttons?.length) || false, isSmallScreen)}
				data-test-id="video_carousel_footer_section"
			>
				{buttons && buttons.length > 0 ? (
					<Box data-test-id="video_carousel_bottom_buttons" sx={getBottomButtonsStyles(isSmallScreen)}>
						{buttons.map((button) =>
							button ? (
								<ContentfulButton
									key={button?.sys?.id}
									{...button}
									dataTestId={`video_carousel_button_${button?.sys.id}`}
								/>
							) : null
						)}
					</Box>
				) : null}

				<Box sx={swiperActionsWrapper} data-test-id="video_carousel_slider_actions">
					{isSmallScreen ? null : (
						<IconButton
							onClick={() => swiperRef.current?.slidePrev()}
							disabled={activeStep === 0}
							sx={{
								color: isDarkBackground ? 'neutrals.white' : 'secondary.dark',
							}}
							data-test-id="video_carousel_left_chevron_button"
						>
							<TAGSvgIcon icon="ChevronLeftFunc" size={40} />
						</IconButton>
					)}

					{isSmallScreen ? (
						<Box sx={manualRecordIconWrapper}>
							{videosWithContent?.map((_, index) => (
								<FiberManualRecordIcon
									key={`manual_record_icon_${index}`}
									data-test-id={`video_carousel_manual_record_icon_${index}`}
									onClick={() => swiperRef.current?.slideTo(index)}
									sx={manualRecordIconStyles(index, activeStep, theme)}
									color={index === activeStep ? 'secondary' : 'disabled'}
								/>
							))}
						</Box>
					) : null}

					{isSmallScreen ? null : (
						<IconButton
							onClick={() => swiperRef.current?.slideNext()}
							sx={{
								color: isDarkBackground ? 'neutrals.white' : 'secondary.dark',
							}}
							disabled={activeStep === videosWithContent?.length - 1}
							data-test-id="video_carousel_right_chevron_button"
						>
							<TAGSvgIcon icon="ChevronRightFunc" size={40} />
						</IconButton>
					)}
				</Box>
			</Box>
		</TemplateContainer>
	);
}
