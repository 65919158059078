import { Container, Box, Typography, DialogTitle, DialogContent, Dialog } from '@mui/material';

import { Drawer } from '@/index';
import PullerTab from '@/components/PullerTab/PullerTab';
import { TReview } from '@/types';

import { CloseButton } from '../TAGDialog/TAGDialog';
import TAGButton from '../TAGButton';

import OneReview from './OneReview';
import {
	reviewsDrawerStyles,
	loadMoreBoxStyles,
	reviewsDialogStyles,
	pullerContainer,
	mobileReviewsContainerStyles,
	oneReviewMobileMargin,
} from './BirdEyeReviews.styles';
import { IReviewsDialogOrDrawer } from './BirdEyeReviews.helper';

export default function ReviewsDialogOrDrawer({
	mobileWidth,
	handleCloseDialog,
	facilityName,
	reviews,
	hasMore = false,
	handleLoadMore,
	showFullReview = false,
}: IReviewsDialogOrDrawer) {
	const LoadMoreButton = ({ hasMore }: { hasMore: boolean }) => {
		if (!hasMore) {
			return null;
		}

		return (
			<Box sx={loadMoreBoxStyles}>
				<TAGButton size="small" variant="secondaryDefault" onClick={handleLoadMore}>
					Load More
				</TAGButton>
			</Box>
		);
	};

	if (mobileWidth) {
		return (
			<Drawer open onClose={handleCloseDialog} anchor={'bottom'} roundedTop>
				<Container
					data-test-id={showFullReview ? 'Single_Review_Drawer' : 'All_Reviews_Drawer'}
					sx={mobileReviewsContainerStyles}
				>
					<Box sx={pullerContainer}>
						<PullerTab position="absolute" />
					</Box>
					<Typography
						variant="header2"
						sx={reviewsDrawerStyles}
						data-test-id={showFullReview ? 'Single_Review_Drawer_Title' : 'All_Reviews_Drawer_Title'}
					>
						{facilityName}
					</Typography>
					{reviews.map((review: TReview) => {
						return (
							<OneReview
								dataTestId={`mobile_${review.reviewId}`}
								key={`mobile-${review.reviewId}}`}
								review={review}
								sx={oneReviewMobileMargin}
							/>
						);
					})}
					<LoadMoreButton hasMore={hasMore} />
				</Container>
			</Drawer>
		);
	}

	return (
		<Dialog
			onClose={handleCloseDialog}
			open
			fullWidth
			maxWidth="md"
			sx={reviewsDialogStyles}
			data-test-id={showFullReview ? 'Single_Review_Dialog' : 'All_Reviews_Dialog'}
		>
			<CloseButton onClose={handleCloseDialog} />
			<DialogTitle
				variant="header2"
				data-test-id={showFullReview ? 'Single_Review_Dialog_Title' : 'All_Reviews_Dialog_Title'}
			>
				{facilityName}
			</DialogTitle>

			<DialogContent>
				{reviews.map((review: TReview) => {
					return (
						<OneReview
							dataTestId={`desktop_${review.reviewId}`}
							key={`desktop-${review.reviewId}`}
							review={review}
							showFullReview={showFullReview}
						/>
					);
				})}
				<LoadMoreButton hasMore={hasMore} />
			</DialogContent>
		</Dialog>
	);
}
