import dynamic from 'next/dynamic';
import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import { CarouselRotationContentCollection, Maybe, Carousel as TCarousel } from '@/types/generated';

import TemplateContainer from '../TemplateContainer';

import { wrapper, getSectionWrapperStyles } from './Carousel.styles';

// NOTE: We need to import this dynamically to handle the issue with the Swiper library.
// It's ESM only and if not imported dynamically it will be required, causing the ERR_REQUIRE_ESM error.
// As we do not need this component on the server, we use ssr: false to disable it.
const SwiperContainer = dynamic(() => import('./SwiperContainer'), { ssr: false });

export interface ICarousel extends TCarousel {
	/**
	 * specifies the color of the navigation arrows in the carousel. Not used.
	 *
	 * @type {Maybe<primary | secondary | accent01 | accent02 | neutrals>}
	 * @memberof ICarousel
	 */
	arrowsColor?: Maybe<string>;
	/**
	 * Specifies the background color of the template.
	 *
	 * @type {Maybe<TAGBackground>}
	 * @memberof ICarousel
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * Template section text description.
	 *
	 * @type {Maybe<string>}
	 * @memberof ICarousel
	 */
	description?: Maybe<string>;
	/**
	 * Specifies the section eyebrow text.
	 *
	 * @type {Maybe<string>}
	 * @memberof ICarousel
	 */
	eyebrow?: Maybe<string>;
	/**
	 * Specifies the color of the image border.
	 *
	 * @type {Maybe<string>}
	 * @memberof ICarousel
	 * @deprecated use the underlying template configuration for styles.
	 */
	imageRounded?: Maybe<string>;
	/**
	 * Specifies the height of the image.
	 *
	 * @type {Maybe<number>}
	 * @memberof ICarousel
	 * @deprecated use the underlying template configuration for styles.
	 */
	imageWidth?: Maybe<number>;
	/**
	 * Specifies the color of the quotes.
	 *
	 * @type {Maybe<primary | secondary | accent01 | accent02 | neutrals>}
	 * @memberof ICarousel
	 * @deprecated use the underlying template configuration for styles.
	 */
	quotesColor?: Maybe<string>;
	/**
	 * Content to be displayed in the carousel. Can be of type Service or SingleMessage, or Card.
	 *
	 * @type {Maybe<CarouselRotationContentCollection>}
	 * @memberof ICarousel
	 */
	rotationContentCollection?: Maybe<CarouselRotationContentCollection>;
	/**
	 * Specifies the section title text.
	 *
	 * @type {Maybe<string>}
	 * @memberof ICarousel
	 */
	title?: Maybe<string>;
}

export default function Carousel({
	sys,
	description,
	eyebrow,
	title,
	backgroundColor,
	rotationContentCollection,
}: ICarousel) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isDarkBackground = !!backgroundColor?.toLowerCase().includes('dark');

	const rotationItems = rotationContentCollection?.items;

	return (
		<TemplateContainer
			containerSx={wrapper}
			dataTestId={`section_carousel_${sys.id}`}
			backgroundColor={backgroundColor as Maybe<TAGBackground>}
		>
			<Container sx={getSectionWrapperStyles(isDarkBackground)} data-test-id={`section_carousel_info_${sys.id}`}>
				{eyebrow && (
					<Typography
						variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
						color="text.secondary"
						data-test-id="text_carousel_section_eyebrow"
					>
						{eyebrow}
					</Typography>
				)}

				{title && (
					<Typography
						variant="header1"
						color="text.primary"
						whiteSpace="pre-line"
						data-test-id="text_carousel_section_title"
					>
						{title}
					</Typography>
				)}

				{description && (
					<Typography
						color="text.secondary"
						whiteSpace="pre-line"
						variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
						data-test-id="text_carousel_section_description"
					>
						{description}
					</Typography>
				)}
			</Container>

			{rotationItems && <SwiperContainer items={rotationItems} isSmallScreen={isSmallScreen} />}
		</TemplateContainer>
	);
}
