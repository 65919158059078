import { Location } from '@/rest/__generated/revspringApi';
import { logError, logWarning } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

export default async function getLocationDetailsById(params: {
	facilityCode: string;
	featureList?: Array<string>;
	environment?: string;
}): Promise<{
	revspringLocationValue: string;
	revspringAccountId: string;
	displayName: string;
	timezone: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	schedulingSystem: string | null;
	scheduleAppointmentURL: string | null;
}> {
	try {
		const { facilityCode, featureList, environment = 'prod' } = params;
		const reqObj = {
			url: `Details/${facilityCode}`,
			params: {
				revSpringDemo:
					environment !== 'prod' &&
					typeof featureList !== 'undefined' &&
					featureList.includes('onPageSchedulingPilotEnabled'),
				isQA: environment !== 'prod',
			},
			requestName: 'getLocationDetailsById',
		};
		const [locationDetails] = await httpClient.request<Location[]>(reqObj);
		if (!locationDetails) {
			throw new Error(`Could not find location details with id ${facilityCode}`);
		}

		if (!locationDetails.schedulingSystem) {
			throw new Error(`Could not find schedulingSystem in location details with id ${facilityCode}`);
		}

		if (locationDetails.schedulingSystem === 'Revspring' && !locationDetails.revspringLocationValue) {
			throw new Error(`Could not find revspringLocationValue in location details with id ${facilityCode}`);
		}

		return Promise.resolve({
			revspringLocationValue: locationDetails.revspringLocationValue ?? '',
			revspringAccountId: locationDetails.revspringAccountId ?? '',
			displayName: locationDetails.displayName ?? '',
			timezone: locationDetails.timezone ?? '',
			address: locationDetails.address ?? '',
			city: locationDetails.city ?? '',
			state: locationDetails.state ?? '',
			zip: locationDetails.zip ?? '',
			schedulingSystem: locationDetails.schedulingSystem,
			scheduleAppointmentURL: locationDetails.scheduleAppointmentURL ?? null,
		});
	} catch (err) {
		logWarning(`Could not find facility for id: ${params.facilityCode}.`);
		if (err instanceof Error) {
			logError(`REVSPRING_LOCATION_DETAIL_FAIL: ${err.message}`);
		}
		return Promise.resolve({
			revspringLocationValue: '',
			revspringAccountId: '',
			displayName: '',
			timezone: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			schedulingSystem: null,
			scheduleAppointmentURL: null,
		});
	}
}
