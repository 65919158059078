import { TAGContentAlignment } from '@/types';

export const getFlexAlignment = (alignment?: TAGContentAlignment) => {
	switch (alignment) {
		case 'Left':
			return 'flex-start';
		case 'Center':
			return 'center';
		case 'Right':
			return 'flex-end';
		default:
			return 'center';
	}
};

export const getTextAlignment = (alignment?: TAGContentAlignment) => {
	switch (alignment) {
		case 'Left':
			return 'left';
		case 'Center':
			return 'center';
		case 'Right':
			return 'right';
		default:
			return 'center';
	}
};
