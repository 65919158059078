import { Box, Typography } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon';

import { wrapper } from './ErrorDialog.styles';

export default function ErrorDialog() {
	return (
		<Box data-test-id="section_error_dialog" sx={wrapper}>
			<Box color={'error.dark'}>
				<TAGSvgIcon
					data-test-id="icon_single_message"
					icon={'CircleWarning'}
					size={40}
					color={'currentColor'}
				/>
			</Box>
			<Typography variant={'bodyLargeBook'} color={'text.secondary'} mt={3}>
				The appointment you have selected is no longer available. We’re sorry for the inconvenience.
			</Typography>
		</Box>
	);
}
