import { Suspense } from 'react';

import { getLogger } from '@/logging/logger';
import { PageContentsItem } from '@/types/generated';

import { componentMap } from './componentMap';

interface Props {
	componentProps: PageContentsItem;
	typename: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const logger = getLogger('design-system');

export const ComponentResolver = ({ typename, componentProps }: Props) => {
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const Component = componentMap[typename];

	if (!Component) {
		logger.info(`No Template Component for ${typename}`);
		return null;
	}

	logger.info(`Created ${typename} ${componentProps?.sys.id}`);

	return (
		<Suspense>
			<Component {...componentProps} />
		</Suspense>
	);
};
