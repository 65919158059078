import { Chip } from '@mui/material';

import { LocationOperationStatusType } from './ODPHero';

export interface ILocationStatusBadge {
	isFacilityOpen: boolean | string | null | undefined;
	locationStatus: LocationOperationStatusType;
	fallBackComponent?: JSX.Element;
}

export default function LocationStatusBadge({
	isFacilityOpen,
	locationStatus,
	fallBackComponent,
}: ILocationStatusBadge) {
	if (isFacilityOpen === 'Not in operation') {
		return (
			<Chip
				label={
					(locationStatus === 'Future Opening' && 'Coming Soon') ||
					(locationStatus === 'Temp-Closed' && 'Closed Temporarily')
				}
				variant={
					(locationStatus === 'Future Opening' && 'lightSuccess') ||
					(locationStatus === 'Temp-Closed' && 'lightError') ||
					undefined
				}
				onDelete={undefined}
			/>
		);
	}

	if (typeof isFacilityOpen === 'boolean') {
		return (
			<Chip
				label={isFacilityOpen ? 'Open Now' : 'Closed Now'}
				variant={isFacilityOpen ? 'heavySuccess' : 'heavyError'}
				onDelete={undefined}
			/>
		);
	}

	return fallBackComponent ? fallBackComponent : <div style={{ height: '1.25rem' }} />;
}
