import { SmallScreenContent, Asset, Maybe, FocalPointImage } from '@/types/generated';

export function resolveBackgroundVideo({
	isSmallScreen,
	videoSmallScreen,
	video,
}: {
	isSmallScreen: boolean;
	videoSmallScreen?: Maybe<SmallScreenContent['videoSmallScreen']>;
	video?: Maybe<Asset>;
}) {
	if (isSmallScreen) {
		if (videoSmallScreen) return videoSmallScreen;

		return null;
	}

	return video;
}

export function resolveBackgroundImage({
	isSmallScreen,
	focalPointImageSmallScreen,
	focalPointImage,
}: {
	isSmallScreen: boolean;
	focalPointImageSmallScreen?: Maybe<SmallScreenContent['focalPointImageSmallScreen']>;
	focalPointImage?: Maybe<FocalPointImage>;
}) {
	if (isSmallScreen && focalPointImageSmallScreen) {
		return focalPointImageSmallScreen;
	}

	return focalPointImage;
}
