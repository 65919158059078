import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, Typography, styled, useTheme } from '@mui/material';

import { TRating } from '@/types';

import { reviewBarItemStyles, reviewBarsBoxStyles } from './BirdEyeReviews.styles';
import { IReviewBarsProps } from './BirdEyeReviews.helper';

const BorderLinearProgress = styled(LinearProgress)(() => {
	const theme = useTheme();
	return {
		width: '100%',
		height: 8,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.neutrals?.neutral,
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.tertiary?.dark,
		},
	};
});

function ReviewBars({ ratings, reviewCount }: IReviewBarsProps) {
	const reviewRatings = [5, 4, 3, 2, 1];

	const normalize = (value: number) => (value * 100) / reviewCount;
	return (
		<Box data-test-id="ReviewsBlock_ReviewsBars" sx={reviewBarsBoxStyles}>
			{reviewRatings.map((reviewRating, index) => {
				const filteredReviews = ratings.filter((rating: TRating) => rating.rating === reviewRating);
				return (
					<Box
						sx={reviewBarItemStyles}
						key={`ReviewsBlock_ReviewsBars_${index}`}
						data-test-id={`${reviewRating}_star_review_bar`}
					>
						<Typography variant="bodyMediumBook" color="text.primary">
							{reviewRating}
						</Typography>
						<BorderLinearProgress variant="determinate" value={normalize(filteredReviews[0].reviewCount)} />
					</Box>
				);
			})}
		</Box>
	);
}

export default ReviewBars;
