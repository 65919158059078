import { Theme } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

import { Maybe } from '@/types';

export const getResourceCardStyles = ({
	backgroundColor: cardBackgroundColor,
}: {
	backgroundColor?: Maybe<string>;
}) => ({
	position: 'relative', // must be non-static because of the AspectRatioPaddingBox
	border: 'none',
	boxShadow: 'none',
	borderRadius: 0,
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	backgroundColor: cardBackgroundColor || 'transparent',
	flexBasis: 0,
});

export const getResourceCardContentStyles = (isSmallScreen: boolean, theme: Theme, cardsAlignment?: Maybe<string>) => ({
	position: 'absolute',
	bottom: '24px',
	left: '24px',
	right: '24px',
	backgroundColor: theme.palette.backgrounds?.dark,
	borderRadius: '1rem',
	display: 'flex',
	flexDirection: 'column',
	gap: '0.75rem',
	padding: isSmallScreen ? '0.75rem' : '2rem 1.5rem',
	alignItems: cardsAlignment || 'flex-start',
	zIndex: 1,
	...(!isSmallScreen && {
		transition: 'background-color .5s',
		'&:hover': {
			backgroundColor: `${theme.palette.secondary.main}`,
			'.MuiCardActions-root': {
				display: 'flex',
			},
		},
	}),
	...(isSmallScreen && {
		backgroundColor: `${theme.palette.secondary.main}`,
	}),
});

export const getResourceCardTitle = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'bodyXLBook'),
	color: theme.palette.text.light,
	[theme.breakpoints.down('md')]: {
		...resolveTypographyVariantStyles(theme, 'bodyLargeBook'),
		color: theme.palette.text.light,
	},
});

export const getResourceCardActionStyles = ({
	cardActionAlignment,
	isSmallScreen,
}: {
	cardActionAlignment?: Maybe<string>;
	isSmallScreen?: boolean;
}) => ({
	padding: 0,
	justifyContent: cardActionAlignment || 'flex-start',
	...(!isSmallScreen && { display: 'none' }),
	['& .MuiButton-root']: {
		paddingLeft: 0,
	},
});
