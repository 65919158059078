import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import TAGLink from '@/components/TAGLink';
import { ImageAsset as TImageAsset } from '@/types/generated';

import { getGridItemStyles, imageItemStyles } from './Category.styles';

export interface ICategoryProps {
	/**
	 * The Icon that will show.
	 * @type {IconName}
	 * @required
	 * @memberof ICategoryProps
	 */
	icon: IconName;
	/**
	 * The image that will appear in place of the icon
	 * @type {ImageAsset}
	 * @memberof ICategoryProps
	 */
	imageAsset?: TImageAsset | null;
	/**
	 * If the size of the viewport is small.
	 * @type {boolean}
	 * @required
	 * @memberof ICategoryProps
	 */
	isSmall: boolean;
	/**
	 * The url for a category link. Can be a localized link.
	 * @type {string}
	 * @required
	 * @memberof ICategoryProps
	 */
	link: string;
	/**
	 * Sets the linear background for mobile show more.
	 * @type {boolean}
	 * @required
	 * @memberof ICategoryProps
	 */
	showLinearBackground: boolean;
	/**
	 * The title of the category item.
	 * @type {string}
	 * @required
	 * @memberof ICategoryProps
	 */
	title: string;
	/**
	 * This determines if the categories should have a transparent background.
	 * @type {boolean}
	 * @memberof ICategoryProps
	 */
	transparentBg?: boolean;
}

export default function Category({
	icon,
	imageAsset,
	title,
	link,
	isSmall,
	showLinearBackground,
	transparentBg = false,
}: ICategoryProps) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	return imageAsset ? (
		<TAGLink href={link} title={title} linkSx={{ height: '100%' }}>
			<Grid
				container
				gap={'0.5rem'}
				alignItems={'center'}
				direction="column"
				sx={{ height: '100%', justifyContent: 'space-between' }}
			>
				<Grid item data-test-id={`category_with_image`} sx={imageItemStyles(isSmallScreen, imageAsset)} />
				<Grid item sx={{ textAlign: 'center', flexShrink: 0, marginY: 'auto' }}>
					<Typography variant="header4" textAlign={'center'} color="text.primary">
						{title}
					</Typography>
				</Grid>
			</Grid>
		</TAGLink>
	) : (
		<TAGLink href={link} title={title}>
			<Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
				<Grid
					item
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					data-test-id={`category_with_background_${showLinearBackground}`}
					sx={getGridItemStyles(theme, showLinearBackground, transparentBg)}
				>
					<TAGSvgIcon
						data-test-id={`category_icon_${icon}_${title}`}
						icon={icon}
						size={isSmall ? 72 : 95}
						style={{ maxWidth: '100%' }}
					/>
				</Grid>
				{!showLinearBackground && (
					<Grid item>
						<Typography variant="header4" textAlign={'center'} color="text.primary">
							{title}
						</Typography>
					</Grid>
				)}
			</Grid>
		</TAGLink>
	);
}
