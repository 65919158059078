import { BffWorkingHours } from '@/types/generated';

import { getDayOfWeek } from '../getDayOfWeek';

export const getTodayWorkingHoursFromBff = (workingHours: BffWorkingHours[]) => {
	const dayOfWeek = getDayOfWeek();
	const hours = workingHours.find((hour) => hour.date === dayOfWeek);

	if (hours?.open?.from && hours?.open?.to) {
		return `${hours.open.from} - ${hours.open.to}`;
	}

	return 'Closed';
};
