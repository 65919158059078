import { Grid, Typography } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { Asset } from '@/types/generated';
import { useLocationDetailsStore, useReasonForVisitStore } from '@/store';

import LocationHeading from '../LocationHeading';
import VirtualCareNow from '../VirtualCareNow';
import NearbyLocations from '../NearbyLocations';

export interface INoTimeSlotsSelector {
	locationCardMedia: Asset;
}

export default function NoTimeslotsMessage({ locationCardMedia }: INoTimeSlotsSelector) {
	const reasonForVisit = useReasonForVisitStore((store) => store.reasonForVisit);
	const { displayName, city, address, state, zip } = useLocationDetailsStore(
		useShallow((store) => ({
			displayName: store.displayName,
			city: store.city,
			address: store.address,
			state: store.state,
			zip: store.zip,
		}))
	);

	return (
		<Grid
			item
			container
			direction={'column'}
			rowGap={{ xs: 4, md: 5 }}
			alignContent={'stretch'}
			data-test-id={'section_no_timeslots_message'}
		>
			<Grid item data-test-id="grid_item_location_heading">
				<LocationHeading type={displayName} name={city} address={`${address}, ${city}, ${state} ${zip}`} />
			</Grid>

			<Grid item data-test-id="grid_item_no_timeslots_message">
				<Typography variant="overline" color="error.dark" data-test-id="overline_no_timeslots_message">
					NO AVAILABLE TIME SLOTS
				</Typography>
				<Typography variant="header3" color="text.primary" data-test-id="headline_no_timeslots_message">
					Walk ins always welcome
				</Typography>
			</Grid>
			<Grid item data-test-id="grid_item_virtual_care_now" sx={{ marginBottom: 1 }}>
				{reasonForVisit === 'Illness / Injury' && <VirtualCareNow />}
			</Grid>
			<NearbyLocations locationCardMedia={locationCardMedia} />
		</Grid>
	);
}
