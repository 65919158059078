import { TitleAlignment } from '@/types';

export const baseSectionContainerStyles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	gap: '1rem',
	marginBottom: '1.25rem',
};

export const sectionWrapperStyles = (alignMent: TitleAlignment) => ({
	...baseSectionContainerStyles,
	alignItems: alignMent === 'Left' ? 'flex-start' : 'center',
	marginLeft: alignMent === 'Left' ? '0' : 'auto',
});
