import { SvgIcon, Stack, Typography, Box, SxProps } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { Maybe, TReviewsMode } from '@/types';
import ImageWrapper from '@/components/ImageWrapper';
import { Asset } from '@/types/generated';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';

import { resolveContentAlignment } from '../../PatientReviews.helpers';

import ExpandableText from './ExpandableText';
import { getContainerStyles, getSubTextContainerStyles, avatarStyles } from './Review.styles';

export type Score = 1 | 2 | 3 | 4 | 5;

export interface IReview {
	score?: Maybe<Score>;
	subText1?: Maybe<string>;
	subText2?: Maybe<string>;
	text?: Maybe<string>;
	trimThreshold: number;
	dataTestIdSuffix: string;
	contentAlign?: Maybe<string>;
	isDarkBackground?: boolean;
	avatar?: Maybe<Asset>;
	mode?: TReviewsMode;
	sx?: SxProps;
	scoreIcon?: IconName;
}

export default function Review({
	score,
	subText1,
	subText2,
	text,
	trimThreshold,
	dataTestIdSuffix,
	contentAlign = 'Center',
	isDarkBackground = false,
	avatar,
	mode = 'list',
	sx = {},
	scoreIcon,
}: IReview) {
	const hasAnySubText = Boolean(subText1 || subText2);
	const testId = `patient_reviews_${dataTestIdSuffix}`;

	return (
		<Stack sx={{ ...getContainerStyles(contentAlign), ...sx }} data-test-id={`group_${testId}`}>
			{score && (
				<Typography
					variant="bodyLargeBook"
					color="warning.main"
					title={`${score} stars`}
					whiteSpace="nowrap"
					data-test-id={`group_${testId}_score`}
				>
					{[...Array(score).keys()].map((i) =>
						scoreIcon ? (
							<TAGSvgIcon key={i} icon={scoreIcon} dataTestId={`group_${testId}_score_icon`} />
						) : (
							<SvgIcon key={i}>
								<StarIcon />
							</SvgIcon>
						)
					)}
				</Typography>
			)}
			{text && (
				<ExpandableText
					text={text}
					trimThreshold={trimThreshold}
					dataTestIdSuffix={`${testId}_text`}
					contentAlign={contentAlign}
					isDarkBackground={isDarkBackground}
					mode={mode}
				/>
			)}
			{hasAnySubText && (
				<Box display="flex" alignItems="center" gap="1rem">
					{avatar?.url ? (
						<Box sx={avatarStyles}>
							<ImageWrapper
								data-test-id={`review_avatar_${subText1 || ''}`}
								maxWidth="100%"
								background-size="cover"
								background-repeat="no-repeat"
								background-position="center center"
								width="100%"
								object-fit="cover"
								src={avatar.url}
								alt={`${subText1 || ''} avatar`}
							/>
						</Box>
					) : null}
					<Stack sx={getSubTextContainerStyles(avatar ? 'Left' : contentAlign)}>
						{subText1 && (
							<Typography
								variant={mode === 'carousel' ? 'bodyLargeSemiBold' : 'bodyMediumBook'}
								color={isDarkBackground ? 'text.light' : 'text.secondary'}
								sx={{
									textAlign: resolveContentAlignment(contentAlign, true),
								}}
								data-test-id={`text_${testId}_subText1`}
							>
								{subText1}
							</Typography>
						)}

						{subText2 && (
							<Typography
								variant="bodyMediumBook"
								color={isDarkBackground ? 'text.light' : 'text.secondary'}
								sx={{
									textAlign: resolveContentAlignment(contentAlign, true),
								}}
								data-test-id={`text_${testId}_subText2`}
							>
								{subText2}
							</Typography>
						)}
					</Stack>
				</Box>
			)}
		</Stack>
	);
}
