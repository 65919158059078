import { getGraphQLClient } from '@/graphql/client';
import { FacilityBrand, UsStateCode } from '@/types/generated';
import { logWarning } from '@/utils/miscUtils';
import { getGraphHeaders } from '@/utils/getGraphHeaders';

export const getInsuranceCompanies = async (
	state: UsStateCode,
	brand: FacilityBrand,
	url: string,
	appName: string,
	appVersion: string
) => {
	try {
		if (!state) {
			return [];
		}

		const insurancesResp = await getGraphQLClient(url).insuranceByState(
			{
				filter: {
					usStateCode: state,
					facilityBrand: brand,
				},
			},
			getGraphHeaders(appName, appVersion)
		);
		const insuranceProviders =
			insurancesResp && insurancesResp.insuranceCompanies && insurancesResp.insuranceCompanies.total > 0
				? (insurancesResp?.insuranceCompanies?.items as Array<{ name: string }>)
				: [];
		return insuranceProviders || [];
	} catch (err) {
		logWarning('Error fetching insurance companies');
	}
};
